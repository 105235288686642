import * as types from '@/store/mutation-types/customerDeliveryMutations'
import * as loaderTypes from '@/store/mutation-types/loaderMutations'
import customerDeliveryService from '@/services/customerDeliveryService'

const deliveryAddressLimit = 5

const state = {
  userAddresses: [],
  userAddressesIsPending: false,
}

const getters = {
  userAddresses: (state) => state.userAddresses,
  userAddressesIsPending: (state) => state.userAddressesIsPending,
  isDeliveryAddressLimitReached: (state) =>
    state.userAddresses.length >= deliveryAddressLimit,
}

const actions = {
  [types.SAVE_DELIVERY_ADDRESS](
    { commit, dispatch, state, getters },
    deliveryAddress,
  ) {
    return new Promise((resolve, reject) => {
      dispatch(loaderTypes.SHOW_LOADER, {
        name: state.loaderName,
        mainText: 'Saving Delivery Address',
        subText: '',
      })

      customerDeliveryService
        .createDeliveryAddress(deliveryAddress)
        .then((response) => {
          commit(types.SAVE_ADDRESS, response.data)
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
        .finally(() => {
          dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
        })
    })
  },
  [types.UPDATE_DELIVERY_ADDRESS](
    { commit, dispatch, state, getters },
    deliveryAddress,
  ) {
    return new Promise((resolve, reject) => {
      dispatch(loaderTypes.SHOW_LOADER, {
        name: state.loaderName,
        mainText: 'Updating Delivery Address',
        subText: '',
      })

      customerDeliveryService
        .updateDeliveryAddress(deliveryAddress)
        .then(() => {
          commit(types.UPDATE_ADDRESS, deliveryAddress)
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
        .finally(() => {
          dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
        })
    })
  },
  [types.DELETE_DELIVERY_ADDRESS](
    { commit, dispatch, state, getters },
    addressId,
  ) {
    return new Promise((resolve, reject) => {
      dispatch(loaderTypes.SHOW_LOADER, {
        name: state.loaderName,
        mainText: 'Deleting Delivery Address',
        subText: '',
      })

      customerDeliveryService
        .deleteDeliveryAddress(addressId)
        .then(() => {
          commit(types.DELETE_ADDRESS, addressId)
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
        .finally(() => {
          dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
        })
    })
  },
  [types.GET_ALL_SAVED_ADDRESSES]({ commit }) {
    return new Promise((resolve, reject) => {
      commit(types.USER_ADDRESSES_IS_PENDING, true)
      customerDeliveryService
        .getDeliveryAddresses()
        .then((response) => {
          commit(types.UPDATE_ALL_SAVED_ADDRESSES, response.data)
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
        .finally(() => {
          commit(types.USER_ADDRESSES_IS_PENDING, false)
        })
    })
  },
}

const mutations = {
  [types.SAVE_ADDRESS](state, address) {
    state.userAddresses.unshift(address)
  },
  [types.UPDATE_ADDRESS](state, address) {
    for (let i = 0; i < state.userAddresses.length; i++) {
      if (state.userAddresses[i].addressId === address.addressId) {
        state.userAddresses.splice(i, 1)
        state.userAddresses.unshift(address)
        break
      }
    }
  },
  [types.DELETE_ADDRESS](state, addressId) {
    for (let i = 0; i < state.userAddresses.length; i++) {
      if (state.userAddresses[i].addressId === addressId) {
        state.userAddresses.splice(i, 1)
        break
      }
    }
  },
  [types.UPDATE_ALL_SAVED_ADDRESSES](state, addresses) {
    state.userAddresses = addresses
  },
  [types.USER_ADDRESSES_IS_PENDING](state, pending) {
    state.userAddressesIsPending = pending
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
