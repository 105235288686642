<template>
  <div id="rollingTally" class="rolling-tally">
    <h2 class="title">{{ title }}</h2>
    <div class="menu-item-name-and-cost">
      <div class="menu-item-name">{{ servesText }}</div>
      <div class="cost">{{ total | toCurrency(isChips) }}</div>
    </div>
    <div class="clear-both" />
    <div
      class="tally-item"
      v-for="section in sections"
      :key="section.id"
      v-show="section.max > 0"
    >
      <div class="tally" :class="{ 'tally-complete': section.isValid }">
        <check-svg />
      </div>
      <div class="name-and-error">
        <div class="section-name">{{ formatSectionName(section) }}</div>
        <div class="section-selected-items title-case">
          {{ section.selectedFoodItems }}
        </div>
        <div class="tally-error title-case" v-show="section.errorMessage">
          Select {{ section.errorMessage }}
        </div>
      </div>
    </div>
    <hr />
    <div v-if="includedItems" class="tally-item">
      <div class="name-and-error">
        <div class="section-name">Included</div>
        <div class="section-selected-items title-case">{{ includedItems }}</div>
      </div>
    </div>
    <hr v-if="includedItems" />
    <div class="menu-item-name-and-cost">
      <div class="menu-item-name subtotal">Order Total</div>
      <div class="cost subtotal">{{ total | toCurrency(isChips) }}</div>
    </div>
    <div class="buttons clear-both">
      <b-button
        id="addToBag"
        variant="primary"
        class="btn-block"
        @click="addToBag"
      >
        {{ addToBagLabel }} - {{ total | toCurrency(isChips) }}
      </b-button>
      <div class="error hideElement" :class="{ showBlockElement: showErrors }">
        {{ errorMessage }}
      </div>
      <div
        v-if="shouldShowCloseWithoutUpdating"
        class="close-without-saving"
        @click="closeWithoutSaving()"
      >
        close without updating
      </div>
    </div>
  </div>
</template>
<script>
import checkSvg from '@/components/common/CheckSvg'

export default {
  props: [
    'title',
    'servesText',
    'menuType',
    'sections',
    'errorMessage',
    'showErrors',
    'includedItems',
    'numberItems',
    'total',
  ],
  components: {
    checkSvg,
  },
  computed: {
    addToBagLabel() {
      if (this.$route.params.orderItemId) {
        return 'Update Bag'
      }

      return 'Add to Bag'
    },
    isChips() {
      return this.menuType === this.$store.getters.chipsAndSalsaId
    },
    isBBTB() {
      return this.menuType === this.$store.getters.burritosByTheBoxId
    },
    menuItem() {
      if (!this.$store.getters.restaurantMenu) {
        return {}
      }

      const menuTypeId = this.menuType
      const possibleItem = this.$store.getters.restaurantMenu.filter(function(
        item,
      ) {
        return item.id === menuTypeId
      })

      return possibleItem.length > 0 ? possibleItem[0] : {}
    },
    itemServes() {
      if (this.menuItem && this.menuItem.itemConfig) {
        return this.menuItem.itemConfig.serves
      } else {
        return ''
      }
    },
    menuItemPrice() {
      return this.menuItem.price ? this.menuItem.price : this.menuItem.minPrice
    },
    shouldShowCloseWithoutUpdating() {
      // do not show the Close without updating button if we do not
      // have an action to redirect to in this.route()
      return (
        this.$store.state.route.params.orderItemId &&
        this.$store.state.route.query.action &&
        this.$store.state.route.query.action.length
      )
    },
  },
  methods: {
    addToBag() {
      this.$emit('rolling-tally-add-to-bag')
    },
    closeWithoutSaving() {
      this.$emit('rolling-tally-close-without-saving')
    },
    formatSectionName(section) {
      let name = section.name

      if (section.max > 1) {
        name += 's'
      }

      return name
    },
  },
}
</script>
<style lang="scss">
#content {
  .rolling-tally {
    display: none;
  }
}

$breakpoint: map-get($grid-breakpoints, lg);
@media screen and (min-width: $breakpoint),
  screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: portrait) {
  #content {
    .rolling-tally {
      z-index: 1;
      display: block;
      border-radius: 4px;
      padding: 19px;
      font-family: $font-family-helvetica;
      font-size: 13px;
      color: $catering-desktop-label;
      background-color: $catering-builder-bg-rolling-tally;

      hr {
        border-top: 1px solid $catering-counter-circle-unfilled-border;
        margin-bottom: 22px;
      }

      .title {
        font-family: $font-family-sans-serif;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $catering-desktop-label;
        margin-bottom: 13px;
      }

      .menu-item-name-and-cost {
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;

        .menu-item-name {
          color: $catering-desktop-label;
          float: left;

          &.subtotal {
            font-size: 16px;
          }
        }

        .cost {
          color: $catering-chocolate;
          float: right;

          &.subtotal {
            font-size: 16px;
            color: $catering-desktop-label;
          }
        }
      }

      .tally-item {
        margin-top: 10px;
        display: flex;
      }

      .name-and-error {
        margin-left: 5px;
      }

      .section-name {
        font-family: $font-family-sans-serif;
        font-weight: bold;
        color: $catering-builder-rolling-tally-section;
        text-transform: uppercase;
      }

      .tally {
        background-color: $catering-counter-circle-unfilled;
        border: 1px solid $catering-light-grey-brown;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        vertical-align: top;
        margin-top: 4px;
        flex-shrink: 0;

        & svg {
          display: none;
          margin-left: -1px;
          margin-top: -1px;
          width: 10px;
          height: auto;
        }

        &.tally-complete {
          background-color: $catering-yellow;
          border-color: $catering-yellow;

          & svg {
            display: block;
          }
        }
      }

      .tally-error {
        font-family: $font-family-sans-serif;
        font-weight: normal;
        line-height: 1.46;
        letter-spacing: -0.3px;
        color: $catering-light-grey-brown;
      }

      .section-selected-items {
        white-space: initial;
        font-family: $font-family-helvetica;
        font-size: 13px;
        line-height: 1.46;
        letter-spacing: -0.3px;
        color: $catering-light-grey-brown;
      }

      .title-case {
        text-transform: lowercase;

        &::first-letter {
          text-transform: uppercase;
        }
      }

      .buttons {
        text-align: center;
        margin-top: 58px;

        button {
          font-size: 20px;
          padding: 12px;
        }

        .error {
          margin-top: 5px;
        }
      }
    }
  }
}

@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
}
</style>
