<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="18"
    height="16"
    viewBox="0 0 18 16"
    class="seeing-eye"
    v-on:click="svgClick"
  >
    <title>Eye Closed</title>
    <desc>Eye closed image</desc>
    <defs>
      <path id="a" d="M.534 16h13.36V.604H.534V16z" />
      <path id="c" d="M0 .24v11.137h10.259V.242H0z" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(4 -.104)">
        <mask id="b" fill="#fff">
          <use xlink:href="#a" />
        </mask>
        <path
          fill="#786259"
          d="M12.63 8.69a8.623 8.623 0 0 1-7.683 4.674 8.345 8.345 0 0 1-1.863-.223l.845-1.688c.292.087.6.136.92.136a3.29 3.29 0 0 0 3.287-3.287 3.26 3.26 0 0 0-1.32-2.617L7.79 3.74a8.581 8.581 0 0 1 4.84 4.174l.202.387-.201.388zM6.359 6.602c.474.417.778 1.02.778 1.7a2.29 2.29 0 0 1-2.287 2.287c-.16 0-.311-.023-.46-.053l1.969-3.934zm7.48 1.47l-.318-.617a9.615 9.615 0 0 0-5.271-4.63l1.11-2.22H8.243L7.278 2.53h-.001l-.457.913-.01.018h.002l-.878 1.752-.452.902-2.004 4.005h-.001l-.456.912-.918 1.834-.45.899L.533 16h1.12l.972-1.94a9.375 9.375 0 0 0 2.322.303A9.62 9.62 0 0 0 13.52 9.15l.319-.617a.502.502 0 0 0 0-.46z"
          mask="url(#b)"
        />
      </g>
      <g transform="translate(0 1.896)">
        <mask id="d" fill="#fff">
          <use xlink:href="#c" />
        </mask>
        <path
          fill="#786259"
          d="M5.175 10.485a8.612 8.612 0 0 1-3.911-3.796L1.063 6.3l.202-.386A8.62 8.62 0 0 1 8.947 1.24c.28 0 .553.03.828.058l.008-.018.476-.952h-.003A9.79 9.79 0 0 0 8.946.24a9.615 9.615 0 0 0-8.57 5.215l-.32.617a.495.495 0 0 0 0 .46l.32.617a9.609 9.609 0 0 0 4.352 4.228l.447-.893z"
          mask="url(#d)"
        />
      </g>
      <path
        fill="#786259"
        d="M8.39 5.957l.522-1.04c-.022 0-.042-.006-.063-.006a3.29 3.29 0 0 0-3.287 3.287c0 .758.269 1.45.703 2.006l.52-1.039a2.248 2.248 0 0 1-.223-.967c0-1.105.787-2.029 1.829-2.242"
      />
    </g>
  </svg>
</template>

<script>
export default {
  methods: {
    svgClick(e) {
      this.$emit('eyeclosedsvg-click', e)
    },
  },
}
</script>
