<template>
  <div v-if="max > 0" class="food-group">
    <div>
      <div class="confirm-counters">
        <h2
          class="food-group-text"
          :class="{ error: displayErrors && !isValid }"
        >
          {{ foodGroupText }}
        </h2>
        <div class="counters">
          <div
            v-for="index in maxCounter"
            :key="index"
            class="counter"
            :class="{
              filled: availableTotal < index,
              error: displayErrors && !isValid,
            }"
          />
          <div v-if="showPerItemCount" class="per-item-count">
            {{ count }} of {{ max }}
          </div>
        </div>
        <div v-if="perItem" class="select-assortment">
          <b-button
            block
            variant="chipotle-secondary"
            size="md"
            @click="updateFoodCountsForAutoAssort"
          >
            choose for me
          </b-button>
        </div>
      </div>
    </div>
    <div class="food-list">
      <food-selector
        v-for="foodInfo in foodInfos"
        :key="foodInfo.id"
        ref="foodInfoSelector"
        :food-info="foodInfo"
        :available-total="availableTotal"
        :max="max"
        :per-item="perItem"
        v-on:food-selector-increment="foodIncrement"
        v-on:food-selector-decrement="foodDecrement"
        v-on:food-selector-request-deselect-sibling="
          foodSelectorRequestDeselectSibling
        "
      />
    </div>

    <b-modal
      :visible="this.limitError"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      @hide="limitErrorHide"
    >
      <div class="limit-error-text">
        <div class="header">
          {{ this.limitErrorMessage }}
        </div>
        To make room for more, remove other
        <br />
        proteins or update the total quantity.
      </div>
      <div class="text-center mt-3">
        <b-button class="pl-5 pr-5" variant="primary" @click="limitErrorHide">
          OK
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import foodSelector from './FoodSelector'

export default {
  props: {
    // item menuId, to include in the raiseChanges payload
    id: String,
    // the max number of subItems selectable for the group
    max: Number,
    // flags if the group should display as "Choose 1 THING per UNIT",
    // but has hardcoded BBTB-protein-specific logic
    perItem: Boolean,
    // the subItem menu data for the food group
    foodInfos: Array,
    groupName: String,
    // the group assortment values (optional, currently BBTB-only)
    builderAssortmentValues: Object,
    // flags if the item quantity affects the group maximum quantity (Chips, BBTB)
    maxEqualsItemQuantity: Boolean,
    // flags if the per-subItem count should be included in the tally list (e.g., "2 steak, 4 veggie")
    includeCountInTally: Boolean,
  },
  components: {
    foodSelector,
  },
  data() {
    return {
      count: 0,
      isDirty: false,
      isValid: false,
      displayErrors: false,
      selectedFoodIds: [],
      limitError: false,
      limitErrorMessage: '',
    }
  },
  computed: {
    foodGroupText() {
      // TODO: refactor hardcoded logic for 'perItem'
      return this.perItem
        ? `Choose 1 ${this.groupName} per burrito`
        : `Choose ${this.max} ${this.displayGroupName(this.max)}`
    },
    showPerItemCount: {
      get() {
        return this.perItem || this.max > 5
      },
    },
    availableTotal: {
      get() {
        return this.max - this.count
      },
    },
    selectedFoodItems: {
      get() {
        let result = ''
        for (let index = 0; index < this.foodInfos.length; index++) {
          if (this.selectedFoodIds.indexOf(this.foodInfos[index].id) > -1) {
            if (result !== '') {
              result += ', '
            }

            if (this.includeCountInTally) {
              result +=
                this.$refs.foodInfoSelector[index].count.toString() + ' '
            }

            result += this.foodInfos[index].name
          }
        }

        return result
      },
    },
    maxCounter: {
      get() {
        if (this.max > 5) {
          return 1
        } else {
          return this.max
        }
      },
    },
  },
  methods: {
    displayGroupName(maxToCheck) {
      let name = this.groupName
      if (maxToCheck > 1) {
        name += 's'
      }

      return name
    },
    inputChange(foodInfoId, count, isInit) {
      for (let index = 0; index < this.foodInfos.length; index++) {
        if (this.foodInfos[index].id === foodInfoId) {
          // TODO: foodInfos is store data that should not be updated outside mutation.
          // refactor to maintain the functionality correctly
          this.foodInfos[index].manualCount = count
          this.$refs.foodInfoSelector[index].inputChange(count, {
            isInit: isInit || false,
          })
          break
        }
      }
    },
    getSubItems() {
      const subItems = []
      if (this.$refs.foodInfoSelector) {
        this.$refs.foodInfoSelector.forEach(function(foodInfoSelectorItem) {
          const subItem = foodInfoSelectorItem.getSubItem()
          if (subItem) {
            subItems.push(subItem)
          }
        })
      }

      return subItems
    },
    appendSelectedFoodItemId(foodInfoId) {
      if (this.selectedFoodIds.indexOf(foodInfoId) === -1) {
        this.selectedFoodIds.push(foodInfoId)
      }
    },
    removeSelectedFoodItemId(foodInfoId) {
      const index = this.selectedFoodIds.indexOf(foodInfoId)
      if (index > -1) {
        this.selectedFoodIds.splice(index, 1)
      }
    },
    limitErrorHide(eventArgs) {
      this.limitError = false
    },
    foodIncrement(eventArgs) {
      this.count += eventArgs.delta
      if (eventArgs.trackCount) {
        // TODO: foodInfos is store data that should not be updated outside mutation.
        // refactor to maintain the functionality correctly
        this.getFoodInfoFromId(eventArgs.id).manualCount = eventArgs.count
      }
      if (eventArgs.overLimit) {
        this.limitErrorMessage = eventArgs.limitErrorMessage
        this.limitError = true
      }
      if (eventArgs.count === 0) {
        this.removeSelectedFoodItemId(eventArgs.foodInfoId)
      } else {
        this.appendSelectedFoodItemId(eventArgs.foodInfoId)
      }
      this.raiseChange(false, eventArgs.isInit)
    },
    foodDecrement(eventArgs) {
      this.count -= eventArgs.delta
      if (eventArgs.trackCount) {
        // TODO: foodInfos is store data that should not be updated outside mutation.
        // refactor to maintain the functionality correctly
        this.getFoodInfoFromId(eventArgs.id).manualCount = eventArgs.count
      }
      if (eventArgs.count === 0) {
        this.removeSelectedFoodItemId(eventArgs.foodInfoId)
      }
      this.raiseChange(true, eventArgs.isInit)
    },
    showErrors() {
      this.displayErrors = true
      this.raiseChange(false)
    },
    raiseChange(clearAutoAssortment, isInit) {
      this.isValid = Number(this.count) === Number(this.max)
      let errorMessage = ''
      if (!this.isValid) {
        errorMessage =
          (this.max - this.count).toString() +
          ' more ' +
          this.displayGroupName(this.max - this.count)
      }
      const eventArgsToSend = {
        id: this.id,
        isDirty: !isInit && this.count > 0,
        count: this.count,
        isValid: this.isValid,
        errorMessage,
        selectedFoodItems: this.selectedFoodItems,
        clearAutoAssortment,
      }

      this.$emit('food-group-change', eventArgsToSend)
    },
    foodSelectorRequestDeselectSibling(eventArgs) {
      for (let index = 0; index < this.foodInfos.length; index++) {
        let assortment = 0
        if (eventArgs.id === this.foodInfos[index].id) {
          assortment = 1
        }
        this.$refs.foodInfoSelector[index].inputChange(assortment, {
          trackCount: true,
        })
      }
    },
    updateFoodCounts() {
      // return early if food counts aren't affected by order item quantity
      // or if we don't have all the appropriate pieces to calculate.
      if (
        !this.maxEqualsItemQuantity ||
        !this.foodInfos ||
        !this.$refs.foodInfoSelector
      ) {
        return
      }

      // Otherwise, calculate based on the manual counts.
      // This applies both for food groups toggling back from AutoAssort
      // (e.g., BBTB Proteins) and food groups that don't use AutoAssort
      // but still have a manual count (e.g., Chips Premium Toppings)
      let assortmentCount = 0
      let stopProcessing = false
      let assortment = 0

      for (let index = 0; index < this.foodInfos.length; index++) {
        if (stopProcessing) {
          assortment = 0
        } else {
          assortment = this.foodInfos[index].manualCount
        }

        if (assortmentCount + assortment > this.max) {
          assortment = this.max - assortmentCount
          stopProcessing = true
        }

        assortmentCount += assortment
        this.$refs.foodInfoSelector[index].inputChange(assortment, {
          trackCount: false,
        })
      }
    },

    updateFoodCountsForAutoAssort() {
      let assortmentCount = 0
      let stopProcessing = false
      let assortment = 0

      const maxDecimal = { index: -1, decimalValue: 0 }

      for (let index = 0; index < this.foodInfos.length; index++) {
        if (stopProcessing) {
          assortment = 0
        } else {
          // Default to 0 to prevent NaN in case we cannot find a matching assortment value for the menu item
          const assortmentRatio =
            this.builderAssortmentValues[this.foodInfos[index].id] || 0
          const calculated = assortmentRatio * this.max
          assortment = Math.round(calculated)

          const mainPart = Math.floor(calculated)
          if (mainPart === assortment) {
            const decimalValue = calculated - mainPart
            if (maxDecimal.decimalValue < decimalValue) {
              maxDecimal.index = index
              maxDecimal.decimalValue = decimalValue
            }
          }
        }

        if (assortmentCount + assortment > this.max) {
          assortment = this.max - assortmentCount
          stopProcessing = true
        }

        assortmentCount += assortment

        this.foodInfos[index].assortment = assortment
        this.$refs.foodInfoSelector[index].inputChange(assortment, {
          trackCount: false,
        })
      }

      // gotta love roundoffs
      if (assortmentCount < this.max) {
        this.foodInfos[maxDecimal.index].assortment++
        this.$refs.foodInfoSelector[maxDecimal.index].inputChange(
          this.foodInfos[maxDecimal.index].assortment,
          { trackCount: false },
        )
        assortmentCount++
      }
    },
    getFoodInfoFromId(id) {
      const possibleItem = this.foodInfos.filter(function(value) {
        return value.id === id
      })

      return possibleItem.length > 0 ? possibleItem[0] : {}
    },
  },
  watch: {
    max(newValue, oldValue) {
      this.updateFoodCounts()
    },
  },
}
</script>
<style lang="scss">
#content {
  .food-group {
    margin-bottom: 20px;

    .limit-error-text {
      text-align: center;
      font-family: $font-family-helvetica;
      font-size: 13px;
      line-height: 1.23;
      color: $catering-builder-limit-error-color;

      .header {
        color: $catering-chocolate;
        text-transform: uppercase;
        font-family: $font-family-sans-serif;
        font-size: 18px;
        font-weight: bold;
        line-height: 0.89;
        margin-bottom: 10px;
      }
    }

    // the autoplace rule is duplicated across each instance
    // where the grid is redeclared to make sure rules are
    // properly rendered for IE11.  In addition, the
    // grid, grid-template-columns, and grid-template-rows
    // must be redeclared for each viewport so that unique
    // prefixes are generated.
    .food-list {
      /* autoprefixer grid: autoplace */
      display: grid;
      // Yes, it seems like this is duplicated but
      // DO NOT REMOVE THE FIRST GRID-TEMPLATE-COLUMNS DECLARATION!
      // It is used by auto-prefixer's autoplace to generate nth-child
      // placements while allowing it to immediately be overridden by other
      // browsers with the repeat(auto-fill, minmax(120px, 1fr) declaration
      // for dynamic resizing.  Removing the first declaration will break
      // IE 11 for smaller viewports.
      grid-template-columns: 1fr 1fr;
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
      grid-template-rows: auto auto auto;
      grid-gap: 20px;
    }

    .select-assortment {
      margin-bottom: 15px;
    }

    .confirm-counters {
      margin-bottom: 5px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;

      .food-group-text {
        font-family: $font-family-sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: $catering-desktop-label;
        text-transform: uppercase;
        line-height: 1;
        max-width: 6em;
      }

      .counters {
        display: flex;
        flex-direction: row-reverse;
        height: 20px; // match height of the title so it cat be centered
        align-items: center;

        .counter {
          background-color: $catering-counter-circle-unfilled;
          border: 1px solid $catering-counter-circle-unfilled-border;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-left: 5px;

          &.filled {
            background-color: $catering-yellow;
            border-color: $catering-yellow;
          }
        }

        .per-item-count {
          font-size: 13px;
          color: $catering-light-grey-brown;
          height: 16px;
        }
      }
    }
  }
}

@include media-breakpoint-only(sm) {
  #content {
    .food-group {
      .food-list {
        /* autoprefixer grid: autoplace */
        display: grid;
        // Yes, it seems like this is duplicated but
        // DO NOT REMOVE THE FIRST GRID-TEMPLATE-COLUMNS DECLARATION!
        // It is used by auto-prefixer's autoplace to generate nth-child
        // placements while allowing it to immediately be overridden by other
        // browsers with the repeat(auto-fill, minmax(120px, 1fr) declaration
        // for dynamic resizing.  Removing the first declaration will break
        // IE 11 for smaller viewports.
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        grid-template-rows: auto auto;
      }
    }
  }
}

@include media-breakpoint-only(md) {
  #content {
    .food-group {
      /* autoprefixer grid: autoplace */
      display: grid;
      grid-template-columns: 200px 1fr;
      grid-template-rows: auto auto;

      .food-list {
        /* autoprefixer grid: autoplace */
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto auto;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  #content {
    .food-group {
      .select-assortment {
        margin-top: 10px;
        line-height: 1.1;
        & label {
          padding-top: 0;
          font-size: 14px;
          width: 155px;
        }
      }
      .confirm-counters {
        align-self: start;
        align-items: flex-start;
        flex-direction: column;
      }
      .food-group-text {
        margin-bottom: 10px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  #content {
    .food-group {
      /* autoprefixer grid: autoplace */
      display: grid;
      grid-template-columns: 200px 1fr;
      grid-template-rows: auto auto;

      .food-list {
        /* autoprefixer grid: autoplace */
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto auto;
      }

      .confirm-counters {
        flex-direction: column;
        justify-content: start;
        flex-basis: 18.5%;
        margin-left: 27px;
        min-width: 122px;

        .food-group-text {
          font-size: 24px;
          line-height: 1;
          height: auto;
          margin-bottom: 10px;
        }

        .counters {
          justify-content: flex-end;
          margin-left: -5px;

          .counter {
            width: 12px;
            height: 12px;
          }

          .per-item-count {
            margin-left: 5px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-only(xs) {
  .select-assortment {
    width: 100%;
    margin: 15px 0;
  }

  // TODO: refactor styles to be mobile first and reduce specificity
  #content .food-group .confirm-counters .food-group-text {
    max-width: none;
  }
}
</style>
