export const GET_RESTAURANT_PICKUP_HOURS = 'GET_RESTAURANT_PICKUP_HOURS'
export const GET_RESTAURANT_TIMES = 'GET_RESTAURANT_TIMES'
export const SEARCH_FOR_RESTAURANTS = 'SEARCH_FOR_RESTAURANTS'
export const SEARCH_BY_LATLONG_FOR_RESTAURANTS =
  'SEARCH_BY_LATLONG_FOR_RESTAURANTS'
export const GET_RESTAURANT = 'GET_RESTAURANT'
export const GET_CONFIRMATION_RESTAURANT = 'GET_CONFIRMATION_RESTAURANT'
export const GET_DELIVERY_RESTAURANTS = 'GET_DELIVERY_RESTAURANTS'

export const UPDATE_RESTAURANT_PICKUP_HOURS = 'UPDATE_RESTAURANT_PICKUP_HOURS'
export const UPDATE_RESTAURANT_DELIVERY_HOURS =
  'UPDATE_RESTAURANT_DELIVERY_HOURS'
export const UPDATE_RESTAURANT_DELIVERY_TIMES =
  'UPDATE_RESTAURANT_DELIVERY_TIMES'
export const UPDATE_RESTAURANT_PICKUP_TIMES = 'UPDATE_RESTAURANT_PICKUP_TIMES'
export const UPDATE_RESTAURANT_SEARCH = 'UPDATE_RESTAURANT_SEARCH'
export const APPEND_RESTAURANT_SEARCH = 'APPEND_RESTAURANT_SEARCH'
export const UPDATE_RESTAURANT_INFO = 'UPDATE_RESTAURANT_INFO'
export const UPDATE_CONFIRMATION_RESTAURANT_INFO =
  'UPDATE_CONFIRMATION_RESTAURANT_INFO'
export const UPDATE_DELIVERY_RESTAURANTS = 'UPDATE_DELIVERY_RESTAURANTS'

export const UPDATE_RESTAURANT_SEARCH_INFO = 'UPDATE_RESTAURANT_SEARCH_INFO'
