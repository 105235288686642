import cmsService from '@/services/cmsService'
import { get } from '@/utilityFunctions'
import {
  GET_HERO_BANNER_CONTENT,
  GET_HERO_BANNER_CONTENT_SUCCESS,
} from '@/store/mutation-types/cmsMutations'

const state = {
  heroBannerContent: null,
}

const getters = {
  heroBannerContent: (state) => state.heroBannerContent,
}

const actions = {
  [GET_HERO_BANNER_CONTENT]({ commit }) {
    return cmsService.getHeroBannerContent().then(({ data }) => {
      const rawContent = get(
        ':items.root.:items.responsivegrid.:items.contentfragment.elements',
        data,
        {},
      )
      const content = Object.keys(rawContent).reduce((acc, key) => {
        acc[key] = rawContent[key].value
        return acc
      }, {})
      commit(GET_HERO_BANNER_CONTENT_SUCCESS, content)
      return content
    })
  },
}

const mutations = {
  [GET_HERO_BANNER_CONTENT_SUCCESS](state, content) {
    state.heroBannerContent = content
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
