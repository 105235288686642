import orderService from '@/services/orderService'
import * as types from '@/store/mutation-types/userOrderHistoryMutations'
import * as loaderTypes from '@/store/mutation-types/loaderMutations'
import { deepClone } from '@/utilityFunctions'
import Vue from 'vue'

const state = {
  loaderName: 'userOrderHistory',
  userOrderHistory: null,
}

const getters = {
  activeOrders: (_state, { userOrderHistory }) =>
    userOrderHistory.activeOrders || [],
  canceledOrders: (_state, { userOrderHistory }) =>
    userOrderHistory.cancelledOrders || [],
  completedOrders: (_state, { userOrderHistory }) =>
    userOrderHistory.completedOrders || [],
  favoriteOrders: (_state, { userOrderHistory }) =>
    userOrderHistory.favoriteOrders || [],
  userOrderHistory: (state) => state.userOrderHistory || {},
}

const actions = {
  [types.GET_USER_ORDER_HISTORY]({ commit, dispatch, state }) {
    dispatch(loaderTypes.SHOW_LOADER, {
      name: state.loaderName,
      mainText: 'Getting Order History',
      subText: '',
    })

    return orderService
      .getUserOrderHistory()
      .then((response) => {
        commit(types.UPDATE_USER_ORDER_HISTORY, response.data)
      })
      .finally(() => {
        dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
      })
  },
  [types.SAVE_FAVORITE_ORDER]({ commit, dispatch }, payload) {
    dispatch(loaderTypes.SHOW_LOADER, {
      name: state.loaderName,
      mainText: 'Saving Favorite',
      subText: '',
    })

    return orderService
      .saveFavoriteOrder(payload)
      .then(() => {
        commit(types.SAVE_FAVORITE_ORDER, payload)
      })
      .finally(() => {
        dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
      })
  },
  [types.REMOVE_FAVORITE_ORDER]({ commit, dispatch }, orderId) {
    dispatch(loaderTypes.SHOW_LOADER, {
      name: state.loaderName,
      mainText: 'Removing Favorite',
      subText: '',
    })

    return orderService
      .removeFavoriteOrder(orderId)
      .then(() => {
        commit(types.REMOVE_FAVORITE_ORDER, orderId)
      })
      .finally(() => {
        dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
      })
  },
}

const mutations = {
  [types.UPDATE_USER_ORDER_HISTORY](state, userOrderHistory) {
    state.userOrderHistory = userOrderHistory
  },
  [types.SAVE_FAVORITE_ORDER](state, { orderId, favoriteName }) {
    const order = state.userOrderHistory.completedOrders.filter(
      (o) => o.orderId === orderId,
    )[0]
    if (order) {
      Vue.set(order, 'favoriteName', favoriteName)
      state.userOrderHistory.favoriteOrders.push(deepClone(order))
    }
  },
  [types.REMOVE_FAVORITE_ORDER](state, orderId) {
    for (let i = 0; i < state.userOrderHistory.favoriteOrders.length; i++) {
      if (state.userOrderHistory.favoriteOrders[i].orderId === orderId) {
        state.userOrderHistory.favoriteOrders.splice(i, 1)
        break
      }
    }
    const order = state.userOrderHistory.completedOrders.filter(
      (o) => o.orderId === orderId,
    )[0]
    if (order) {
      order.favoriteName = null
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
