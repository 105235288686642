<template>
  <div id="app" :class="{ 'is-scrolled': isScrolled }">
    <vue-announcer />
    <usable-net-header />
    <cmg-header />
    <div id="app-content">
      <div id="content" role="main">
        <transition name="router-fade" tag="div">
          <router-view :key="$route.path" />
        </transition>
      </div>
      <aem-footer />
    </div>
    <b-modal
      ref="refreshDialog"
      :visible="appNeedsRefresh"
      no-close-on-backdrop
      no-close-on-esc
      ok-only
      ok-variant="Refresh"
    >
      <div slot="modal-header" />
      <div class="text-center">
        <p>
          You may not be seeing the latest and greatest information. Refresh
          your page by clicking the button below or close and re-open your
          browser.
        </p>
        <p />
      </div>
      <div slot="modal-footer">
        <button class="btn btn-xl btn-danger" @click="refreshApp">
          REFRESH PAGE
        </button>
      </div>
    </b-modal>
    <b-modal
      ref="confirmDialog"
      class="confirm-dialog"
      :visible="$store.getters.confirm.show"
      centered
      no-close-on-backdrop
      no-close-on-esc
      :hide-header="!$store.getters.confirm.headerText"
      hide-footer
      hide-header-close
      :return-focus="$store.getters.confirm.returnFocus"
      v-on:ok="okConfirm"
      v-on:cancel="cancelConfirm"
    >
      <div slot="modal-title" class="modal-header-slot">
        {{ $store.getters.confirm.headerText }}
      </div>
      <div class="text-container">
        <div class="confirm-text">{{ $store.getters.confirm.text }}</div>
        <div 
          v-if="$store.getters.confirm.emphasisText" 
          class="confirm-emphasis-text">
          {{ $store.getters.confirm.emphasisText }}
        </div>
        <div
          v-for="subText in confirmSubText"
          :key="subText"
          class="confirm-sub-text"
        >
          {{ subText }}
        </div>
      </div>
      <div class="confirm-buttons row pl-3 pr-3">
        <b-button
          id="confirm-dialog-ok-button"
          variant="primary"
          class="col-12 col-lg-8 offset-lg-2"
          @click="okConfirm"
        >
          {{ $store.getters.confirm.okText }}
        </b-button>
        <div
          v-if="!$store.getters.confirm.hideCancel"
          id="confirm-dialog-cancel-button"
          class="col-12 gold-link mt-3 text-center"
          role="button"
          tabindex="0"
          @keydown.enter="cancelConfirm"
          @click="cancelConfirm"
        >
          {{ $store.getters.confirm.cancelText }}
        </div>
      </div>
    </b-modal>

    <account-verify-modal />
    <update-country-modal />
    <epops-modal />

    <div
      :class="{
        showMyCustomLoader: $store.getters.loaderDisplay,
        hideMyCustomLoader: !$store.getters.loaderDisplay,
      }"
    >
      <loader />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CmgHeader from '@/components/layout/CmgHeader'
import AemFooter from '@/components/layout/AEMFooter'
import EpopsModal from '@/components/common/EpopsModal'
import Loader from '@/components/common/Loader'
import UpdateCountryModal from '@/components/account/UpdateCountryModal'
import AccountVerifyModal from '@/components/account/AccountVerifyModal.vue'
import UsableNetHeader from '@/components/layout/UsableNetHeader'
import {
  OK_CONFIRM_DIALOG,
  CANCEL_CONFIRM_DIALOG,
} from '@/store/mutation-types/confirmDialogMutations'
import {
  SETUP_APP_VERSION_CHECK,
  REFRESH_APP,
} from '@/store/mutation-types/versionCheckMutations'
import { SET_IS_SCROLLED } from './store/mutation-types/uiCommonMutations'

export default {
  name: 'App',
  components: {
    CmgHeader,
    AemFooter,
    EpopsModal,
    Loader,
    UpdateCountryModal,
    AccountVerifyModal,
    UsableNetHeader,
  },
  computed: {
    ...mapGetters(['appNeedsRefresh', 'isScrolled']),
    confirmSubText() {
      const { subText } = this.$store.getters.confirm
      return Array.isArray(subText) ? subText : [subText]
    },
  },
  mounted() {
    this.setupAppVersionCheck()

    window.addEventListener('scroll', this.updateIsScrolled)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updateIsScrolled)
  },
  methods: {
    ...mapActions({
      okConfirm: OK_CONFIRM_DIALOG,
      cancelConfirm: CANCEL_CONFIRM_DIALOG,
      setupAppVersionCheck: SETUP_APP_VERSION_CHECK,
      refreshApp: REFRESH_APP,
    }),
    updateIsScrolled() {
      const isScrolled = window.scrollY !== 0
      if (isScrolled !== this.isScrolled) {
        this.$store.dispatch(SET_IS_SCROLLED, isScrolled)
      }
    },
  },
}
</script>

<style lang="scss">
/**
  * overrides must come BEFORE lib styles ie: bootstrap
  * -- this only works where !default flag is used --
  * an alternative is to include all lib partials the app uses rather than the entire lib
  */
@import './styles/overrides.scss';
@import './styles/libs.scss';
@import './styles/app.scss';

body > img {
  // Hack due to tracking pixel causing whitespace at bottom of body
  display: none;
}

#app {
  display: flex;
  flex-direction: column;
}

#app-content {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

#content {
  background: $catering-bg-grey;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-height: calc(100vh - #{$header-height-tall});

  > div {
    flex-grow: 1;
  }
}

.confirm-dialog {
  .modal-content {
    .modal-body {
      min-height: 320px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      .text-container {
        align-self: center;
        max-width: 225.8px;
      }

      .confirm-buttons {
        width: 100%;

        button {
          font-size: 16px;
          width: 350.7px;
          height: 44px;
        }
      }
    }
  }
}
.confirm-text {
  font-family: $font-family-bold-condensed;
  font-size: 22px;
  text-align: center;
  text-transform: uppercase;
  color: $catering-chocolate;
  margin-bottom: 5px;
}
.confirm-emphasis-text {
  font-family: $font-family-helvetica;
  font-size: 13px;
  line-height: 1.31;
  letter-spacing: -0.2px;
  text-align: center;
  color: #736357;
  font-weight: bold;
}
.confirm-sub-text {
  font-family: $font-family-helvetica;
  font-size: 13px;
  line-height: 1.31;
  letter-spacing: -0.2px;
  text-align: center;
  color: #736357;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}
.showMyCustomLoader {
  position: fixed;
  display: block;
  z-index: 10001; // Bootstrap modal increments z-index by 2000 for every nested modal. So assume 5 nested modals is more than enough
  top: 25%;
  left: calc((100% - 300px) / 2);
}
.hideMyCustomLoader {
  display: none;
}

.router-fade-enter-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.router-fade-leave-active {
  transition-property: opacity;
  // since we have variable height content,
  // immediately make the content invisible
  // with a zero opacity value that takes place
  // instantly
  transition-duration: 0s;
  // set the leaving content's position to
  // absolute to avoid dynamic resizing to
  // include both views as the content is swapping
  position: absolute;
}

.router-fade-enter-active {
  transition-delay: 0.15s;
}

.router-fade-enter,
.router-fade-leave-active {
  opacity: 0;
}
</style>
