import { cateringOrderClient } from '@/services/clients'

export default {
  getTieredAllMenu() {
    return cateringOrderClient.get('/menu/tiered')
  },
  getTieredMenuByGeoCode(lat, long) {
    return cateringOrderClient.get(
      `/menu/geocode?latitude=${lat}&longitude=${long}`,
    )
  },
  getRestaurantTiered(restaurantNumber) {
    return cateringOrderClient.get(`/menu/tiered/${restaurantNumber}`)
  },
}
