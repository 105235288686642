import * as types from '@/store/mutation-types/versionCheckMutations'
import healthCheckService from '@/services/healthCheckService'
import errorService from '@/services/errorService'
import { reload } from '@/utilityFunctions'

const state = {
  appVersion: null,
  latestAppVersion: null,
}

const getters = {
  appNeedsRefresh: (state) => state.appVersion !== state.latestAppVersion,
}

const actions = {
  [types.SETUP_APP_VERSION_CHECK]({ commit }) {
    const tenMinutes = 600000
    window.setInterval(() => {
      getAppVersion()
        .then((version) => commit(types.SET_LATEST_APP_VERSION, { version }))
        .catch(() => {})
    }, tenMinutes)
    getAppVersion()
      .then((version) => commit(types.SET_INITIAL_APP_VERSION, { version }))
      .catch(() => {})
  },
  [types.REFRESH_APP]({ getters }) {
    if (getters.appNeedsRefresh) {
      reload()
    }
  },
}

const mutations = {
  [types.SET_INITIAL_APP_VERSION](state, { version }) {
    Object.assign(state, { appVersion: version, latestAppVersion: version })
  },
  [types.SET_LATEST_APP_VERSION](state, { version }) {
    state.latestAppVersion = version
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}

function getAppVersion() {
  return healthCheckService
    .checkHealth()
    .then((response) => response.headers['coo-ui-version'])
    .catch((err) => {
      errorService.handle(err)
      throw err
    })
}
