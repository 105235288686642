import mask from '@/masks/masker/directive'
import masker from '@/masks/masker/masker'
import { birthMonthHyphenDateMask } from '@/masks/birthMonthHyphenDateMask'
import { creditCardMask } from '@/masks/creditCardMask'
import { monthOfYearMask } from '@/masks/monthOfYearMask'
import { phoneMask } from '@/masks/phoneMask'
import { promotionMask } from '@/masks/promotionMask'
import * as removeNonNumericMaskFuncs from '@/masks/removeNonNumericMask'

// Created this function to support mocking in unit tests
function removeNonNumericMask(...args) {
  return removeNonNumericMaskFuncs.removeNonNumericMask(...args)
}

export {
  birthMonthHyphenDateMask,
  creditCardMask,
  mask,
  masker,
  monthOfYearMask,
  phoneMask,
  promotionMask,
  removeNonNumericMask,
}
