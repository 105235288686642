<template>
  <div class="readonly-food-group">
    <div class="header-container">
      <h2 class="header">{{ header }}</h2>
      <div class="header-subtext" v-if="headerSubtext">
        {{ headerSubtext }}
      </div>
    </div>
    <div class="food-info-container">
      <div
        class="food-info"
        :class="{
          'smaller-images': foodInfos.length > 3,
          salsa: foodInfo.id === salsa,
        }"
        v-for="foodInfo in foodInfos"
        :key="foodInfo.id"
      >
        <img :src="foodInfo.imageUrl" :alt="foodInfo.name" />
        <div class="food-name">{{ foodInfo.name }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { SALSA } from '@/constants'
export default {
  props: ['id', 'header', 'headerSubtext', 'foodInfos'],
  data: (_) => ({
    salsa: SALSA,
  }),
}
</script>
<style lang="scss">
#content {
  .readonly-food-group {
    margin: 0 auto;
    margin-bottom: 40px;
    padding-top: 25px;

    .header {
      text-align: center;
      text-transform: uppercase;
      font-family: $font-family-sans-serif;
      font-size: 20px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: $catering-desktop-label;
    }

    .header-subtext {
      font-size: 13px;
      text-align: center;
    }

    .food-info-container {
      margin-top: 14px;
      grid-gap: 1px;
      /* autoprefixer grid: autoplace */
      display: grid;
      /// use repeat 2 for smaller viewports and override to
      // a row of 4
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto auto;

      .food-info {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        font-family: $font-family-sans-serif;
        color: $catering-white;
        box-shadow: 0 0 0 1px $catering-light-brown;
        text-align: center;

        .food-name {
          position: absolute;
          left: 0;
          text-transform: uppercase;
          font-size: 15px;
          font-weight: bold;
          line-height: 1;
          bottom: 12px;
          text-align: center;
          color: $catering-desktop-label;
          padding-right: 15px;
          padding-left: 15px;
          width: 100%;
          height: 2em;
        }
      }

      & > div {
        flex: 0 50%;
      }
    }
  }
}

@media (max-width: 350px) {
  #content {
    .readonly-food-group {
      .food-info-container {
        .food-info {
          .food-name {
            // override for iphone 5 viewport
            font-size: 14px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .burritos-by-the-box {
    .food-info {
      &.salsa {
        grid-column-start: 1;
        grid-column-end: 3;
        -ms-grid-column-span: 3;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  #content {
    .readonly-food-group {
      /* autoprefixer grid: autoplace */
      display: grid;
      grid-template-columns: 200px 1fr; //TODO make 200px a variable to match food-group
      grid-template-rows: auto;

      .header-container {
        .header,
        .header-subtext {
          text-align: left;
        }
      }

      .food-info-container {
        /* autoprefixer grid: autoplace */
        display: grid;
        // Yes, it seems like this is duplicated but
        // DO NOT REMOVE THE FIRST GRID-TEMPLATE-COLUMNS DECLARATION!
        // It is used by auto-prefixer's autoplace to generate nth-child
        // placements while allowing it to immediately be overridden by other
        // browsers with the repeat(auto-fill, minmax(120px, 1fr) declaration
        // for dynamic resizing.  Removing the first declaration will break
        // IE 11 for smaller viewports.
        grid-template-columns: repeat(4, 1fr);
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        grid-template-rows: auto auto;
        flex-wrap: initial;
        margin-top: auto;

        .food-info {
          img {
            width: 100%;
          }

          .food-name {
            font-size: 13px;
            bottom: 10%;
            padding-right: 5px;
            padding-left: 5px;
          }
        }

        & > div {
          flex: 1 1 auto;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .readonly-food-group {
    .header-container {
      flex-direction: column;
      justify-content: start;
      flex-basis: 18.5%;
      margin-left: 27px;
      min-width: 122px;

      .header {
        font-size: 20px;
        padding-right: 40px;

        .display-group-name {
          max-width: 170px;
        }
      }
    }
  }
}
</style>
