<template>
  <div>
    <div class="d-flex" :class="{ 'flex-column': orientation === 'vertical' }">
      <div v-for="(link, i) in links" :key="i" :class="headerLinkItemClasses">
        <common-link
          class="header-link"
          :class="commonLinkClasses"
          :link="link"
        >
          {{ link.text }}
        </common-link>
      </div>
    </div>
  </div>
</template>

<script>
import CommonLink from '@/components/layout/CommonLink'

export default {
  components: {
    CommonLink,
  },
  props: {
    links: {
      type: Array,
      required: true,
    },
    orientation: {
      type: String, // horizontal, vertical
      default: 'horizontal',
    },
  },
  computed: {
    commonLinkClasses() {
      return this.orientation === 'horizontal' ? 'h4' : 'd-block h5 my-2'
    },
    headerLinkItemClasses() {
      return this.orientation === 'horizontal' ? 'horizontal-spacing' : 'd-flex'
    },
  },
}
</script>

<style lang="scss" scoped>
.horizontal-spacing {
  justify-content: center;
  padding: 16px;
}

.header-link {
  text-transform: uppercase;
  margin-bottom: 0;
  position: relative;

  &:after {
    content: '';
    height: 0.17em;
    left: 0;
    width: 0;
    height: 2px;
    bottom: -8px;
    position: absolute;
    background-color: $catering-red;
    transition: width 0.2s;
  }

  &:hover,
  &.link-active {
    color: $catering-red;
    transition: color 0.1s ease-in;

    &:after {
      width: 22px;
    }
  }
}
</style>
