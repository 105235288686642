// actions
// Default state as if you never enter a promotion code.
export const APPLY_ORDER_PROMOTION = 'APPLY_ORDER_PROMOTION'
export const REMOVE_ORDER_PROMOTION = 'REMOVE_ORDER_PROMOTION'
// When applying a promotion to the order
// When we load an order or change its contents
export const VALIDATE_ORDER_PROMOTIONS = 'VALIDATE_ORDER_PROMOTIONS' // for onload,, refresh

// mutations
// ErrorPromoCodeInvalid = "0115";
// ErrorPromotionItemMissing = "0116";
// ErrorPromotionExpired = "0117";
export const ORDER_PROMOTION_EXCEPTION = 'ORDER_PROMOTION_EXCEPTION'
export const ORDER_PROMOTION_IS_PENDING_CHANGE =
  'ORDER_PROMOTION_IS_PENDING_CHANGE'
export const READY_FOR_ORDER_PROMOTION_ENTRY = 'READY_FOR_ORDER_PROMOTION_ENTRY'
export const VALID_ORDER_PROMOTION = 'VALID_ORDER_PROMOTION'
