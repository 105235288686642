<template>
  <div class="bag-footer-container">
    <div class="bag-footer-summary d-flex flex-column">
      <b-row class="bag-footer-item flex-grow-1 flex-shrink-1">
        <b-col>
          <div class="bag-footer-big-font">BAG TOTAL</div>
        </b-col>
        <b-col>
          <div class="bag-footer-big-font text-right">
            {{ bagtotal | toCurrency }}
          </div>
        </b-col>
      </b-row>
      <b-form-row class="bag-footer-item flex-grow-1 flex-shrink-1 mb-4">
        <b-col>
          <bag-promotion />
        </b-col>
      </b-form-row>
      <b-row class="bag-footer-item flex-grow-1 flex-shrink-1">
        <b-col>
          <div class="bag-footer-big-font">SUBTOTAL</div>
        </b-col>
        <b-col>
          <div class="bag-footer-big-font text-right">
            {{ currentOrderPromotionSubtotal | toCurrency }}
          </div>
        </b-col>
      </b-row>
      <delivery-limit-warning
        v-if="orderIsDelivery && orderExceedsDeliveryMax"
      />
      <b-form-row class="bag-footer-item flex-grow-0 flex-shrink-1 mt-2">
        <b-col>
          <b-button
            type="button"
            variant="tertiary"
            size="md"
            class="bag-btn-add-more-food"
            @click="addMoreFoodClose"
          >
            ADD MORE FOOD
          </b-button>
        </b-col>
        <b-col>
          <b-button
            variant="primary"
            size="md"
            class="bag-btn-checkout"
            @click="checkoutClose"
          >
            CHECKOUT
          </b-button>
        </b-col>
      </b-form-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import deliveryLimitWarning from '@/components/common/DeliveryLimitWarning'
import bagPromotion from '@/components/bag/BagPromotion'

export default {
  name: 'bag-footer',
  components: { deliveryLimitWarning, bagPromotion },
  methods: {
    addMoreFoodClose() {
      this.$root.$emit('close-bag')
      this.$router.push({ path: '/' })
    },
    checkoutClose() {
      this.$root.$emit('close-bag')
      this.$router.push({ path: '/bag/checkout' })
    },
  },
  computed: {
    ...mapGetters([
      'orderIsDelivery',
      'orderExceedsDeliveryMax',
      'currentOrderPromotionSubtotal',
    ]),
    orderDetail() {
      if (
        this.$store.getters.currentOrder &&
        this.$store.getters.currentOrder.orderDetail
      ) {
        return this.$store.getters.currentOrder.orderDetail
      }
      return {}
    },
    bagtotal() {
      return this.orderDetail.subTotal
    },
  },
}
</script>

<style lang="scss">
$catering-footer-shadow: rgba(0, 0, 0, 0.16);

.bag-footer-container {
  background-color: $catering-white;
  bottom: 0;
  box-shadow: 0 -1px 4px 0 $catering-footer-shadow;
  width: 100%;
}

.bag-footer-big-font {
  font-size: 16px;
}

.bag-footer-summary {
  color: $catering-chocolate;
  font-family: $font-family-sans-serif;
  font-weight: bold;
  height: 100%;
  padding: 15px;
}

.bag-btn-add-more-food {
  font-size: 16px;
  letter-spacing: -0.3px;
  width: 100%;
}

.bag-btn-checkout {
  font-size: 16px;
  letter-spacing: -0.3px;
  width: 100%;
}

// Fix for Firefox regarding flex items, which does not auto shrink items to fit flex container
// Firefox sets min-height to auto, while Chrome sets min-height to 0
// We can't set min-height to 0 here because then Firefox will shrink the height all the way to 0
// We are concerned with height because flex-direction is column (same issue with width for flex-direction = row)
.bag-footer-item {
  min-height: 20px;
}

@include media-breakpoint-up(lg) {
  .bag-footer-big-font {
    font-size: 18px;
  }

  .bag-footer-container {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .bag-btn-group {
    left: 20px;
    right: 20px;
  }
}

@include media-breakpoint-up(sm) {
  .bag-footer-summary {
    padding: 20px;
  }
}
</style>
