import './bootstrap/index'

import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueMq from 'vue-mq'
import VueAnnouncer from '@vue-a11y/announcer'
import { sync } from 'vuex-router-sync'

import polyfill from '@/polyfill'
import App from '@/App.vue'
import router from '@/router'
import filters from '@/filters'
import directives from '@/directives'
import store from '@/store'

polyfill().then(renderApp)

function renderApp() {
  sync(store, router)

  Vue.config.productionTip = false

  Vue.use(BootstrapVue)
  Vue.use(VueMq, {
    breakpoints: {
      xs: 576,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: Infinity,
    },
  })
  Vue.use(filters)
  Vue.use(directives)
  Vue.use(VueAnnouncer, {}, router)

  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app')
}
