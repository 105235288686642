import * as types from '@/store/mutation-types/rewardsMutations'
import loyaltyService from '@/services/loyaltyService'
import promotionService from '@/services/promotionService'

function getInitialRequestState() {
  return {
    data: null,
    error: null,
    isPending: false,
  }
}

const state = {
  points: getInitialRequestState(),
  promotions: getInitialRequestState(),
}

const getters = {
  pointsHaveError: (state) => !!state.points.error,
  pointsArePending: (state) => state.points.isPending,
  points: (state) => state.points.data,
  promotionsHaveError: (state) => !!state.promotions.error,
  promotionsArePending: (state) => state.promotions.isPending,
  promotions: (state) => state.promotions.data,
  currentPointsBalance: (state, getters) =>
    getters.points && getters.points.currentPointsBalance
      ? getters.points.currentPointsBalance
      : 0,
  promotionsCount: (state, getters) =>
    getters.promotions ? getters.promotions.length : 0,
  rewardsDataPending: (state, getters) =>
    [getters.pointsArePending, getters.promotionsArePending].some(Boolean),
}

const mutations = {
  [types.GET_POINTS_PENDING](state) {
    state.points.isPending = true
  },
  [types.GET_POINTS_SUCCESS](state, data) {
    state.points.data = data
    state.points.isPending = false
  },
  [types.GET_POINTS_FAILURE](state, error) {
    state.points.error = error
    state.points.isPending = false
  },
  [types.GET_PROMOTIONS_PENDING](state) {
    state.promotions.isPending = true
  },
  [types.GET_PROMOTIONS_SUCCESS](state, data) {
    state.promotions.data = data
    state.promotions.isPending = false
  },
  [types.GET_PROMOTIONS_FAILURE](state, error) {
    state.promotions.error = error
    state.promotions.isPending = false
  },
  [types.CLEAR_POINTS](state) {
    state.points = getInitialRequestState()
  },
  [types.CLEAR_PROMOTIONS](state) {
    state.promotions = getInitialRequestState()
  },
}

const actions = {
  [types.GET_POINTS]({ commit }) {
    commit(types.GET_POINTS_PENDING)
    return loyaltyService
      .getPoints()
      .then(({ data }) => {
        commit(types.GET_POINTS_SUCCESS, data)
        return data
      })
      .catch((err) => {
        commit(types.GET_POINTS_FAILURE, err)
        throw err
      })
  },
  [types.GET_PROMOTIONS]({ commit }) {
    commit(types.GET_PROMOTIONS_PENDING)
    return promotionService
      .getPromotions()
      .then(({ data }) => {
        commit(types.GET_PROMOTIONS_SUCCESS, data)
        return data
      })
      .catch((err) => {
        commit(types.GET_PROMOTIONS_FAILURE, err)
        throw err
      })
  },
  [types.GET_ALL_REWARDS_DATA]({ dispatch }) {
    return Promise.all([
      dispatch(types.GET_POINTS),
      dispatch(types.GET_PROMOTIONS),
    ])
  },
  [types.RESET_ALL_REWARDS_DATA]({ commit }) {
    commit(types.CLEAR_POINTS)
    commit(types.CLEAR_PROMOTIONS)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
