<template>
  <custom-modal
    class="main-menu"
    :title="menuTitle"
    :visible="value"
    :cover-site-header="false"
    position="top-left"
    :hide-header="$mq | mq({ xs: false, sm: true })"
    :hide-header-close="$mq | mq({ xs: false, sm: true })"
    hide-footer
    @change="setModalVisibility"
    :noFade="true"
  >
    <div id="menuPopup" class="menu-popup">
      <div v-show="!isFullUser" class="login-container">
        <div v-show="!showLogin" class="guest-user-drawer">
          <header-links
            class="header-links"
            :links="links"
            orientation="vertical"
          />
          <div class="guest-rewards-container">
            <div class="guest-rewards">
              <h4>REWARD YOURSELF WITH FREE CHIPOTLE</h4>
              <b-button
                class="my-3"
                type="button"
                variant="primary"
                @click="launchCreateAnAccount"
              >
                CREATE AN ACCOUNT
              </b-button>
              <div class="h5">
                <span>ALREADY A MEMBER?</span>
                <span
                  class="clickable gold-link ml-3"
                  tabindex="0"
                  role="button"
                  @click="showLogin = true"
                  @keydown.enter="showLogin = true"
                >
                  SIGN IN
                </span>
              </div>
            </div>
          </div>
        </div>
        <login
          v-show="showLogin"
          class="pt-3"
          @login-success="loginSuccess"
          @login-verification-failed="loginVerificationFailed"
          @forgot-password-click="launchForgotPassword"
          @create-an-account-click="launchCreateAnAccount"
        />
      </div>
      <rewards-menu v-show="showRewardsMenu" />
      <header-links
        v-show="isFullUser"
        class="header-links m-3"
        :links="links"
        orientation="vertical"
      />
      <account-menu v-show="isFullUser" />
    </div>
  </custom-modal>
</template>

<script>
import { mapGetters } from 'vuex'

import CustomModal from '@/components/common/CustomModal'
import Login from '@/components/account/Login'
import HeaderLinks from '@/components/account/HeaderLinks'
import AccountMenu from '@/components/account/AccountMenu'
import RewardsMenu from '@/components/rewards/RewardsMenu'
import { goToRooPasswordReset } from '@/common/rooUtils'

export default {
  name: 'MainMenu',
  components: {
    CustomModal,
    AccountMenu,
    HeaderLinks,
    Login,
    RewardsMenu,
  },
  props: {
    links: { type: Array, default: () => [] },
    value: { type: Boolean, default: false },
  },
  data() {
    return {
      showLogin: false,
    }
  },
  methods: {
    setModalVisibility(visible) {
      this.$emit('input', visible)
      if (!visible) {
        this.showLogin = false
      }
    },
    loginSuccess() {
      this.setModalVisibility(false)
    },
    loginVerificationFailed() {
      this.setModalVisibility(false)
    },
    launchForgotPassword() {
      goToRooPasswordReset()
    },
    launchCreateAnAccount() {
      this.setModalVisibility(false)
      this.$emit('create-an-account-click')
    },
  },
  computed: {
    ...mapGetters(['isFullUser', 'currentUser', 'rewardsDataPending']),
    menuTitle() {
      return this.isFullUser
        ? `HEY ${this.currentUser.firstName.toUpperCase()}`
        : 'SIGN IN'
    },
    showRewardsMenu() {
      return this.isFullUser && !this.rewardsDataPending
    },
  },
}
</script>

<style lang="scss">
.main-menu {
  .menu-popup {
    height: 100%;

    @include media-breakpoint-up(sm) {
      width: 370px;
      height: 450px;
    }
  }

  .header-links {
    flex: 0 0 auto;
    display: flex;

    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

  .guest-rewards-container {
    border-top: 1px solid $catering-light-brown;
    flex: 1 0;
    margin-left: -30px;
    margin-right: -30px;
  }

  .guest-rewards {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
  }

  .gold-link {
    font-size: 1em;
  }

  .login-container {
    padding: 0 30px;
    height: 100%;
    width: 100%;
  }

  .guest-user-drawer {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .dialog-header {
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}
</style>
