<template>
  <div id="accountVerifyPhoneContainer" class="account-verify-phone-container">
    <img
      src="@/static/img/header/cmg-medallion-logo.svg"
      alt="Medallion"
      class="medallion"
    />
    <h2>Verify Your Phone Number</h2>
    <span class="account-verify-phone-instructions">
      {{ phoneInstructions }}
    </span>
    <inputbox
      id="accountPhoneVerificationCode"
      type="text"
      v-model="tokenValue"
      class="mt-5 mb-4"
      placeholder="Verification Code"
      auto-complete="verificationCode"
      label-override="Phone Verification Code"
      required
      :max-length="20"
      :validity-state="validityState"
      :rules="[rules.required('Verification code required')]"
    />
    <span class="error">
      {{ errorMessage }}
    </span>
    <div class="account-verify-phone-button-container">
      <b-button
        variant="primary"
        block
        size="md"
        class="account-verify-phone-btn"
        :disabled="accountLocked"
        @click.stop="verifyPhoneNumber"
      >
        VERIFY
      </b-button>
      <button
        class="account-phone-resend-btn"
        role="button"
        tabindex="0"
        :disabled="disableResendButton"
        @click.stop="resendVerificationCode"
      >
        RESEND CODE
      </button>
    </div>
    <div class="account-verify-customer-support-info">
      <b-form-checkbox
        class="mt-3 text-small"
        v-model="showCustomerSupportInfo"
      >
        I don't have a mobile number or I'm unable to receive the verification
        code.
      </b-form-checkbox>
      <p class="mt-3" v-show="showCustomerSupportInfo">
        Please contact
        <u>
          <b><a href="tel:+1-800-244-7685">1-800-CHIPOTLE.</a></b>
        </u>
      </p>
    </div>
  </div>
</template>

<script>
import inputbox from '@/components/common/Inputbox'
import { required } from '@/validators/index'

export default {
  name: 'account-verify-phone',
  props: {
    phoneNumber: { type: String, required: true },
    errorMessage: { type: String },
    accountLocked: { type: Boolean, default: false },
    verifyPayload: { type: Object },
    validityState: { type: Object, required: true },
  },
  components: {
    inputbox,
  },
  data() {
    return {
      tokenValue: '',
      showCustomerSupportInfo: false,
      resendButtonDisabled: false,
      rules: {
        required,
      },
    }
  },
  computed: {
    phoneInstructions() {
      return `Enter the code sent to your phone number ***-***-${this.phoneLast4Digit}. This code will only be valid for 5 minutes.`
    },
    phoneLast4Digit() {
      return this.phoneNumber.slice(-4)
    },
    disableResendButton() {
      return this.accountLocked || this.resendButtonDisabled
    },
  },
  methods: {
    verifyPhoneNumber() {
      // Strange behavior on mobile (actual device) browsers where this click event is fired before the v-model two way binding can complete.
      // This causes the value of this.tokenValue being used here to not be updated from the input and thus we submit the initial (blank) value to the API.
      // Adding $nextTick allows the two-way-binding to catch up and everything else to work.
      this.$nextTick(() => {
        if (this.validityState.isValid()) {
          this.$emit('phoneVerification:verify-phone', this.tokenValue)
        }
      })
    },
    toggleResendButtonDisabled() {
      this.resendButtonDisabled = !this.resendButtonDisabled
    },
    resendButtonDisabledTimer() {
      this.toggleResendButtonDisabled()
      setTimeout(this.toggleResendButtonDisabled, 60000)
    },
    resendVerificationCode() {
      this.resendButtonDisabledTimer()
      this.$emit('phoneVerification:resend-code')
    },
  },
  watch: {
    verifyPayload(newValue) {
      this.tokenValue = (newValue && newValue.verifyValue) || ''
    },
  },
}
</script>

<style lang="scss" scoped>
.account-verify-phone-container {
  margin-top: 2rem;
  padding: 20px;
  width: 95%;
  max-width: 370px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    display: block;
    margin: 0 auto;
  }
  h2 {
    margin-bottom: 0px;
    text-transform: uppercase;
    padding: 24px 0;
    letter-spacing: -1px;
    line-height: 0.9;
    width: 100%;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    color: $catering-mobile-header;
  }
  span {
    width: 100%;
  }
}
.account-verify-phone-instructions {
  padding: 0 30px;
  text-align: center;
}
.account-verify-phone-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  width: 100%;
  align-self: center;
}

.account-verify-phone-btn {
  height: 40px;
  font-size: 16px;
  letter-spacing: -0.3px;
}
.account-phone-resend-btn {
  color: $catering-gold;
  font-family: $font-family-sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-top: 1rem;
  letter-spacing: -0.3px;
  border: none;
  background: none;
  cursor: pointer;

  &:disabled {
    color: $catering-grey;
  }
}

.account-verify-phone-btn,
.account-phone-resend-btn {
  &:disabled {
    cursor: not-allowed;
  }
}

.account-verify-customer-support-info {
  a {
    color: $catering-chocolate;
  }
}

.error {
  position: relative;
  top: -2.5rem;
  color: red;
}
</style>
