var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"food-selector",attrs:{"aria-disabled":"true","tabindex":0},on:{"click":_vm.select,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.select.apply(null, arguments)}}},[_c('img',{attrs:{"src":_vm.foodInfo.imageUrl,"alt":_vm.foodInfo.name}}),_c('div',{staticClass:"selected-background",class:{
      selected: _vm.count > 0,
      disabled: _vm.count === 0 && _vm.availableTotal === 0,
    }}),_c('div',{staticClass:"food-selector-container"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!(_vm.count === 0 || _vm.max === 1)),expression:"!(count === 0 || max === 1)"}],staticClass:"minus-button",attrs:{"aria-label":"decrement"},on:{"click":_vm.decrement}},[_c('minus-svg')],1),(_vm.perItem)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.count > 0),expression:"count > 0"}],staticClass:"circle per-item-circle"},[_c('b-form-input',{ref:"perItemCount",attrs:{"type":"number","id":"per-item-count","min":"0","step":"1","value":_vm.count.toString()},on:{"change":_vm.validateItemCount},nativeOn:{"keypress":function($event){return _vm.onlyWholeNumber.apply(null, arguments)}}})],1):_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.count > 0),expression:"count > 0"}],staticClass:"circle",attrs:{"aria-live":"polite"}},[(_vm.max > 1)?_c('span',[_vm._v(_vm._s(_vm.count))]):_c('check-svg')],1),_c('button',{directives:[{name:"show",rawName:"v-show",value:(!(_vm.count === 0 || _vm.max === 1)),expression:"!(count === 0 || max === 1)"}],staticClass:"plus-button",attrs:{"disabled":_vm.availableTotal === 0,"aria-label":"increment"},on:{"click":_vm.increment}},[_c('plus-svg')],1)]),_c('div',{staticClass:"food-name",class:{
      selected: _vm.count > 0,
      disabled: _vm.count === 0 && _vm.availableTotal === 0,
    }},[_vm._v(" "+_vm._s(_vm.foodInfo.name)+" "),(_vm.isVegan(_vm.foodInfo))?_c('div',[_vm._v("(vegan)")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }