// export a factory function to ensure a unique
// instance per required instantiation
export const validityStateInitialize = () => {
  return {
    state: {},
    map: new Map(),
    __isStateValid() {
      return Object.keys(this.state).every(
        (x) => this.state[x].isValid === true,
      )
    },
    setPropIsValid(event) {
      this.state[event.id] = {
        isValid: event.isValid,
        errorMessage: event.errorMessage,
      }
    },
    addHandler(key, validateHandler, resetHandler) {
      this.map.set(key, { validateHandler, resetHandler })
    },
    removeHandler(key) {
      this.map.delete(key)
      delete this.state[key]
    },
    isValid() {
      // find first invalid input to focus
      let id = ''
      Object.keys(this.state).some((k) => {
        const isValid = this.state[k].isValid

        if (!isValid) {
          id = k
        }

        return isValid === false
      })

      // force a retouch of all validation calls held in the map
      this.map.forEach((value, key) => {
        if (id === '') {
          // if no id has been found, the form has not been interacted with yet
          // force focus of the first element and use a fake id to pass
          // false for the rest of the elements.
          value.validateHandler(true)
          id = 'theElementHasBeenTouched__FakeId'
        } else {
          value.validateHandler(key === id)
        }
      })
      return this.__isStateValid()
    },
    reset() {
      this.state = {}
      this.map.forEach((value) => {
        value.resetHandler()
      })
    },
  }
}
