export default () => {
  const {
    VUE_APP_KETCH_PROPERTY_CODE,
    VUE_APP_KETCH_REGION_DOMAIN_URL,
    VUE_APP_KETCH_ORG_CODE,
    VUE_APP_KETCH_BOOT_FILE_PATH,
  } = process.env
  !(function () {
    ;(window.semaphore = window.semaphore || []),
      (window.ketch = function () {
        window.semaphore.push(arguments)
      })
    const e = new URLSearchParams(document.location.search),
      o = e.has('property')
        ? e.get('property')
        : `${VUE_APP_KETCH_PROPERTY_CODE}`,
      n = document.createElement('script')
    ;(n.type = 'text/javascript'),
      (n.src =
        `${VUE_APP_KETCH_REGION_DOMAIN_URL}/${VUE_APP_KETCH_ORG_CODE}/`.concat(
          o,
          `${VUE_APP_KETCH_BOOT_FILE_PATH}`,
        )),
      (n.defer = n.async = !0),
      document.getElementsByTagName('head')[0].appendChild(n)
  })()
  !(function () {
    document.addEventListener('DOMContentLoaded', function () {
      function preventDefaultByClassName(className, preventDefaultAction) {
        const elements = document.getElementsByClassName(className)
        for (const element of elements) {
          element.addEventListener('click', (event) => {
            event.preventDefault()
            preventDefaultAction()
          })
        }
      }
      preventDefaultByClassName('data-request-over-ride', () => {
        window.semaphore.push(['showPreferences', { tab: 'rightsTab' }])
      })
    })
  })()
}
