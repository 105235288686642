<template>
  <div
    id="accountVerifyPasswordContainer"
    class="account-verify-password-container"
  >
    <img
      src="@/static/img/header/cmg-medallion-logo.svg"
      alt="Medallion"
      class="medallion"
    />
    <h1>Verify Your Password</h1>
    <span class="account-verify-password-instructions">
      Re-enter your Chipotle password to continue
    </span>
    <inputbox
      id="accountPassword"
      :type="passwordInputType"
      v-model="passwordModel"
      class="mt-5 mb-4"
      required
      placeholder="Password"
      :max-length="50"
      :validity-state="validityState"
      :rules="[rules.required('Password required')]"
    >
      <template slot="icon">
        <eye-open-svg
          v-show="!passwordVisible"
          aria-label="Show password"
          :aria-hidden="passwordVisible"
          role="button"
          tabindex="0"
          @eyeopensvg-click="showPassword"
          @keydown.native.enter="showPassword"
        />
        <eye-closed-svg
          v-show="passwordVisible"
          aria-label="Hide password"
          :aria-hidden="!passwordVisible"
          role="button"
          tabindex="0"
          @eyeclosedsvg-click="hidePassword"
          @keydown.native.enter="hidePassword"
        />
      </template>
    </inputbox>
    <span class="error">
      {{ errorMessage }}
    </span>
    <div class="account-verify-password-button-container">
      <b-button
        variant="primary"
        block
        size="md"
        class="account-verify-password-btn"
        :disabled="accountLocked"
        @click.stop="verifyPassword"
      >
        VERIFY
      </b-button>
      <button
        class="account-password-cancel-btn"
        role="button"
        tabindex="0"
        @click.stop="$emit('passwordVerification:cancel-verification')"
      >
        CANCEL
      </button>
    </div>
  </div>
</template>

<script>
import eyeOpenSvg from '@/components/common/EyeOpenSvg'
import eyeClosedSvg from '@/components/common/EyeClosedSvg'
import inputbox from '@/components/common/Inputbox'
import { required } from '@/validators/index'

export default {
  name: 'account-verify-password',
  props: {
    verifyPayload: {
      type: Object,
      required: false,
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
    accountLocked: {
      type: Boolean,
      required: false,
      default: false,
    },
    validityState: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  components: {
    eyeOpenSvg,
    eyeClosedSvg,
    inputbox,
  },
  data() {
    return {
      passwordModel: '',
      resendButtonDisabled: false,
      rules: {
        required,
      },
      passwordVisible: false,
    }
  },
  computed: {
    passwordInputType() {
      return this.passwordVisible ? 'text' : 'password'
    },
  },
  methods: {
    showPassword() {
      this.passwordVisible = true
    },
    hidePassword() {
      this.passwordVisible = false
    },
    verifyPassword() {
      // Strange behavior on mobile (actual device) browsers where this click event is fired before the v-model two way binding can complete.
      // This causes the value of this.passwordModel being used here to not be updated from the input and thus we submit the initial (blank) value to the API.
      // Adding $nextTick allows the two-way-binding to catch up and everything else to work.
      this.$nextTick(() => {
        if (this.validityState.isValid()) {
          this.$emit('passwordVerification:verify-password', this.passwordModel)
        }
      })
    },
  },
  watch: {
    verifyPayload(newValue) {
      this.passwordModel = (newValue && newValue.verifyValue) || ''
    },
  },
}
</script>

<style lang="scss" scoped>
.account-verify-password-container {
  margin-top: 2rem;
  padding: 20px;
  width: 95%;
  max-width: 370px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    display: block;
    margin: auto;
  }
  h1 {
    margin-bottom: 0px;
    text-transform: uppercase;
    padding: 24px 0;
    letter-spacing: -1px;
    line-height: 0.9;
    width: 100%;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    color: $catering-mobile-header;
  }
  span {
    width: 100%;
  }

  .form-group {
    & > div {
      position: relative;
    }

    .seeing-eye {
      position: absolute;
      top: 6px;
      left: 94%;
      cursor: pointer;

      path {
        fill: $catering-chocolate;
      }
      circle {
        stroke: $catering-chocolate;
      }
    }
  }

  input[type='text'],
  input[type='password'] {
    background-color: transparent;
    font-size: 19px;
    letter-spacing: normal;
  }
}
.account-verify-password-instructions {
  padding: 0 30px;
  text-align: center;
}
.account-verify-password-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  width: 100%;
  align-self: center;
}

.account-verify-password-btn {
  height: 40px;
  font-size: 16px;
  letter-spacing: -0.3px;
}
.account-password-cancel-btn {
  color: $catering-gold;
  margin-right: 10px;
  font-family: $font-family-sans-serif;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin-top: 1rem;
  letter-spacing: -0.3px;
  border: none;
  background: none;
  cursor: pointer;

  &:disabled {
    color: $catering-grey;
  }
}

.account-verify-password-btn,
.account-password-resend-btn {
  &:disabled {
    cursor: not-allowed;
  }
}
.error {
  position: relative;
  top: -2.5rem;
  color: red;
}
</style>
