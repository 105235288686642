import Vue from 'vue'
import errorService from '@/services/errorService'

Vue.config.errorHandler = function (err, vm, info) {
  if (vm.$options.propsData) {
    // console.log will get passed to trackJS telemetry
    console.log(
      `Props passed to component ${vm.$options.name} in file ${vm.$options.__file}`,
      vm.$options.propsData,
    )
  }

  if (info) {
    const infoMessage = `Error occurred during component ${vm.$options.name} in file ${vm.$options.__file} lifecycle hook: ${info}\n`
    console.log(infoMessage)
  }

  errorService.handle(err)
}
