<template>
  <div
    class="food-selector"
    aria-disabled="true"
    :tabindex="0"
    @click="select"
    @keydown.enter="select"
  >
    <img :src="foodInfo.imageUrl" :alt="foodInfo.name" />
    <div
      class="selected-background"
      :class="{
        selected: count > 0,
        disabled: count === 0 && availableTotal === 0,
      }"
    />
    <div class="food-selector-container">
      <button
        v-show="!(count === 0 || max === 1)"
        class="minus-button"
        aria-label="decrement"
        @click="decrement"
      >
        <minus-svg />
      </button>
      <div v-if="perItem" v-show="count > 0" class="circle per-item-circle">
        <b-form-input
          type="number"
          id="per-item-count"
          ref="perItemCount"
          min="0"
          step="1"
          :value="count.toString()"
          @change="validateItemCount"
          @keypress.native="onlyWholeNumber"
        />
      </div>
      <div v-else v-show="count > 0" class="circle" aria-live="polite">
        <span v-if="max > 1">{{ count }}</span>
        <check-svg v-else />
      </div>
      <button
        v-show="!(count === 0 || max === 1)"
        class="plus-button"
        :disabled="availableTotal === 0"
        aria-label="increment"
        @click="increment"
      >
        <plus-svg />
      </button>
    </div>
    <div
      class="food-name"
      :class="{
        selected: count > 0,
        disabled: count === 0 && availableTotal === 0,
      }"
    >
      {{ foodInfo.name }}
      <div v-if="isVegan(foodInfo)">(vegan)</div>
    </div>
  </div>
</template>
<script>
import minusSvg from '../common/MinusSvg'
import plusSvg from '../common/PlusSvg'
import checkSvg from '../common/CheckSvg'
import { onlyWholeNumber } from '../../utilityFunctions'
import { VeganItemList } from '@/constants'

export default {
  props: ['foodInfo', 'availableTotal', 'max', 'perItem'],
  components: {
    minusSvg,
    plusSvg,
    checkSvg,
  },
  data() {
    return {
      count: 0,
    }
  },
  methods: {
    getSubItem() {
      let subItem = null

      if (this.count > 0) {
        subItem = {
          menuId: this.foodInfo.id,
          name: this.foodInfo.name,
          quantity: this.count,
        }
      }
      return subItem
    },
    isVegan(foodInfo) {
      return VeganItemList[foodInfo.id]
    },
    onlyWholeNumber(eventArgs) {
      onlyWholeNumber(eventArgs)
    },
    validateItemCount(eventArgs) {
      if (
        Number(eventArgs) <=
        Number(this.availableTotal) + Number(this.count)
      ) {
        this.inputChange(eventArgs)
      } else {
        const newTotal = Number(this.availableTotal) + Number(this.count)

        // TODO: foodInfo is store data that should not be updated outside mutation.
        // refactor to maintain the functionality correctly.
        this.foodInfo.manualCount = newTotal
        this.inputChange(newTotal, {
          overLimit: true,
          limitErrorMessage:
            'SORRY! LIMITED TO ' +
            newTotal.toString() +
            ' ' +
            this.foodInfo.name,
        })
        if (
          this.$refs.perItemCount.value !== this.$refs.perItemCount.$el.value
        ) {
          this.$refs.perItemCount.$el.value = this.$refs.perItemCount.value
        }
      }
    },
    inputChange(value, options) {
      const lastCount = Number(this.count)
      this.count = Number(value)

      let trackCount = true
      let overLimit = false
      let limitErrorMessage = ''
      let isInit = false
      if (options) {
        trackCount = options.trackCount
        overLimit = options.overLimit
        limitErrorMessage = options.limitErrorMessage
        isInit = options.isInit
      }
      this.$emit('food-selector-increment', {
        id: this.foodInfo.id,
        delta: this.count - lastCount,
        count: this.count,
        trackCount,
        overLimit,
        isInit,
        limitErrorMessage,
        foodInfoId: this.foodInfo.id,
      })
    },
    increment(e) {
      if (this.availableTotal > 0) {
        if (e) {
          e.cancelBubble = true
        }
        const lastCount = this.count
        this.count++
        this.$emit('food-selector-increment', {
          id: this.foodInfo.id,
          delta: this.count - lastCount,
          count: this.count,
          trackCount: true,
          isInit: false,
          foodInfoId: this.foodInfo.id,
        })
      }
    },
    decrement(e, doNotTrack) {
      if (this.count > 0) {
        if (e) {
          e.cancelBubble = true
        }
        const lastCount = this.count
        this.count--
        this.$emit('food-selector-decrement', {
          id: this.foodInfo.id,
          delta: lastCount - this.count,
          count: this.count,
          trackCount: !doNotTrack,
          isInit: false,
          foodInfoId: this.foodInfo.id,
        })
      }
    },
    select(e) {
      if (this.count === 0) {
        if (this.max === 1 && this.availableTotal === 0) {
          this.$emit('food-selector-request-deselect-sibling', {
            id: this.foodInfo.id,
          })
        } else {
          this.increment()
        }
      } else if (this.max === 1 && !this.perItem) {
        this.deselect()
      }
    },
    deselect(doNotTrack) {
      if (this.count >= 0) {
        this.count = 1
        this.decrement(null, doNotTrack)
      }
    },
  },
  watch: {},
}
</script>
<style lang="scss">
.food-selector {
  user-select: none;
  position: relative;
  cursor: pointer;
  font-family: $font-family-sans-serif;
  color: $catering-white;
  box-shadow: $catering-box-shadow;

  img {
    object-fit: cover;
    width: 100%;
    border-radius: 4px;
  }

  .food-selector-container {
    position: absolute;
    top: 24%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 30%;

    .circle {
      background-color: $catering-yellow;
      border: 2px solid $catering-white;
      border-radius: 50%;
      flex: 0 0 auto;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      grid-column: 2;
      align-self: center;
      width: 2.5em;
      height: 2.5em;
      font-size: 16px;

      &.per-item-circle {
        background-color: $catering-white;
      }

      input {
        width: 30px;
        height: 20px;
        font-size: 16px;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        border-bottom: 1px solid $catering-yellow;
        background-color: transparent;
        text-align: center;
        color: $catering-desktop-label;
        font-family: $font-family-sans-serif;
        font-weight: bold;
        padding: 0;
      }

      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .minus-button {
      grid-column: 1;
      justify-self: end;
    }

    .plus-button {
      grid-column: 3;
      justify-self: start;
    }

    .minus-button,
    .plus-button {
      position: relative;
      align-self: center;
      height: 50%;
      width: 50%;
      padding: 0;
      border: 0;
      border-radius: 50%;
      background: transparent;

      svg {
        width: 100%;
        height: 100%;
      }

      &:disabled {
        line {
          opacity: 0.5;
        }
        ellipse {
          fill: $catering-light-grey;
          opacity: 0.5;
        }
      }
    }
  }

  .selected-background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;

    &.selected {
      background-image: linear-gradient(
        to bottom,
        $catering-selected-gradient-target,
        $catering-selectedimage-overlay
      );
    }
    &.disabled {
      background: $catering-builder-bg-disabled;
      opacity: 0.5;
    }
  }

  .food-name {
    position: absolute;
    text-transform: uppercase;
    font-size: 14px;
    padding: 0 0.5em;
    font-weight: bold;
    line-height: 1;
    height: 2em;
    bottom: 0.7em;
    text-align: center;
    width: 100%;
    color: $catering-desktop-label;

    &.selected {
      color: $catering-white;
    }
    &.disabled {
      opacity: 0.5;
    }
    & div {
      text-transform: lowercase;
      font-weight: normal;
      font-family: $font-family-helvetica;
      font-size: 13px;
    }
  }
}

@include media-breakpoint-up(sm) {
  .food-selector {
    .food-selector-container {
      .circle {
        font-size: 18px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .food-selector {
    .food-name {
      font-size: 18px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .food-selector {
    .food-selector-container {
      .circle {
        width: 2.5em;
        height: 2.5em;
      }

      .minus-button {
        right: 8px;
      }

      .plus-button {
        left: 8px;
      }

      .minus-button,
      .plus-button {
        height: 29px;
        width: 29px;
      }
    }

    .food-name {
      font-size: 16px;
    }

    .selected-background {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;

      &.selected {
        background-image: linear-gradient(
          to bottom,
          $catering-selected-gradient-target,
          $catering-selectedimage-overlay
        );
      }
    }
  }
}
</style>
