<template>
  <div>
    <header id="header" class="cmg-header" @click="closeOpenDrawer">
      <div class="content-left">
        <div
          v-show="canOpenDrawers"
          class="hamburger-icon-container"
          tabindex="0"
          role="button"
          @click.stop="toggleLoginDrawer"
          @keydown.enter="toggleLoginDrawer"
        >
          <img
            class="hamburger-icon"
            src="@/static/img/header/menu-hamburger.svg"
            alt="hamburger menu"
          />
        </div>
        <router-link :to="{ name: HOME }">
          <img
            class="cmg-logo"
            src="@/static/img/header/cmg-medallion-logo.svg"
            alt="Chipotle Mexican Grill"
          />
        </router-link>
        <div
          v-show="canOpenDrawers"
          class="user-icon-container"
          tabindex="0"
          role="button"
          @click.stop="toggleLoginDrawer"
          @keydown.enter="toggleLoginDrawer"
        >
          <img
            class="user-icon"
            src="@/static/img/header/user-outline.svg"
            alt="user"
          />
          <h3 class="user-greeting">{{ greeting }}</h3>
        </div>
      </div>
      <header-links class="header-links" :links="links" />
      <div class="content-right">
        <div
          v-show="canOpenDrawers"
          class="bag-icon-container"
          tabindex="0"
          role="button"
          @click.stop="toggleBag"
          @keydown.enter="toggleBag"
        >
          <img class="bag-icon" src="@/static/img/header/bag.svg" alt="bag" />
          <div
            v-show="orderItems.length"
            class="item-count"
            aria-label="item count"
          >
            {{ orderItems.length }}
          </div>
        </div>
      </div>
    </header>
    <!-- TODO: refactor all modals out to either respective view component or App component -->
    <main-menu-modal
      v-model="showLoginDrawer"
      :links="links"
      @create-an-account-click="showCreateAccountModal = true"
    />
    <create-an-account-modal v-model="showCreateAccountModal" />
    <bag-modal v-model="showBag" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import config from '@/components/layout/headerConfig'
import HeaderLinks from '@/components/account/HeaderLinks'
import MainMenuModal from '@/components/account/MainMenu'
import CreateAnAccountModal from '@/components/account/CreateAnAccount'
import BagModal from '@/components/bag/Bag'
import { HOME } from '@/router/routeNames'
import {
  TOGGLE_LOGIN_DRAWER,
  TOGGLE_BAG,
  CLOSE_LOGIN_DRAWER,
  CLOSE_BAG,
  CLOSE_OPEN_DRAWER,
} from '@/store/mutation-types/drawersMutations'

export default {
  name: 'CmgHeader',
  components: {
    HeaderLinks,
    BagModal,
    CreateAnAccountModal,
    MainMenuModal,
  },
  data() {
    return {
      showCreateAccountModal: false,
      links: config.links,
      HOME,
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'isFullUser',
      'orderItems',
      'loginDrawerIsOpened',
      'bagIsOpened',
      'canOpenDrawers',
    ]),
    showLoginDrawer: {
      get() {
        return this.loginDrawerIsOpened
      },
      set(value) {
        !value && this.closeLoginDrawer()
      },
    },
    showBag: {
      get() {
        return this.bagIsOpened
      },
      set(value) {
        !value && this.closeBag()
      },
    },
    greeting() {
      if (this.isFullUser) {
        return `HEY ${this.currentUser.firstName.toUpperCase()}`
      }
      return 'SIGN IN'
    },
  },
  methods: {
    ...mapActions({
      toggleLoginDrawer: TOGGLE_LOGIN_DRAWER,
      closeLoginDrawer: CLOSE_LOGIN_DRAWER,
      toggleBag: TOGGLE_BAG,
      closeBag: CLOSE_BAG,
      closeOpenDrawer: CLOSE_OPEN_DRAWER,
    }),
  },
}
</script>

<style lang="scss" scoped>
.cmg-header {
  position: fixed;
  width: 100%;
  height: $header-height-default;
  display: inline-flex;
  align-items: center;
  border-bottom: thin solid $catering-counter-circle-unfilled-border;
  // TODO: why 10? investigate what z-indexes are currently in use and consolidate into variables
  z-index: 10;
  color: $catering-chocolate;
  background-color: $catering-white;
  padding: 0 16px;

  .content-left,
  .content-right {
    flex: 1 1 0;
    display: flex;
  }

  .content-center {
    display: flex;
  }

  .content-right {
    justify-content: flex-end;
  }

  .hamburger-icon-container {
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .cmg-logo {
    width: 58px;
  }

  .user-icon-container {
    display: none;
    align-items: center;
    cursor: pointer;
    margin-left: 20px;
  }

  .user-greeting {
    margin: 0;
    font-size: 13px;
    font-family: $font-family-helvetica;
    letter-spacing: 0.3px;
  }

  .header-links {
    display: none;
  }

  .bag-icon-container {
    position: relative;
    cursor: pointer;
    margin-right: 30px;
  }

  .item-count {
    position: absolute;
    top: 30%;
    right: 12%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    font-size: 12px;
    line-height: 12px;
    background-color: $catering-red;
    color: $catering-white;
  }
}

@include media-breakpoint-up(sm) {
  .cmg-header {
    .user-icon-container {
      display: flex;
    }
  }
}

@include media-breakpoint-up(xl) {
  .cmg-header {
    height: $header-height-tall;

    .hamburger-icon-container {
      display: none;
    }

    .cmg-logo {
      width: 81px;
    }

    .header-links {
      display: flex;
    }
  }
}
</style>
