import { getNextTime } from '@/utilityFunctions'

export default function toStringDateTimeRange(value) {
  if (!value || !value.toLocaleDateString) {
    return value
  }

  const format = {
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }

  const baseDate = value.toLocaleDateString('en-US', format)
  const addedTime = getNextTime(value, 15, 'en-US', {
    hour: 'numeric',
    minute: '2-digit',
  })

  return `${baseDate} - ${addedTime}`
}
