import './track-js'
import './errorHandler'
import analyticsLoader from './analytics/gtag'
import './analytics/googleTagManager'
import ketchLoader from './ketch'
import dataDogLoader from './dataDog'

analyticsLoader()
ketchLoader()
dataDogLoader()
