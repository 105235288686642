<template>
  <div class="bag-item-container">
    <bag-item-build-your-own v-show="itemIsBuildYourOwn" v-bind:item="item" />
    <bag-item-burritos-by-the-box
      v-show="itemIsBurritosByTheBox"
      v-bind:item="item"
    />
    <bag-item-chips-and-salsa v-show="itemIsChipsAndSalsa" v-bind:item="item" />
    <div class="bag-item-spacer" />
  </div>
</template>

<script>
import { HomeMenuIds } from '@/constants'
import bagItemBuildYourOwn from '@/components/bag/BagItemBuildYourOwn'
import bagItemBurritosByTheBox from '@/components/bag/BagItemBurritosByTheBox'
import bagItemChipsAndSalsa from '@/components/bag/BagItemChipsAndSalsa'

export default {
  name: 'bag-item',
  props: ['item'],
  components: {
    bagItemBuildYourOwn,
    bagItemBurritosByTheBox,
    bagItemChipsAndSalsa,
  },
  computed: {
    itemIsBuildYourOwn() {
      return HomeMenuIds.BuildYourOwnIds.indexOf(this.item.menuId) > -1
    },
    itemIsChipsAndSalsa() {
      return this.item.menuId === HomeMenuIds.ChipsAndSalsaId
    },
    itemIsBurritosByTheBox() {
      return this.item.menuId === HomeMenuIds.BurritosByTheBoxId
    },
  },
}
</script>
