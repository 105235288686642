// actions
export const SAVE_DELIVERY_ADDRESS = 'SAVE_DELIVERY_ADDRESS'
export const UPDATE_DELIVERY_ADDRESS = 'EDIT_DELIVERY_ADDRESS'
export const DELETE_DELIVERY_ADDRESS = 'DELETE_DELIVERY_ADDRESS'
export const GET_ALL_SAVED_ADDRESSES = 'GET_ALL_SAVED_ADDRESSES'

// mutations
export const SAVE_ADDRESS = 'SAVE_ADDRESS'
export const UPDATE_ADDRESS = 'EDIT_ADDRESS'
export const DELETE_ADDRESS = 'DELETE_ADDRESS'
export const UPDATE_ALL_SAVED_ADDRESSES = 'UPDATE_ALL_SAVED_ADDRESSES'
export const USER_ADDRESSES_IS_PENDING = 'USER_ADDRESSES_IS_PENDING'
