import { SET_IS_SCROLLED } from '@/store/mutation-types/uiCommonMutations'

const state = {
  isScrolled: false,
}

const getters = {
  isScrolled: (state) => state.isScrolled,
}

const actions = {
  [SET_IS_SCROLLED]({ commit }, isScrolled) {
    commit(SET_IS_SCROLLED, isScrolled)
  },
}

const mutations = {
  [SET_IS_SCROLLED](state, isScrolled) {
    state.isScrolled = isScrolled
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
