export const QUEUE_LOADER = 'QUEUE_LOADER'
export const SHOW_LOADER = 'SHOW_LOADER'
export const CLEAR_LOADER = 'CLEAR_LOADER'

export const UPDATE_QUEUE_LOADER_TIMER = 'UPDATE_QUEUE_LOADER_TIMER'
export const UPDATE_LOADER_DISPLAY = 'UPDATE_LOADER_DISPLAY'
export const UPDATE_LOADER_MAIN_TEXT = 'UPDATE_LOADER_MAIN_TEXT'
export const UPDATE_LOADER_SUB_TEXT = 'UPDATE_LOADER_SUB_TEXT'
export const DEQUEUE_DATA = 'DEQUEUE_DATA'
export const TRACK_DISPLAYED = 'TRACK_DISPLAYED'
export const UNTRACK_DISPLAYED = 'UNTRACK_DISPLAYED'
export const UPDATE_LOADER_INCLUDE_OVERLAY = 'UPDATE_LOADER_INCLUDE_OVERLAY'
