// actions
export const ADD_POPULAR_BUILD = 'ADD_POPULAR_BUILD'
export const CANCEL_SUBMITTED_ORDER = 'CANCEL_SUBMITTED_ORDER'
export const DELETE_ORDER_ITEM = 'DELETE_ORDER_ITEM'
export const GET_CART = 'GET_CART'
export const GET_IS_CHANGEABLE_SUBMITTED_ORDER =
  'GET_IS_CHANGEABLE_SUBMITTED_ORDER'
export const GET_ORDER = 'GET_ORDER'
export const GET_SUBMITTED_ORDER = 'GET_SUBMITTED_ORDER'
export const MARK_SUBMITTED_ORDER_FOR_UPDATE = 'MARK_SUBMITTED_ORDER_FOR_UPDATE'
export const REFRESH_ORDER_PRICE = 'REFRESH_ORDER_PRICE'
export const REORDER = 'REORDER'
export const SAVE_CUSTOMER = 'SAVE_CUSTOMER'
export const SAVE_INTAKE = 'SAVE_INTAKE'
export const SAVE_ORDER_DELIVERY_ADDRESS = 'SAVE_ORDER_DELIVERY_ADDRESS'
export const SAVE_ORDER_ITEM = 'SAVE_ORDER_ITEM'
export const SAVE_PICKUP_INFO = 'SAVE_PICKUP_INFO'
export const SUBMIT_ORDER = 'SUBMIT_ORDER'
export const TRANSFER_CART = 'TRANSFER_CART'
export const TRANSFER_ORDER = 'TRANSFER_ORDER'
export const UNDO_SUBMITTED_ORDER_FOR_UPDATE = 'UNDO_SUBMITTED_ORDER_FOR_UPDATE'
export const UPDATE_DELIVERY_TIP = 'UPDATE_DELIVERY_TIP'
export const VALIDATE_ORDER = 'VALIDATE_ORDER'

// mutations
export const CLEAR_ORDER = 'CLEAR_ORDER'
export const SET_INTAKE_LOAD_ERROR = 'SET_INTAKE_LOAD_ERROR'
export const SET_IS_CHANGEABLE_SUBMITTED_ORDER =
  'SET_IS_CHANGEABLE_SUBMITTED_ORDER'
export const SET_IS_ORDER_BEING_SUBMITTED = 'SET_IS_ORDER_BEING_SUBMITTED'
export const SET_SUBMITTED_ORDER = 'SET_SUBMITTED_ORDER'
export const UPDATE_CART_LOADER = 'UPDATE_CART_LOADER'
export const UPDATE_ORDER = 'UPDATE_ORDER'
export const UPDATE_ORDER_VALIDITY = 'UPDATE_ORDER_VALIDITY'
export const UPDATE_SEARCH_INFO = 'UPDATE_SEARCH_INFO'
