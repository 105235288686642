import * as types from '@/store/mutation-types/popularBuildsMutations'
import popularBuildsService from '@/services/popularBuildsService'
import { popularBuildsImageList } from '@/constants'

const state = {
  popularBuilds: {
    data: null,
    error: null,
    isPending: false,
  },
}

function getPriceToDisplay(popularBuild, menu) {
  if (!getIsAvailable(popularBuild, menu)) {
    return 0
  }
  return popularBuild.menuItems.reduce((price, m) => {
    const found = menu.filter((menuItem) => menuItem.id === m.menuItemId)[0]
    return price + found.minPrice
  }, 0)
}

function getIsAvailable(popularBuild, menu) {
  return popularBuild.menuItems.every((m) =>
    menu.some(
      (menuItem) =>
        menuItem.id === m.menuItemId && typeof menuItem.minPrice === 'number',
    ),
  )
}

const getters = {
  popularBuildsHaveError: (state) => !!state.popularBuilds.error,
  popularBuildsArePending: (state) => state.popularBuilds.isPending,
  popularBuilds: (state) => state.popularBuilds.data || [],
  activePopularBuildsWithMeta: (state, getters, rootState, rootGetters) =>
    getters.popularBuilds
      .filter((popularBuild) => popularBuild.active)
      .map((popularBuild) => ({
        ...popularBuild,
        imageUrl: popularBuildsImageList[popularBuild.id],
        priceToDisplay: getPriceToDisplay(
          popularBuild,
          rootGetters.allTieredMenu,
        ),
        isAvailable: getIsAvailable(popularBuild, rootGetters.allTieredMenu),
      })),
}

const mutations = {
  [types.GET_POPULAR_BUILDS_PENDING](state) {
    state.popularBuilds.isPending = true
  },
  [types.GET_POPULAR_BUILDS_SUCCESS](state, data) {
    state.popularBuilds.data = data
    state.popularBuilds.isPending = false
  },
  [types.GET_POPULAR_BUILDS_FAILURE](state, error) {
    state.popularBuilds.error = error
    state.popularBuilds.isPending = false
  },
}

const actions = {
  [types.GET_POPULAR_BUILDS]({ commit }) {
    commit(types.GET_POPULAR_BUILDS_PENDING)
    return popularBuildsService
      .getPopularBuilds()
      .then(({ data }) => {
        commit(types.GET_POPULAR_BUILDS_SUCCESS, data)
        return data
      })
      .catch((err) => {
        commit(types.GET_POPULAR_BUILDS_FAILURE, err)
        throw err
      })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
