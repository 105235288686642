import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import { identity } from '@/utilityFunctions'

function defaultErrorHandler(error, _options) {
  // get directly to the meat of the response for easier access in the components
  if (error && error.response && error.response.data) {
    error = error.response.data
  }

  throw error
}

function getDefaultHeaders() {
  return {
    'Ocp-Apim-Subscription-Key': process.env.VUE_APP_SUBSCRIPTION_KEY,
    'Content-Type': 'application/json',
    'Chipotle-CorrelationId': `COO-${uuidv4()}`,
    Accept: 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '-1',
  }
}

// In order to honor the axios function contract
// on use(...) a generic function that wraps
// a Promise.reject that returns with the ERROR.
function rejection(error) {
  return Promise.reject(error)
}

function createAxiosInstance(config) {
  const axiosInstance = axios.create({
    ...config,
  })
  axiosInstance.interceptors.request.use(identity, rejection)
  axiosInstance.interceptors.response.use(identity, rejection)

  return axiosInstance
}

function baseHttpCall(
  { method, url, data, params = null, options = {} },
  axiosInstance,
  errorHandler,
) {
  // Not defaulted in axiosInstance because ignoring defaults is difficult
  // See: https://github.com/axios/axios/issues/382
  const headers = options.headers || getDefaultHeaders()
  const req = {
    headers,
    method,
    url,
    data,
    params,
  }

  return axiosInstance(req).catch((error) => {
    errorHandler(error, options)
  })
}

export function createClient(config, errorHandler = defaultErrorHandler) {
  const axiosInstance = createAxiosInstance(config)
  return {
    get(url, params, options) {
      const args = {
        method: 'GET',
        url,
        params,
        options,
      }
      return baseHttpCall(args, axiosInstance, errorHandler)
    },
    post(url, data, options) {
      const args = {
        method: 'POST',
        url,
        data,
        options,
      }
      return baseHttpCall(args, axiosInstance, errorHandler)
    },
    put(url, data, options) {
      const args = {
        method: 'PUT',
        url,
        data,
        options,
      }
      return baseHttpCall(args, axiosInstance, errorHandler)
    },
    delete(url, options) {
      const args = {
        method: 'DELETE',
        url,
        options,
      }
      return baseHttpCall(args, axiosInstance, errorHandler)
    },
  }
}
