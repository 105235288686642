import errorService from '@/services/errorService'
import { loadScript } from '@/utilityFunctions'

export default () => {
  const {
    VUE_APP_DATA_DOG_ENABLED,
    VUE_APP_DATA_DOG_SCRIPT_URL,
    VUE_APP_DATA_DOG_CLIENT_TOKEN,
    VUE_APP_DATA_DOG_APPLICATION_ID,
    VUE_APP_DATA_DOG_SITE,
    VUE_APP_DATA_DOG_SERVICE,
    VUE_APP_DATA_DOG_ENVIRONMENT,
  } = process.env

  // Due to licensing costs, please only enable this on lower environments temporarily for testing.
  // This should be turned on long-term only for prod.
  if (VUE_APP_DATA_DOG_ENABLED.toUpperCase() !== 'TRUE') {
    return
  }

  const ddRum = (window.DD_RUM = window.DD_RUM || {
    q: [],
    onReady: (c) => ddRum.q.push(c),
  })

  loadScript(VUE_APP_DATA_DOG_SCRIPT_URL).catch(errorService.handle)

  ddRum.onReady(() => {
    window.DD_RUM.init({
      clientToken: VUE_APP_DATA_DOG_CLIENT_TOKEN,
      applicationId: VUE_APP_DATA_DOG_APPLICATION_ID,
      site: VUE_APP_DATA_DOG_SITE,
      service: VUE_APP_DATA_DOG_SERVICE,
      env: VUE_APP_DATA_DOG_ENVIRONMENT,
      sampleRate: 100,
      premiumSampleRate: 0,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
    })
    window.DD_RUM.startSessionReplayRecording()
  })
}
