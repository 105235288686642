import Vue from 'vue'
import Router from 'vue-router'

import routes from '@/router/routes'
import store from '@/store'
import { CLOSE_OPEN_DRAWER } from '@/store/mutation-types/drawersMutations'
import analyticsService from '@/services/analyticsService'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  scrollBehavior,
  routes,
})

router.beforeEach(beforeEach)
router.afterEach(afterEach)

export function scrollBehavior(to, from, savedPosition) {
  if (to.hash) {
    return { selector: to.hash }
  }
  return savedPosition || { x: 0, y: 0 }
}

export function beforeEach(to, from, next) {
  store.dispatch(CLOSE_OPEN_DRAWER).then(next)
}

export function afterEach(to, from) {
  /**
   * Routes with dynamic params should be considered the same page for analytics,
   * so we use matched[].path (where the params haven't been replaced) rather than to.path or to.fullPath.
   */
  const { path: toPath } = to.matched[0]
  const { path: fromPath } = from.matched[0] || {} // Initially is []

  if (toPath === fromPath) {
    return // Early return when param or query changes
  }
  const path = toPath || '/' // For home page, VueRouter gives us '', default to '/'
  const { title = '' } = to.meta
  const siteName = 'Chipotle Catering'
  document.title = title ? `${siteName} - ${title}` : siteName
  analyticsService.pageView(path, title)
}

export default router
