<template>
  <div class="account-menu-item">
    <div v-for="(link, i) in hamburgerLinks" :key="i" class="hamburger-link">
      <router-link
        class="link-hamburger-menu"
        :to="{ name: link.name, query: link.query || {} }"
      >
        {{ link.text }}
      </router-link>
    </div>
    <div class="hamburger-link">
      <div
        class="sign-out-item"
        role="button"
        tabindex="0"
        @keydown.enter="confirmLogout"
        @click="confirmLogout"
      >
        Sign out
      </div>
    </div>
  </div>
</template>

<script>
import { LOGOUT } from '@/store/mutation-types/userMutations'
import {
  CHANGE_PASSWORD,
  ORDER_HISTORY,
  PAYMENT_METHODS,
  UPDATE_USER,
  SAVED_ADDRESSES,
  HOME,
  BUILD_YOUR_OWN,
  BURRITOS_BY_THE_BOX,
  CHIPS_AND_SALSA,
} from '@/router/routeNames'
import { SHOW_CONFIRM_DIALOG } from '@/store/mutation-types/confirmDialogMutations'
import { CONFIRM_UNSAVED_CHANGES } from '@/constants/messages'

export default {
  name: 'account-menu',
  data() {
    return {
      hamburgerLinks: [
        { text: 'ORDER HISTORY', name: ORDER_HISTORY },
        { text: 'PAYMENT METHODS', name: PAYMENT_METHODS },
        {
          text: 'PERSONAL & PREFERENCES',
          name: UPDATE_USER,
          query: this.createLinkQuery(UPDATE_USER),
        },
        { text: 'SAVED ADDRESSES', name: SAVED_ADDRESSES },
        {
          text: 'CHANGE PASSWORD',
          name: CHANGE_PASSWORD,
          query: this.createLinkQuery(CHANGE_PASSWORD),
        },
      ],
    }
  },
  methods: {
    confirmLogout() {
      if (
        [BUILD_YOUR_OWN, BURRITOS_BY_THE_BOX, CHIPS_AND_SALSA].indexOf(
          this.$route.name,
        ) > -1 &&
        this.$store.getters.isBuilderDirty
      ) {
        this.$store.dispatch(SHOW_CONFIRM_DIALOG, {
          text: CONFIRM_UNSAVED_CHANGES,
          okCallback: this.logoutAndNavigate,
        })
      } else {
        this.logoutAndNavigate()
      }
    },
    logoutAndNavigate() {
      return this.$store
        .dispatch(LOGOUT)
        .then(() =>
          this.$router.push({ name: HOME, query: { signedOut: true } }),
        )
        .catch(() => {})
    },
    createLinkQuery(toName) {
      const { name, fullPath } = this.$store.state.route
      // We need the query params of the previous route for redirecting (since some are required)
      // We don't want the query string to exceed the url size so we trim the previous one if it exists
      const prevPath = fullPath.replace(/\?.+/, '')
      return name === toName ? {} : { prevPath }
    },
  },
}
</script>

<style lang="scss">
.account-menu-item {
  background-color: $catering-white;
  width: 100%;
  box-shadow: 0 0 1px 0 $catering-popup-shadow;

  .link-hamburger-menu {
    color: $catering-mobile-header;
    font-weight: bold;
  }
  .hamburger-link {
    padding: 0.5em 0 0.5em 1em;
  }
  .sign-out-item {
    cursor: pointer;
    color: $catering-grey-brown;
    font-family: $font-family-helvetica;
    font-size: 16px;
    text-decoration: underline;
  }

  .or-separator {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .border-line {
      background-color: $catering-light-grey;
      height: 1px;
      width: 50%;
    }
    .or-or {
      padding-left: 7px;
      padding-right: 7px;
      color: $catering-chocolate;
      font-family: $font-family-sans-serif;
      font-size: 11px;
      font-weight: bold;
      letter-spacing: -0.2px;
    }
  }
}
</style>
