// modified from: https://developers.google.com/analytics/devguides/collection/gtagjs
import { loadScript } from '@/utilityFunctions'
import errorService from '@/services/errorService'

export default () => {
  const { VUE_APP_GOOGLE_ANALYTICS_ID: id } = process.env
  const scriptURL = `https://www.googletagmanager.com/gtag/js?id=${id}`
  window.dataLayer = window.dataLayer || []
  // Do not use an arrow function here to prevent breaking gtag functionality.
  window.gtag = function () {
    window.dataLayer.push(arguments)
  }
  window.gtag('js', new Date())
  window.gtag('config', id)
  loadScript(scriptURL).catch((err) => {
    window.gtag = () => {}
    errorService.handle(err)
  })
}
