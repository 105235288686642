<template>
  <div class="full-bag">
    <div class="bag-full-container" id="bag-scroll-container">
      <bag-item
        v-for="item in orderItemsWithMenuData"
        v-bind:item="item"
        :key="item.orderItemId"
      />
      <div class="bag-item-container">
        <div class="bag-item-menu-header">
          <div class="bag-item-menu-header-label">SERVING ACCESSORIES</div>
          <div>FREE</div>
        </div>
        <div>{{ accessoriesText }}</div>
        <div class="bag-item-menu-footer">SETUP NOT INCLUDED</div>
      </div>
    </div>
    <bag-footer />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { HomeMenuIds } from '@/constants'
import bagFooter from '@/components/bag/BagFooter'
import bagItem from '@/components/bag/BagItem'

export default {
  name: 'full-bag',
  components: {
    bagFooter,
    bagItem,
  },
  computed: {
    ...mapGetters(['orderItemsWithMenuData', 'orderHasItems']),
    accessoriesText() {
      if (this.bagHasBuildYourOwn) {
        return 'Enough bowls, forks, and napkins for everyone, plus serving spoons and tongs.'
      }
      if (this.bagHasBurritosByTheBox) {
        return 'Enough napkins and forks for everyone.'
      }
      return 'Enough napkins for everyone, plus serving spoons.'
    },
    bagHasBuildYourOwn() {
      if (!this.orderHasItems) {
        return false
      }
      return this.orderItemsWithMenuData.some((item) => {
        return HomeMenuIds.BuildYourOwnIds.indexOf(item.menuId) > -1
      })
    },
    bagHasBurritosByTheBox() {
      if (!this.orderHasItems) {
        return false
      }
      return this.orderItemsWithMenuData.some((item) => {
        return item.menuId === HomeMenuIds.BurritosByTheBoxId
      })
    },
  },
}
</script>

<style lang="scss">
.full-bag {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;

  .bag-full-container {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    flex-grow: 1;
    background-color: $catering-white;
    box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.1);
    margin: 15px;

    @include media-breakpoint-up(sm) {
      margin: 20px;
    }
  }
}
</style>
