import { cateringOrderClient } from '@/services/clients'

export default {
  getCustomer() {
    return cateringOrderClient.get('/customer')
  },
  updateCustomer(request) {
    return cateringOrderClient.put('/customer', request)
  },
}
