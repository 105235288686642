import * as types from '@/store/mutation-types/deliveryMutations'
import * as loaderTypes from '@/store/mutation-types/loaderMutations'
import deliveryService from '@/services/deliveryService'

const state = {
  loaderName: 'delivery',
}

const getters = {}

const actions = {
  [types.GENERATE_DELIVERY_QUOTE](
    { dispatch, state },
    { deliveryAddress, deliveryTime, restaurantNumbers },
  ) {
    // NOTE: restaurantNumbers MUST originate from the real hours data from GET_DELIVERY_RESTAURANTS.
    return new Promise((resolve, reject) => {
      if (
        !deliveryAddress ||
        !deliveryTime ||
        !restaurantNumbers ||
        !restaurantNumbers.length
      ) {
        reject(
          new Error(
            'Order is missing required fields to generate delivery quote',
          ),
        )
        return
      }

      const request = {
        deliveryAddress: {
          street: deliveryAddress.address1,
          city: deliveryAddress.city,
          state: deliveryAddress.state,
          postalCode: deliveryAddress.postalCode,
          latitude: deliveryAddress.latitude,
          longitude: deliveryAddress.longitude,
          instructions: deliveryAddress.instructions,
        },
        deliveryTime,
        restaurantNumbers,
      }

      dispatch(loaderTypes.SHOW_LOADER, {
        name: state.loaderName,
        mainText: 'Generating Delivery Quote',
        subText: '',
      })

      deliveryService
        .generateDeliveryQuote(request)
        .then((deliveryQuoteResponse) => {
          resolve(deliveryQuoteResponse.data)
        })
        .catch((e) => {
          reject(e)
        })
        .finally(() => {
          dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
        })
    })
  },
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}
