<template>
  <div>
    <div class="bag-item-menu-header">
      <div class="bag-item-menu-header-label">{{ item.displayName }}</div>
      <div>{{ item.subTotal | toCurrency() }}</div>
    </div>
    <div>{{ item.servesText }}</div>
    <div v-show="showItems('bases')" class="bag-item-category-header">
      <br />
      Base
    </div>
    <div>{{ selectedSubItems(item, menuItem, 'bases') }}</div>
    <div v-show="showItems('proteins')" class="bag-item-category-header">
      <br />
      Proteins
    </div>
    <div>{{ selectedSubItems(item, menuItem, 'proteins', true) }}</div>
    <br />
    <div class="bag-item-btn-group">
      <gold-link class="bag-item-btn" @click="editClose">EDIT</gold-link>
      <gold-link class="bag-item-btn" @click="showRemove">REMOVE</gold-link>
    </div>
    <div style="font-weight: bold">
      <br />
      Included Fillings
    </div>
    <div>{{ includedExtraItems('fillings') }}</div>
    <div style="font-weight: bold">
      <br />
      Included Sides
    </div>
    <div>{{ includedExtraItems('sides') }}</div>
  </div>
</template>

<script>
import GoldLink from '@/components/common/GoldLink'
import { commonBuilderMixin } from '@/mixins/commonBuilder'
import { BURRITOS_BY_THE_BOX } from '@/router/routeNames'

export default {
  name: 'bag-item-burritos-by-the-box',
  props: ['item'],
  components: { GoldLink },
  mixins: [commonBuilderMixin],
  methods: {
    editClose() {
      this.$root.$emit('close-bag')
      this.editItemFromBag(BURRITOS_BY_THE_BOX)
    },
    showRemove() {
      this.$root.$emit('open-bag-delete', this.item.orderItemId)
    },
    showItems(category) {
      return (
        this.selectedSubItems(this.item, this.menuItem, category).length > 0
      )
    },
    includedExtraItems(category) {
      const descriptions = []
      if (
        this.menuItem &&
        this.menuItem.config &&
        this.menuItem.config.readonly &&
        this.menuItem.config.readonly[category]
      ) {
        this.menuItem.config.readonly[category].forEach((si) => {
          descriptions.push(si.name)
        })
      }
      return descriptions.join(', ')
    },
  },
  computed: {
    menuItem: {
      get() {
        return this.$store.getters.getMenuItem(this.item.menuId)
      },
    },
  },
}
</script>
