import * as types from '@/store/mutation-types/loaderMutations'

const state = {
  mainText: '',
  subText: '',
  display: false,
  queueData: [],
  displayData: [],
  includeOverlay: false,
}

const getters = {
  loaderMainText: (state) => state.mainText,
  loaderSubText: (state) => state.subText,
  loaderDisplay: (state) => state.display,
  loaderIncludeOverlay: (state) => state.includeOverlay,
}

const actions = {
  [types.QUEUE_LOADER]({ commit, dispatch, state }, options) {
    // This will queue a request to show the loader. After 400 milliseconds (below) the message will be shown
    // unless CLEAR_LOADER is called before that
    const timerId = window.setTimeout(function() {
      dispatch(types.SHOW_LOADER, copiedOptions)
    }, 600)
    const copiedOptions = { ...options, ...{ timerId } }

    commit(types.UPDATE_QUEUE_LOADER_TIMER, copiedOptions)
  },
  [types.SHOW_LOADER]({ commit, state }, options) {
    // This will immediately show the loader. It will show until SHOW_LOADER is called again, either directly or by the QUEUE_LOADER timeout
    options.displayed = true
    commit(types.DEQUEUE_DATA, options)
    commit(types.UNTRACK_DISPLAYED, options)
    commit(types.TRACK_DISPLAYED, options)
    commit(types.UPDATE_LOADER_MAIN_TEXT, options.mainText)
    commit(types.UPDATE_LOADER_SUB_TEXT, options.subText)
    commit(types.UPDATE_LOADER_INCLUDE_OVERLAY, options.includeOverlay)
    commit(types.UPDATE_LOADER_DISPLAY, true)
  },
  [types.CLEAR_LOADER]({ commit, dispatch, state }, options) {
    // This will clear the curretly shown loading message. It will then pull the top already down but not cleared loading message
    commit(types.DEQUEUE_DATA, options)
    commit(types.UNTRACK_DISPLAYED, options)
    commit(types.UPDATE_LOADER_DISPLAY, false)

    // get the top element that is being shown
    const optionToDisplay = state.displayData[0]
    if (optionToDisplay) {
      dispatch(types.SHOW_LOADER, optionToDisplay)
    }
  },
}

const mutations = {
  [types.UPDATE_QUEUE_LOADER_TIMER](state, options) {
    state.queueData.push(options)
  },
  [types.UPDATE_LOADER_DISPLAY](state, displayLoader) {
    state.display = displayLoader
  },
  [types.UPDATE_LOADER_MAIN_TEXT](state, text) {
    state.mainText = text
  },
  [types.UPDATE_LOADER_SUB_TEXT](state, text) {
    state.subText = text
  },
  [types.DEQUEUE_DATA](state, options) {
    for (let index = 0; index < state.queueData.length; index++) {
      if (state.queueData[index].name === options.name) {
        const removedElement = state.queueData.splice(index, 1)
        if (removedElement[0].timerId) {
          window.clearTimeout(removedElement[0].timerId)
        }
      }
    }
  },
  [types.TRACK_DISPLAYED](state, options) {
    state.displayData.push(options)
  },
  [types.UNTRACK_DISPLAYED](state, options) {
    for (let index = 0; index < state.displayData.length; index++) {
      if (state.displayData[index].name === options.name) {
        state.displayData.splice(index, 1)
      }
    }
  },
  [types.UPDATE_LOADER_INCLUDE_OVERLAY](state, value) {
    state.includeOverlay = value
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
