<template>
  <span
    :class="{
      'include-delete': showDelete,
      'include-delete-desktop': showDeleteDesktop,
      'include-delete-mobile': showDeleteMobile,
    }"
  >
    <b-form-group
      :label="getLabel"
      :label-for="id"
      label-text-align="left"
      label-class="form-input-group-label"
      :class="{ 'form-error': !this.isValid }"
    >
      <b-input-group>
        <b-form-input
          :id="id"
          :type="type"
          v-model="userValue"
          ref="inputBoxInput"
          :class="[{ error: !this.isValid }, this.inputClass]"
          :placeholder="getPlaceholder"
          :autocomplete="autoComplete"
          :pattern="pattern"
          :required="required"
          :plaintext="plainText"
          :formatter="checkLength"
          :maxlength="maxLength"
          v-mask="mask"
          @input.native="trackChange($event)"
          @blur.native="isDirty && validateAndCommit()"
        />
        <slot name="append-input-button" />
        <span class="inputbox-icon">
          <slot name="icon">
            <span class="delete-indicator" @click="clearValue">x</span>
          </slot>
        </span>
      </b-input-group>
    </b-form-group>
  </span>
</template>
<script>
import { mask } from '@/masks'

export default {
  directives: { mask },
  props: {
    id: [String, Number],
    value: [String, Number],
    type: String,
    labelOverride: {
      default: '',
      type: String,
    },
    inputClass: {
      default: '',
      type: String,
    },
    placeholder: String,
    autoComplete: {
      default: null,
      type: String,
    },
    pattern: {
      default: null,
      type: String,
    },
    required: Boolean,
    plainText: Boolean,
    maxLength: Number,
    minLength: Number,
    mask: [String, Object],
    includeDelete: [Boolean, Object],
    validityState: {
      type: Object,
      required: true,
    },
    rules: Array,
  },
  data() {
    return {
      userValue: '',
      isDirty: false,
      errorMessage: '',
      isEmitting: false,
    }
  },
  computed: {
    showDelete: {
      get() {
        return this.userValue !== '' && this.includeDelete === true
      },
    },
    showDeleteDesktop: {
      get() {
        return (
          this.userValue !== '' &&
          this.includeDelete &&
          this.includeDelete.desktop
        )
      },
    },
    showDeleteMobile: {
      get() {
        return (
          this.userValue !== '' &&
          this.includeDelete &&
          this.includeDelete.mobile
        )
      },
    },
    isValid: {
      get() {
        return !this.errorMessage.length
      },
    },
    getErrorMessage: {
      get() {
        if (!this.rules || !this.rules.length) {
          return ''
        }

        let errorMessage = ''

        for (
          let index = 0;
          index < this.rules.length && !errorMessage.length;
          index++
        ) {
          const rule = this.rules[index]
          errorMessage = rule(this.userValue)
        }

        return errorMessage && errorMessage.length ? errorMessage : ''
      },
    },
    isInvalidAndDirty: {
      get() {
        return this.isDirty && !this.isValid
      },
    },
    getLabel: {
      get() {
        if (
          this.userValue &&
          this.userValue.length &&
          this.errorMessage.length
        ) {
          return this.errorMessage
        }

        const label =
          this.labelOverride && this.labelOverride.length
            ? this.labelOverride
            : this.placeholder
        return label + (this.required ? '*' : '')
      },
    },
    getPlaceholder: {
      get() {
        if (this.isInvalidAndDirty) {
          return this.errorMessage
        }

        return this.placeholder
      },
    },
  },
  mounted() {
    this.validityState.addHandler(
      this.id,
      this.validateAndCommit,
      this.resetValidation,
    )

    this.userValue = this.value
    if (this.mask) {
      this.applyMask()
    }
  },
  beforeDestroy() {
    this.validityState.removeHandler(this.id)
  },
  methods: {
    applyMask() {
      // wrap in setTimeout to allow vue to flush the tasks queue
      // and apply the changes to the DOM element.
      this.$nextTick(() => {
        const event = document.createEvent('Event')
        event.initEvent('input', true, true)
        // this is utilized by the mask directive to allow for a
        // one-time override update to the value.  b-form-input
        // emits non-native events which prevents the attached
        // el.oninput event handler in the mask directive from
        // receiving the new value if set programmatically and
        // not from user input.  This specially-marked event tells
        // the directive to trust and apply the update.
        event.customEventType = 'inputManual'
        this.$refs.inputBoxInput.$el.dispatchEvent(event)
      })
    },
    checkLength(value, event) {
      if (!this.maxLength) {
        return value
      }

      return value && value.length > this.maxLength
        ? value.substring(0, this.maxLength)
        : value
    },
    clearValue() {
      this.$emit('input', '')
      this.userValue = ''
      this.$refs.inputBoxInput.focus()
    },
    trackChange(event) {
      // Ignore if triggered from mask or watch
      if (event.customEventType) {
        return
      }

      this.isDirty = true

      if (this.userValue && this.userValue.length > 0) {
        // clear any existing errors while user is typing
        this.errorMessage = ''
      }
    },
    validateAndCommit(shouldFocus) {
      this.isDirty = this.userValue !== this.value || this.isDirty
      this.errorMessage = this.getErrorMessage
      this.validityState.setPropIsValid({
        id: this.id,
        value: this.userValue,
        isValid: !this.errorMessage.length,
        isDirty: this.isDirty,
        errorMessage: this.errorMessage,
      })

      if (this.errorMessage.length && shouldFocus) {
        this.$refs.inputBoxInput.focus()
      }
      this.$emit('input', this.userValue)
    },
    resetValidation() {
      this.isDirty = false
      this.errorMessage = ''
    },
  },
  watch: {
    value(newValue) {
      const isDomUpdated = this.userValue === newValue

      this.userValue = newValue
      const errorMessage = this.getErrorMessage

      this.validityState.setPropIsValid({
        id: this.id,
        value: this.userValue,
        isValid: !errorMessage.length,
        isDirty: this.isDirty,
        errorMessage,
      })

      // if a mask is not defined or if the DOM has already updated, do not emit an event.
      if (!this.mask || isDomUpdated) {
        return
      }

      this.applyMask()
    },
  },
}
</script>

<style lang="scss">
.delete-indicator {
  display: none;
}
.include-delete,
.include-delete-mobile {
  position: relative;
  display: block;

  &.delete-indicator {
    display: inline-block;
    position: absolute;
    bottom: 7px;
    right: 10px;
    font-size: 13px;
  }
}

.inputbox-icon {
  z-index: 10;
}

@include media-breakpoint-up(lg) {
  .include-delete-mobile {
    & .delete-indicator {
      display: none;
    }
  }

  .include-delete,
  .include-delete-desktop {
    position: relative;

    & .delete-indicator {
      display: inline-block;
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 13px;
    }
  }
}
</style>
