export const birthMonthHyphenDateMask = {
  mask: 'M#-D#',
  tokens: {
    M: {
      pattern: /[0-1]/,
    },
    '#': {
      pattern: /\d/,
    },
    D: {
      pattern: /[0-3]/,
    },
  },
}
