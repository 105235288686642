export const BUILD_YOUR_OWN = 'BuildYourOwn'
export const BURRITOS_BY_THE_BOX = 'BurritosByTheBox'
export const CHANGE_PASSWORD = 'ChangePassword'
export const CHECKOUT = 'Checkout'
export const CHIPS_AND_SALSA = 'ChipsAndSalsa'
export const CONFIRMATION = 'Confirmation'
export const CREATE_ACCOUNT = 'CreateAccount'
export const HOME = 'Home'
export const INTAKE_DETAIL = 'IntakeDetail'
export const LOGIN = 'Login'
export const ORDER_HISTORY = 'OrderHistory'
export const PAGE_NOT_FOUND = 'PageNotFound'
export const PAYMENT_METHODS = 'PaymentMethods'
export const SAVED_ADDRESSES = 'SavedAddresses'
export const UPDATE_USER = 'UpdateUser'
export const BUILDERS = [BUILD_YOUR_OWN, BURRITOS_BY_THE_BOX, CHIPS_AND_SALSA]
