<template>
  <div class="usablenet-wrapper" :style="{ position: wrapperPosition }">
    <a
      href="#"
      class="UsableNetAssistive"
      onclick="return enableUsableNetAssistive()"
    >
      ENABLE ACCESSIBILITY
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['isScrolled']),
  },
  data() {
    return {
      wrapperPosition: 'static',
    }
  },
  watch: {
    isScrolled(newValue) {
      if (!newValue) {
        this.wrapperPosition = 'static'
      } else if (this.wrapperPosition !== 'absolute') {
        // Delay setting the position so that the transition animation can take effect
        setTimeout(() => {
          this.wrapperPosition = 'absolute'
        }, 100)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.usablenet-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 4px 2rem;
  background-color: $catering-bg-grey;
  transition: height 0.1s;
  height: $usablenet-accessibility-header-height;
}

.is-scrolled .usablenet-wrapper {
  height: 0;
}

@media (min-width: 576px) {
  .usablenet-wrapper {
    justify-content: end;
  }
}

.UsableNetAssistive {
  text-decoration: none;
  color: $catering-mobile-header;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.85px;
  text-transform: uppercase;
}

a.UsableNetAssistive:hover,
a.UsableNetAssistive:focus,
a.UsableNetAssistive:active {
  text-decoration: underline;
  color: #8e5d04; // Specific gold color required for at least 4.5:1 contrast ratio
}
</style>
