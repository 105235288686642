import { getNextTime } from '@/utilityFunctions'

export default function toStringTimeRange(value) {
  if (!value || !value.toLocaleDateString) {
    return value
  }

  const format = { hour: 'numeric', minute: 'numeric' }
  const baseDate = value.toLocaleTimeString('en-US', format)
  const addedTime = getNextTime(value, 15, 'en-US', {
    hour: 'numeric',
    minute: '2-digit',
  })

  return `${baseDate} - ${addedTime}`
}
