export const phoneMask = {
  // the mask enforces only digits 2-9
  // in the first digit position.
  mask: '(F##) ###-####',
  tokens: {
    F: {
      pattern: /[2-9]/,
    },
    '#': { pattern: /\d/ },
  },
}
