<template>
  <div class="rewards-menu container">
    <div class="flex-column justify-content-center menu-row row">
      <div class="align-items-center d-flex flex-row mb-2">
        <img
          src="@/static/img/medallion-reward-shout.svg"
          alt="Medallion"
          class="medallion"
        />
        <div class="d-flex flex-column ml-2">
          <div class="font-weight-bold points-header-prefix" role="definition">
            YOU'VE GOT
          </div>
          <div class="font-weight-bold points-header-value" role="definition">
            {{ currentPointsBalance }} POINTS
          </div>
        </div>
      </div>
      <b-button
        :href="links.rewards"
        target="_blank"
        type="button"
        variant="outline-primary"
        class="font-weight-bold"
      >
        MY REWARDS
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const { VUE_APP_ROO_URL, VUE_APP_ROO_REWARDS_PATH } = process.env

export default {
  name: 'RewardsMenu',
  computed: {
    ...mapGetters(['currentPointsBalance']),
    links() {
      return {
        rewards: `${VUE_APP_ROO_URL}${VUE_APP_ROO_REWARDS_PATH}`,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.rewards-menu {
  max-width: 100%;

  .menu-row {
    padding: 10px 15px;
    border-bottom: 1px solid $catering-counter-circle-unfilled-border;
  }

  .medallion {
    width: 72px;
    height: 72px;
  }

  .points-header-prefix {
    color: $catering-mobile-header;
    letter-spacing: 2px;
  }

  .points-header-value {
    color: $catering-mobile-header;
    font-family: $font-family-sans-serif;
    font-size: 2.5rem;
  }

  @include media-breakpoint-up(sm) {
    .points-header-prefix {
      font-size: 0.8rem;
    }

    .points-header-value {
      font-size: 2rem;
    }
  }
}
</style>
