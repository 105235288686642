<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="18"
    height="16"
    viewBox="0 0 18 16"
    class="seeing-eye"
    v-on:click="svgClick"
  >
    <title>Eye Open</title>
    <desc>Eye open image</desc>
    <defs>
      <path id="a" d="M0 13h17.894V.877H0z" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <path
        fill="#786259"
        d="M8.849 5.774A2.29 2.29 0 0 0 6.56 8.061a2.29 2.29 0 0 0 2.288 2.287 2.29 2.29 0 0 0 2.287-2.287 2.29 2.29 0 0 0-2.287-2.287m0 5.574A3.29 3.29 0 0 1 5.56 8.061a3.292 3.292 0 0 1 3.288-3.287 3.291 3.291 0 0 1 3.287 3.287 3.29 3.29 0 0 1-3.287 3.287"
      />
      <path
        fill="#786259"
        d="M8.849 5.774A2.29 2.29 0 0 0 6.56 8.061a2.29 2.29 0 0 0 2.288 2.287 2.29 2.29 0 0 0 2.287-2.287 2.29 2.29 0 0 0-2.287-2.287m0 5.574A3.29 3.29 0 0 1 5.56 8.061a3.292 3.292 0 0 1 3.288-3.287 3.291 3.291 0 0 1 3.287 3.287 3.29 3.29 0 0 1-3.287 3.287"
      />
      <g transform="translate(0 1.123)">
        <mask id="b" fill="#fff">
          <use xlink:href="#a" />
        </mask>
        <path
          fill="#786259"
          d="M1.063 6.939l.2.388A8.624 8.624 0 0 0 8.947 12a8.62 8.62 0 0 0 7.683-4.674l.201-.388-.2-.388a8.621 8.621 0 0 0-7.684-4.675 8.624 8.624 0 0 0-7.683 4.675l-.202.388zM8.947 13A9.623 9.623 0 0 1 .376 7.787l-.32-.62a.5.5 0 0 1 0-.457l.32-.619A9.622 9.622 0 0 1 8.947.877a9.618 9.618 0 0 1 8.572 5.215l.319.617a.493.493 0 0 1 0 .46l-.32.619A9.617 9.617 0 0 1 8.947 13z"
          mask="url(#b)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  methods: {
    svgClick(e) {
      this.$emit('eyeopensvg-click', e)
    },
  },
}
</script>
