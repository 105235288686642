<template>
  <div class="order-info-container">
    <div class="back-title">
      <h1 class="title">{{ title }}</h1>
    </div>
    <div class="clear-both" />
    <div class="order-info">
      <div
        class="order"
        tabindex="-1"
      >
        <div class="name-cost flex">
          <div
            v-if="showMenuItemName"
            class="label"
          >
            {{ menuItemName }}
          </div>
          <div
            v-if="showServes"
            class="label"
          >
            Serves {{ menuItemServes }}
          </div>
          <div class="label">
            <span>{{ menuItemPrice | toCurrency(isChips) }}</span>
            <span class="slash">/</span>
            <span class="per-person">{{ selectBoxGroupNameSingle }}</span>
          </div>
        </div>
        <div class="description">
          {{ menuItemDescription }}
        </div>
      </div>
      <div class="order-border-right desktop-only" />
      <div
        class="number-people"
        tabindex="-1"
      >
        <h2 class="label how-many">{{ headcountQuestion }}</h2>
        <div class="order-border-bottom flex">
          <div class="minimum">
            <div v-if="minimum > 1">Minimum of {{ minimum }}</div>
            <div
              aria-label="Need help"
              class="need-help"
              role="button"
              tabindex="0"
              @click="showHelp = true"
              @keydown.enter="showHelp = true"
            >
              NEED HELP?
            </div>
          </div>
          <div class="number-people-selector">
            <b-form-select
              v-if="inputType == builderInputCountTypes.Select"
              :aria-label="headcountQuestion"
              v-model="numberPeople"
              :options="numberPeopleOptions"
              size="lg"
            />
            <div
              class="input-group"
              v-else
            >
              <minus-svg
                :class="{ disabled: numberPeople <= menuItemConfig.min }"
                @minussvg-click="decrement"
              />
              <b-form-input
                :aria-label="headcountQuestion"
                type="number"
                id="number-people-input"
                ref="numberPeopleInput"
                min="1"
                step="1"
                :value="numberPeople.toString()"
                @change="validateNumberPeople"
                @keypress.native="onlyWholeNumber"
              />
              <plus-svg
                :class="{ disabled: numberPeople >= menuItemConfig.max }"
                @plussvg-click="increment"
              />
              <b-tooltip
                target="number-people-input"
                :show.sync="showPeopleError"
                :disabled="disablePeopleError"
                placement="bottom"
                @hidden="disablePeopleError = true"
                @shown="setHideTimer"
              >
                {{ peopleErrorText }}
              </b-tooltip>
            </div>
          </div>
        </div>
        <div class="subtotal-container flex">
          <div class="label subtotal">Order Total</div>
          <div class="label subtotal">{{ total | toCurrency(isChips) }}</div>
        </div>
      </div>
    </div>

    <b-modal
      class="need-help-modal form-inline"
      v-model="showHelp"
      centered
      hide-footer
      no-close-on-backdrop
    >
      <div
        slot="modal-title"
        class="modal-header-slot"
      >
        CATERING QUANTITIES
      </div>
      <div>
        <div class="help-body-header">
          {{ helpHeaderText }}
        </div>
        <div class="help-body">
          {{ helpBodyText }}
        </div>
      </div>
      <div slot="modal-footer" />
    </b-modal>
  </div>
</template>
<script>
import minusSvg from '../common/MinusSvg'
import plusSvg from '../common/PlusSvg'
import { GET_RESTAURANT } from '@/store/mutation-types/restaurantDataMutations'
import { BuilderInputCountTypes } from '@/constants'
import { onlyWholeNumber } from '../../utilityFunctions'

export default {
  props: [
    'menuType',
    'isDirty',
    'headcountQuestion',
    'inputType',
    'minimum',
    'orderItemId',
    'title',
    'showAssortment',
    'selectBoxGroupName',
    'selectBoxGroupNameSingle',
    'showMenuItemName',
    'showServes',
    'helpHeaderText',
    'helpBodyText',
  ],

  components: {
    minusSvg,
    plusSvg,
  },
  data() {
    return {
      showHelp: false,
      showPeopleError: false,
      disablePeopleError: true,
      peopleErrorText: 'Sorry. We have a 200 burrito limit.',
      isAutoAssortment: false,
      numberPeopleData: 1,
    }
  },
  computed: {
    isChips: {
      get() {
        return this.menuType === this.$store.getters.chipsAndSalsaId
      },
    },
    builderInputCountTypes: {
      get() {
        return BuilderInputCountTypes
      },
    },
    numberPeopleOptions: {
      get() {
        if (!this.menuItem) {
          return []
        } else {
          const options = []
          for (
            let index = this.menuItemConfig.min;
            index <= this.menuItemConfig.max;
            index += this.menuItemConfig.increment
          ) {
            let text
            if (index === 1) {
              text = index.toString() + ' ' + this.selectBoxGroupNameSingle
            } else {
              text = index.toString() + ' ' + this.selectBoxGroupName
            }
            options.push({ value: index, text })
          }
          return options
        }
      },
    },
    menuItem: {
      get() {
        if (!this.$store.getters.restaurantMenu) {
          return {}
        }

        const menuTypeId = this.menuType
        const possibleItem = this.$store.getters.restaurantMenu.filter(
          function (item) {
            return item.id === menuTypeId
          },
        )

        return possibleItem.length > 0
          ? possibleItem[0]
          : { config: {}, itemConfig: {} }
      },
    },
    menuItemServes: {
      get() {
        return this.menuItemConfig.serves
      },
    },
    menuItemName: {
      get() {
        if (this.menuItem) {
          return this.menuItem.name
        } else {
          return ''
        }
      },
    },
    menuItemPrice: {
      get() {
        if (this.menuItem) {
          return this.menuItem.price
            ? this.menuItem.price
            : this.menuItem.minPrice
        } else {
          return 0
        }
      },
    },
    menuItemDescription: {
      get() {
        if (this.menuItem && this.menuItem.shortDescription) {
          return this.menuItem.shortDescription
        } else if (this.menuItem) {
          return this.menuItem.description
        } else {
          return ''
        }
      },
    },
    total: {
      get() {
        const totalValue = this.numberPeople * this.menuItemPrice
        this.$emit('order-info-total-change', totalValue)
        return totalValue
      },
    },
    numberPeople: {
      get() {
        // TODO: Pull from the order info once we wire up adding of orders
        return this.numberPeopleData
      },
      set(value) {
        this.numberPeopleData = value
        this.$emit('order-info-number-people-change', value)
      },
    },
    menuItemConfig: {
      get() {
        if (this.menuItem && this.menuItem.itemConfig) {
          return this.menuItem.itemConfig
        }
        return null
      },
    },
  },
  mounted() {
    let orderItem = null

    if (
      this.orderItemId &&
      this.$store.getters.currentOrder &&
      this.$store.getters.currentOrder.orderDetail &&
      this.$store.getters.currentOrder.orderDetail.orderItems
    ) {
      // pull from order item
      const that = this
      const possibleItems =
        this.$store.getters.currentOrder.orderDetail.orderItems.filter(
          function (item) {
            return item.orderItemId === that.orderItemId
          },
        )

      if (possibleItems.length > 0) {
        orderItem = possibleItems[0]
        this.numberPeopleData = orderItem.quantity
      }
    }

    if (!orderItem) {
      // set defaults
      this.numberPeople = this.menuItemConfig.min
    }
  },
  methods: {
    setHideTimer() {
      // TODO: Need to revisit this
      const that = this
      setTimeout(function () {
        that.showPeopleError = false
      }, 2000)
    },
    toggleAutoAssortment(eventArgs) {
      this.isAutoAssortment = eventArgs
      this.$emit('order-info-toggle-auto-assortment', this.isAutoAssortment)
    },
    decrement(e) {
      this.showPeopleError = false
      let newNumber = Number(this.numberPeople)
      if (newNumber > this.menuItemConfig.min) {
        newNumber -= this.menuItemConfig.increment
      }
      if (newNumber < this.menuItemConfig.min) {
        newNumber = this.menuItemConfig.min
      }
      this.numberPeople = newNumber
    },
    increment(e) {
      let newNumber = Number(this.numberPeople)
      if (newNumber < this.menuItemConfig.max) {
        newNumber += this.menuItemConfig.increment
      }
      if (newNumber > this.menuItemConfig.max) {
        newNumber = this.menuItemConfig.max
      }
      this.numberPeople = newNumber
    },
    onlyWholeNumber(eventArgs) {
      onlyWholeNumber(eventArgs)
    },
    validateNumberPeople(eventArgs) {
      let inputNumber = Number(eventArgs)
      if (inputNumber < this.menuItemConfig.min) {
        inputNumber = this.menuItemConfig.min
        this.peopleErrorText =
          'Sorry. We have a ' + this.menuItemConfig.min + ' burrito minimum.'
        this.disablePeopleError = false
        this.showPeopleError = true
      } else if (inputNumber > this.menuItemConfig.max) {
        inputNumber = this.menuItemConfig.max
        this.peopleErrorText =
          'Sorry. We have a ' + this.menuItemConfig.max + ' burrito limit.'
        this.disablePeopleError = false
        this.showPeopleError = true
      }

      this.numberPeople = inputNumber
      if (
        this.$refs.numberPeopleInput.value !==
        this.$refs.numberPeopleInput.$el.value
      ) {
        this.$refs.numberPeopleInput.$el.value =
          this.$refs.numberPeopleInput.value
      }
    },
    navigateHome() {
      this.$store
        .dispatch(
          GET_RESTAURANT,
          this.$store.getters.currentOrder.orderDetail.store.storeNumber,
        )
        .then((restaurant) => {
          if (
            restaurant.addresses &&
            restaurant.addresses.length > 0 &&
            restaurant.addresses[0].postalCode
          ) {
            const postalCode = restaurant.addresses[0].postalCode.split('-')[0]
            this.$router.push({ name: 'Home', query: { zipCode: postalCode } })
          } else {
            this.$router.push({ name: 'Home' })
          }
        })
        .catch((e) => {
          this.$router.push({ name: 'Home' })
        })
    },
  },
  watch: {},
}
</script>
<style lang="scss">
.flex {
  display: flex;
  justify-content: space-between;
}
#content {
  .order-info-container {
    background-color: $catering-builder-gray;
    padding-top: 47px;
    padding-bottom: 19px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 20px;
    .back-title {
      height: 50px;

      .back {
        cursor: pointer;
        background-color: $catering-white;
        box-shadow: $catering-box-shadow;
        float: left;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        line-height: 40px;
        text-align: center;
        margin-left: 15px;
        margin-top: 7px;

        .arrow {
          transform: rotate(180deg);

          g {
            stroke: $catering-desktop-label;
          }
        }
      }

      .title {
        text-transform: uppercase;
        font-family: $font-family-sans-serif;
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 50px;
        height: 100%;
        letter-spacing: normal;
        text-align: center;
        color: $catering-chocolate;
        width: 100%;
        margin: 0 auto;
        padding-top: 2px;
      }
    }

    .modal-body {
      padding: 20px;
      font-family: $font-family-helvetica;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.38;
      letter-spacing: normal;
      font-size: 13px;

      .help-body-header {
        font-weight: bold;
        line-height: 1.46;
        color: $catering-builder-help-body-header;
        margin-bottom: 10px;
      }

      .help-body {
        letter-spacing: -0.1px;
        color: $catering-grey-brown;
        margin-bottom: 30px;

        a {
          color: $catering-builder-help-body-header;
          font-weight: bold;
        }
      }
    }
  }

  .order-info {
    max-width: $max-width-order-info;
    box-shadow: $catering-box-shadow;
    background-color: $catering-white;
    border-radius: 4px;
    margin-left: auto;
    margin-right: auto;
    padding: 12px 15px 11.1px 15px;
    font-family: $font-family-helvetica;

    .order-border-bottom {
      border-bottom: 1px solid $catering-counter-circle-unfilled-border;
      padding-bottom: 10px;
      margin-bottom: 11px;
    }

    .order-border-right {
      border-right: 1px solid $catering-counter-circle-unfilled-border;
    }

    .order {
      .name-cost {
        .slash {
          font-weight: 100;
          padding: 0 2px;
          color: $catering-greyish-red;
        }

        .per-person {
          text-transform: lowercase;
          font-size: 13px;
          font-weight: 100;
          color: $catering-greyish-red;
        }
      }

      .description {
        padding-top: 4px;
        color: $catering-light-grey-brown;
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.46;
        letter-spacing: -0.3px;

        border-bottom: 1px solid $catering-counter-circle-unfilled-border;
        padding-bottom: 10px;
        margin-bottom: 11px;
      }
    }

    .number-people {
      select {
        box-shadow: $catering-sm-box-shadow;
        color: $catering-desktop-label;
        font-size: 18px;
        width: 150px;
      }

      .how-many {
        font-family: inherit;
        margin-bottom: 9.3px;
      }

      .minimum {
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $catering-light-grey-brown;
      }

      .need-help {
        cursor: pointer;
        font-family: $font-family-sans-serif;
        font-size: 13px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $catering-yellow;
        margin-top: 2px;
      }

      .number-people-selector {
        padding-bottom: 7px;
        min-width: 115px;

        select {
          height: 40px;
        }

        .input-group {
          position: relative;
          justify-content: space-between;

          input {
            max-width: 30px;
            flex: 1 1 50%;
            height: 20px;
            font-size: 16px;
            border-top: 0px;
            border-left: 0px;
            border-right: 0px;
            border-bottom: 1px solid $catering-yellow;
            background-color: transparent;
            text-align: center;
            color: $catering-desktop-label;
            font-family: $font-family-sans-serif;
            font-weight: bold;
            line-height: 1;
            padding: 0;
            letter-spacing: 0.4px;
          }

          input[type='number']::-webkit-inner-spin-button,
          input[type='number']::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          .minus,
          .plus {
            flex: 0 1 auto;
            cursor: pointer;
            box-shadow: $catering-sm-box-shadow;
            border-radius: 50%;

            ellipse {
              stroke: $catering-medium-brown;
              stroke-width: 1px;
              ry: 12px;
              rx: 12px;
            }
            &.disabled {
              cursor: auto;

              line {
                opacity: 0.5;
              }
              ellipse {
                fill: $catering-light-grey;
                opacity: 0.5;
              }
            }
          }
        }
      }
    }

    .label {
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $catering-desktop-label;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: baseline;
      align-items: baseline;
    }
  }
}

$breakpoint: map-get($grid-breakpoints, lg);
@media screen and (min-width: $breakpoint),
  screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: portrait) {
  #content {
    .order-info-container {
      padding-bottom: 30px;

      .order-border-bottom {
        border-bottom-width: 1px;
      }

      .back-title {
        height: 80px;

        .title {
          font-size: 24px;
          width: 100%;
          line-height: 80px;
        }
      }

      .label {
        font-size: 18px;
      }

      .order-info {
        display: flex;
        margin-top: 0px;
        padding-left: 36px;
        padding-top: 0px;
        padding-bottom: 0px;

        .order {
          padding-top: 21px;
          padding-right: 33px;
          width: 296px;

          .description {
            border-bottom-width: 0px;
            font-size: 15px;
            letter-spacing: normal;
            margin-bottom: 6px;
          }
        }

        .number-people {
          padding-top: 21px;
          padding-bottom: 17px;
          margin-left: 33px;
          width: 280px;

          & > div:first-child {
            width: 100%;
          }
          & > div {
            width: 260px;
          }

          .subtotal-container {
            margin-top: 16px;
          }

          .subtotal {
            font-size: 16px;
          }
        }
      }

      .modal-dialog {
        max-width: 580px;
      }

      .modal-body {
        .help-body-header,
        .help-body {
          padding-left: 98px;
          padding-right: 98px;
        }
      }
    }
  }
}

@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
}
</style>
