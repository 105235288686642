<template>
  <div class="delivery-limit-warning">
    <div>
      <p class="heading">DELIVERY LIMIT IS ${{ deliveryLimit }}</p>
    </div>
    <div>
      <p class="text">
        If you would like to order more, edit the order in your bag or
        <span v-if="withoutLink">switch to pickup</span>
        <router-link
          v-else
          class="link"
          :to="intakePageRoute"
        >
          switch to pickup
        </router-link>
        .
      </p>
    </div>
  </div>
</template>

<script>
import { INTAKE_DETAIL } from '@/router/routeNames'
import { PICKUP } from '@/constants/orderTypes'

export default {
  name: 'DeliveryLimitWarning',
  data() {
    return {
      deliveryLimit: parseInt(process.env.VUE_APP_DELIVERY_LIMIT),
    }
  },
  computed: {
    withoutLink() {
      return this.$store.state.route.name === INTAKE_DETAIL
    },
    intakePageRoute() {
      /**
       * TODO: This doesn't handle the case where you are editing an order item, go to intake,
       * then expect to be navigated back. Refactor action out of qs and use prevPath pattern
       * instead (which should be renamed returnPath)
       */
      const {
        name: action,
        params: { menuType: menuItemId },
      } = this.$store.state.route
      return {
        name: INTAKE_DETAIL,
        query: {
          orderType: PICKUP,
          ...(!menuItemId ? { action } : {}),
          ...(menuItemId ? { menuItemId } : {}),
        },
      }
    },
  },
}
</script>

<style lang="scss">
.delivery-limit-warning {
  font-size: 0.9em;

  .heading,
  .text {
    margin: 0.1em 0;
    width: 100%;
    text-align: center;
  }

  .heading {
    font-weight: bold;
  }

  .text {
    font-weight: normal;
  }

  .link {
    text-decoration: underline;
    color: $catering-red;

    &:hover {
      color: $catering-red;
    }
  }
}
</style>
