const usZipCodeRegEx = /^\d{5}$/
const canadaPostalCodeRegEx = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/

export default (country, errorMessage) => (value) => {
  return (
    (country === 'US' && usZipCodeRegEx.test(value)) ||
    (country === 'CA' && canadaPostalCodeRegEx.test(value)) ||
    !value ||
    errorMessage
  )
}
