import toCurrency from './toCurrency'
import toMasked from './toMasked'
import toStringDate from './toStringDate'
import toStringDayOfWeekDateTimeRange from './toStringDayOfWeekDateTimeRange'
import toStringDateTimeRange from './toStringDateTimeRange'
import toStringTime from './toStringTime'
import toStringTimeRange from './toStringTimeRange'
import toStringDayOfWeekDateTime from './toStringDayOfWeekDateTime'
import toStringTimeCapacity from './toStringTimeCapacity'

export default {
  install(Vue) {
    Vue.filter('toCurrency', toCurrency)
    Vue.filter('toMasked', toMasked)
    Vue.filter('toStringDate', toStringDate) // March 15, 2019
    Vue.filter('toStringDayOfWeekDateTimeRange', toStringDayOfWeekDateTimeRange) // FRIDAY MARCH 15, 3:15 PM - 3:30 PM
    Vue.filter('toStringDateTimeRange', toStringDateTimeRange) // MARCH 15, 3:15 PM - 3:30 PM
    Vue.filter('toStringDayOfWeekDateTime', toStringDayOfWeekDateTime) // FRIDAY MARCH 15, 3:15 PM
    Vue.filter('toStringTime', toStringTime) // 3:15 PM
    Vue.filter('toStringTimeRange', toStringTimeRange) // 3:15 PM - 3:30 PM
    Vue.filter('toStringTimeCapacity', toStringTimeCapacity)
  },
}
