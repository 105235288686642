<template>
  <div id="accountConfirmPhone" class="account-confirm-phone-container">
    <img
      src="@/static/img/header/cmg-medallion-logo.svg"
      alt="Medallion"
      class="medallion"
    />
    <h2>TWO-STEP VERIFICATION</h2>
    <p id="label-confirm-phone" class="text-center mx-5">
      To verify your identity, we'll text a code via SMS to your mobile number.
    </p>
    <b-container>
      <b-row
        class="my-3"
        tabindex="0"
        @keydown.enter.native="selectOption(0)"
        @click="selectOption(0)"
      >
        <b-col cols="1" class="d-flex align-items-center">
          <checkmark-toggle-svg
            :is-checked="isUsingExistingPhoneNumber"
            :aria-label="'Text code to phone ending in ' + phoneLast4Digit"
          />
        </b-col>
        <b-col>Text code to ***-***-{{ phoneLast4Digit }}</b-col>
      </b-row>
      <b-row
        class="my-3"
        tabindex="0"
        @keydown.enter.native="selectOption(1)"
        @click="selectOption(1)"
      >
        <b-col cols="1" class="d-flex align-items-center">
          <checkmark-toggle-svg
            :is-checked="isUsingNewPhoneNumber"
            aria-label="Update my mobile number"
          />
        </b-col>
        <b-col>Update my mobile number</b-col>
      </b-row>
    </b-container>
    <template v-if="isUsingNewPhoneNumber">
      <input-box
        v-model="overridePhoneNumber"
        type="text"
        class="w-100"
        placeholder="Mobile Number"
        auto-complete="on"
        :mask="phoneMask"
        :validityState="validityState"
        :rules="[
          rules.required('Mobile Number is required'),
          rules.phoneNumber('Mobile Number is invalid'),
        ]"
      />
      <span class="account-confirm-phone-helper-text">
        Enter a valid mobile number that can accept SMS messages.
      </span>
    </template>
    <p class="account-confirm-phone-rates-disclaimer">
      Message and data rates may apply
    </p>
    <b-button variant="primary" block @click="sendVerificationCode">
      SEND CODE
    </b-button>
    <div class="account-confirm-customer-support-info">
      <b-form-checkbox class="mt-4" v-model="showCustomerSupportInfo">
        I don't have a mobile number or I'm unable to receive the verification
        code.
      </b-form-checkbox>
      <p class="mt-3" v-show="showCustomerSupportInfo">
        Please contact
        <u>
          <b>
            <a href="tel:+1-800-244-7685">1-800-CHIPOTLE.</a>
          </b>
        </u>
      </p>
    </div>
  </div>
</template>

<script>
import CheckmarkToggleSvg from '@/components/common/CheckmarkToggleSvg'
import InputBox from '@/components/common/Inputbox'
import { phoneNumber, required } from '@/validators/index'
import { phoneMask } from '@/masks'

export default {
  components: {
    CheckmarkToggleSvg,
    InputBox,
  },
  props: {
    validityState: {
      type: Object,
      required: true,
      default: () => {},
    },
    phoneNumber: {
      type: String,
      required: true,
      default: '',
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      selectedOptionIdx: 0,
      showCustomerSupportInfo: false,
      rules: {
        phoneNumber,
        required,
      },
      overridePhoneNumber: '',
    }
  },
  computed: {
    phoneLast4Digit() {
      return this.phoneNumber ? this.phoneNumber.slice(-4) : ''
    },
    isUsingExistingPhoneNumber() {
      return this.selectedOptionIdx === 0
    },
    isUsingNewPhoneNumber() {
      return this.selectedOptionIdx === 1
    },
    phoneMask() {
      return phoneMask
    },
  },
  methods: {
    selectOption(val) {
      this.selectedOptionIdx = val
    },
    sendVerificationCode() {
      if (this.validityState.isValid()) {
        this.$emit(
          'phoneConfirmation:send-verification-code',
          this.overridePhoneNumber,
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.account-confirm-phone-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 370px;
  padding: 1.5rem;

  h2 {
    font-size: 36px;
    text-transform: uppercase;
    text-align: center;
    line-height: 0.9;
    padding: 24px 0;
    color: $catering-mobile-header;
  }
}

.account-confirm-phone-rates-disclaimer {
  font-size: 12px;
  color: $catering-light-grey-brown;
  padding: 1rem 0;
}

.account-confirm-phone-helper-text {
  font-size: 12px;
  position: relative;
  top: -0.5rem;
  width: 100%;
}

.account-confirm-customer-support-info {
  a {
    color: $catering-chocolate;
  }
}
</style>
