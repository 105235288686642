<template>
  <div class="or-separator">
    <div class="border-line" />
    <div class="or-or">OR</div>
    <div class="border-line" />
  </div>
</template>

<script>
export default {
  name: 'or-separator',
}
</script>

<style lang="scss">
.or-separator {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .border-line {
    background-color: $catering-light-grey;
    height: 1px;
    width: 50%;
  }
  .or-or {
    padding-left: 7px;
    padding-right: 7px;
    color: $catering-chocolate;
    font-family: $font-family-sans-serif;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: -0.2px;
  }
}
</style>
