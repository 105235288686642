import * as types from '@/store/mutation-types/confirmDialogMutations'

const state = {
  confirm: {
    show: false,
    text: '',
    emphasisText: '',
    subText: '',
    headerText: '',
    okText: '',
    cancelText: '',
    okCallback: null,
    cancelCallback: null,
    hideCancel: false,
    returnFocus: '',
  },
}

const getters = {
  confirm: (state) => {
    return state.confirm
  },
}

const actions = {
  [types.SHOW_CONFIRM_DIALOG](
    { commit, state },
    {
      text,
      emphasisText,
      subText,
      headerText,
      okText,
      cancelText,
      okCallback,
      cancelCallback,
      hideCancel,
      returnFocus,
    },
  ) {
    if (state.confirm.show) {
      return
    }

    if (!okText) {
      okText = 'OK'
    }

    if (!cancelText) {
      cancelText = 'CANCEL'
    }

    commit(types.UPDATE_CONFIRM_DIALOG, {
      show: true,
      text,
      emphasisText,
      subText,
      headerText,
      okText,
      cancelText,
      okCallback,
      cancelCallback,
      hideCancel,
      returnFocus,
    })
  },
  [types.OK_CONFIRM_DIALOG]({ commit, state }) {
    commit(types.UPDATE_CONFIRM_DIALOG, {
      show: false,
      isOk: true,
      returnFocus: state.confirm.returnFocus,
    })
  },
  [types.CANCEL_CONFIRM_DIALOG]({ commit, state }) {
    commit(types.UPDATE_CONFIRM_DIALOG, {
      show: false,
      isOk: false,
      returnFocus: state.confirm.returnFocus,
    })
  },
}

const mutations = {
  [types.UPDATE_CONFIRM_DIALOG](state, newInfo) {
    state.confirm.text = newInfo.text
    state.confirm.emphasisText = newInfo.emphasisText
    state.confirm.subText = newInfo.subText
    state.confirm.headerText = newInfo.headerText
    state.confirm.okText = newInfo.okText
    state.confirm.cancelText = newInfo.cancelText
    state.confirm.show = newInfo.show
    state.confirm.hideCancel = newInfo.hideCancel
    state.confirm.returnFocus = newInfo.returnFocus

    if (newInfo.show) {
      state.confirm.okCallback = newInfo.okCallback
      state.confirm.cancelCallback = newInfo.cancelCallback
    } else {
      if (state.confirm.okCallback && newInfo.isOk) {
        state.confirm.okCallback()
      } else if (state.confirm.cancelCallback && !newInfo.isOk) {
        state.confirm.cancelCallback()
      }

      state.confirm.okCallback = null
      state.confirm.cancelCallback = null
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
