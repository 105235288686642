// actions
export const GET_POINTS = 'GET_POINTS'
export const GET_CHALLENGES = 'GET_CHALLENGES'
export const GET_PROFILE = 'GET_PROFILE'
export const GET_PROMOTIONS = 'GET_PROMOTIONS'
export const GET_ALL_REWARDS_DATA = 'GET_ALL_REWARDS_DATA'
export const RESET_ALL_REWARDS_DATA = 'RESET_ALL_REWARDS_DATA'

// mutations
export const GET_POINTS_PENDING = 'GET_POINTS_PENDING'
export const GET_POINTS_SUCCESS = 'GET_POINTS_SUCCESS'
export const GET_POINTS_FAILURE = 'GET_POINTS_FAILURE'
export const GET_CHALLENGES_PENDING = 'GET_CHALLENGES_PENDING'
export const GET_CHALLENGES_SUCCESS = 'GET_CHALLENGES_SUCCESS'
export const GET_CHALLENGES_FAILURE = 'GET_CHALLENGES_FAILURE'
export const GET_PROFILE_PENDING = 'GET_PROFILE_PENDING'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE'
export const GET_PROMOTIONS_PENDING = 'GET_PROMOTIONS_PENDING'
export const GET_PROMOTIONS_SUCCESS = 'GET_PROMOTIONS_SUCCESS'
export const GET_PROMOTIONS_FAILURE = 'GET_PROMOTIONS_FAILURE'
export const CLEAR_POINTS = 'CLEAR_POINTS'
export const CLEAR_CHALLENGES = 'CLEAR_CHALLENGES'
export const CLEAR_PROFILE = 'CLEAR_PROFILE'
export const CLEAR_PROMOTIONS = 'CLEAR_PROMOTIONS'
