<template>
  <div>
    <custom-modal v-model="showModal" title="HEADS UP">
      <div class="pt-3 px-3">
        <div v-for="(epop, idx) in epops" :key="idx" class="epop-post">
          <hr v-if="idx !== 0" />
          <h5>{{ epop.Title }}</h5>
          <p>{{ epop.Message }}</p>
        </div>
      </div>
      <div slot="modal-footer" class="w-100">
        <b-button class="w-100" variant="primary" @click="showModal = false">
          OKAY
        </b-button>
      </div>
    </custom-modal>
  </div>
</template>

<script>
import CustomModal from '@/components/common/CustomModal'
import { SHOW_EPOPS_MODAL } from '@/store/mutation-types/epopsMutations'

export default {
  components: {
    CustomModal,
  },
  computed: {
    epops() {
      return this.$store.getters.epops
    },
    showModal: {
      get() {
        return this.$store.getters.showEpopsModal
      },
      set(val) {
        this.$store.dispatch(SHOW_EPOPS_MODAL, val)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.epop-post {
  max-width: 60vw;
  width: 500px;
}
</style>
