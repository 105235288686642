<template>
  <div class="bag-promotion-container">
    <b-row class="bag-footer-item flex-grow-1 flex-shrink-1">
      <b-col>
        <div v-show="pendingOrderPromotionChange" class="promotion-spinner" />
        <div v-show="showPromoCodeApplied" class="pill-container">
          <span
            role="button"
            :aria-label="ariaPromoCodeApplied"
            @click="remove"
            @keydown.enter="remove"
            class="pill clickable"
          >
            <b-row no-gutters>
              <b-col cols="9">{{ currentOrderPromotionName }}</b-col>
              <b-col class="text-right">X</b-col>
            </b-row>
          </span>
          <div class="deduction-container pull-right">
            <div
              :aria-label="ariaDeductedAmount"
              class="deduction bag-footer-big-font text-right"
              v-show="currentOrderHasValidPromotionCode"
            >
              {{ (currentOrderPromotionDeduction * -1) | toCurrency }}
            </div>
            <div
              :aria-label="displayErrorMessage"
              class="error bag-footer-big-font text-right"
              v-show="showPromoCodeError"
            >
              {{ displayErrorMessage }}
            </div>
          </div>
        </div>
        <div v-show="showPromoCodeEntry" class="input-container">
          <b-row class="bag-footer-item flex-grow-1 flex-shrink-1">
            <b-col>
              <div :aria-label="displayErrorMessage" class="error">
                <span v-show="showPromoCodeError">
                  {{ displayErrorMessage }}
                </span>
              </div>
            </b-col>
          </b-row>
          <b-row class="bag-footer-item flex-grow-1 flex-shrink-1">
            <b-col>
              <b-form class="input-form" @submit.prevent="apply">
                <inputbox
                  :id="'promoCode'"
                  :mask="promotionMask"
                  :max-length="20"
                  :validityState="validityState"
                  class="input"
                  labelOverride=" "
                  placeholder="Enter a Promo Code"
                  type="text"
                  v-model="promoCode"
                >
                  <b-input-group-append slot="append-input-button">
                    <b-button
                      type="submit"
                      :disabled="disablePromoCodeSubmit"
                      class="input-apply-btn"
                    >
                      Apply
                    </b-button>
                  </b-input-group-append>
                </inputbox>
              </b-form>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { isValidationError, getErrorMessageByCode } from '@/utilityFunctions'
import { mapGetters } from 'vuex'

import { promotionMask } from '@/masks'
import { SHOW_CONFIRM_DIALOG } from '@/store/mutation-types/confirmDialogMutations'
import { UnexpectedError } from '@/constants'
import { validityStateInitialize } from '@/validators'
import inputbox from '@/components/common/Inputbox'
import toCurrency from '@/filters/toCurrency'
import {
  APPLY_ORDER_PROMOTION,
  REMOVE_ORDER_PROMOTION,
  VALIDATE_ORDER_PROMOTIONS,
} from '@/store/mutation-types/orderPromotionMutations'
import analyticsService from '@/services/analyticsService'
import {
  ERROR_PROMO_CODE_MISSING_ITEMS,
  ERROR_PROMO_CODE_EXPIRED,
} from '@/constants/errorCodes'

const errorMessageByErrorCode = {
  [ERROR_PROMO_CODE_MISSING_ITEMS]: 'Item is missing',
  [ERROR_PROMO_CODE_EXPIRED]: 'Promotion has expired',
}

export default {
  data() {
    return {
      promotionMask,
      promoCode: '',
      errorMessage: '',
      validityState: validityStateInitialize(),
      attemptedToApplyPromo: false,
    }
  },
  name: 'bag-promotion',
  components: {
    inputbox,
  },
  computed: {
    ...mapGetters([
      'bagIsOpened',
      'currentOrder',
      'currentOrderHasPromotionCode',
      'currentOrderHasValidPromotionCode',
      'currentOrderPromotionCode',
      'currentOrderPromotionName',
      'currentOrderPromotionCodeIsInvalid',
      'currentOrderPromotionDeduction',
      'currentOrderPromotionException',
      'pendingOrderPromotionChange',
    ]),
    // #REGION 'Aria'
    ariaDeductedAmount() {
      return `Promo code deducted ${toCurrency(
        this.currentOrderPromotionDeduction,
      )}`
    },
    ariaPromoCodeApplied() {
      return this.currentOrderHasPromotionCode
        ? `Promo Code ${this.currentOrderPromotionCode} applied`
        : 'Enter a Promo Code'
    },
    disablePromoCodeEntry() {
      return this.pendingOrderPromotionChange || !this.showPromoCodeEntry
    },
    disablePromoCodeSubmit() {
      return (
        this.pendingOrderPromotionChange || this.currentOrderHasPromotionCode
      )
    },
    orderId() {
      if (this.currentOrder) {
        return this.currentOrder.id || ''
      }
      return ''
    },
    showPromoCodeError() {
      return (
        (this.attemptedToApplyPromo || this.showPromoCodeApplied) &&
        !!this.displayErrorMessage
      )
    },
    showPromoCodeApplied() {
      return (
        !this.pendingOrderPromotionChange && this.currentOrderHasPromotionCode
      )
    },
    showPromoCodeEntry() {
      return (
        !this.pendingOrderPromotionChange && !this.currentOrderHasPromotionCode
      )
    },
    displayErrorMessage() {
      if (this.errorMessage) {
        return this.errorMessage
      }
      return getErrorMessageByCode(
        errorMessageByErrorCode,
        'Promo code is invalid',
        this.currentOrderPromotionException,
      )
    },
  },
  methods: {
    apply() {
      this.attemptedToApplyPromo = true
      this.errorMessage = ''
      analyticsService.applyPromotionClick()
      if (!this.pendingOrderPromotionChange) {
        const isValid = this.validityState.isValid()
        const promo = { orderId: this.orderId, promoCode: this.promoCode }

        if (!this.promoCode) {
          this.errorMessage = 'Please enter a promo code'
          return
        }

        if (!isValid) {
          return
        }

        this.$store
          .dispatch(APPLY_ORDER_PROMOTION, promo)
          .then(() => {
            this.promoCode = ''
            analyticsService.applyPromotionSuccess()
          })
          .catch((err) => {
            if (!isValidationError(err)) {
              this.$store.dispatch(SHOW_CONFIRM_DIALOG, {
                ...UnexpectedError,
                headerText: 'Promotions',
              })
            }
          })
      }
    },
    remove() {
      if (!this.pendingOrderPromotionChange) {
        const promo = {
          orderId: this.orderId,
          promoCode: this.currentOrderPromotionCode,
        }
        this.$store
          .dispatch(REMOVE_ORDER_PROMOTION, promo)
          .then(() => {
            this.promoCode = ''
            this.attemptedToApplyPromo = false
            this.errorMessage = ''
          })
          .catch((err) => {
            if (!isValidationError(err)) {
              this.$store.dispatch(SHOW_CONFIRM_DIALOG, {
                ...UnexpectedError,
                headerText: 'Promotions',
              })
            }
          })
      }
    },
  },
  // #ENDREGION 'Aria'
  watch: {
    bagIsOpened(newValue) {
      if (newValue) {
        if (this.currentOrderHasPromotionCode) {
          this.$store
            .dispatch(VALIDATE_ORDER_PROMOTIONS, this.orderId)
            .catch((err) => {
              if (!isValidationError(err)) {
                this.$store.dispatch(SHOW_CONFIRM_DIALOG, {
                  ...UnexpectedError,
                  headerText: 'Promotions',
                })
              }
            })
        }
      } else {
        this.promoCode = ''
        this.errorMessage = ''
        this.attemptedToApplyPromo = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$bag-promotion-container-height: 74px;
$bag-promotion-content-height: 72px;
$bag-promotion-error-height: 24px;
$bag-promotion-pill-height: 28px;

.bag-promotion-container {
  background-color: $catering-white;
  bottom: 0;
  height: $bag-promotion-container-height;
  text-rendering: optimizeLegibility;
  width: 100%;

  .promotion-spinner {
    background-image: url('@/static/img/pepper-spinner.gif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: $bag-promotion-container-height;
    margin: 0 auto;
    width: $bag-promotion-container-height;
    z-index: 99;
  }

  .input-container {
    height: $bag-promotion-content-height;

    .error {
      height: $bag-promotion-error-height;
    }

    .input-form {
      // * Dragons ahead *
      // this uses the adjacent sibling selector with
      // a combined class selector to find the inner button to keep the.
      // focus styling the same as the inputbox input control. Lastly,
      // the $catering-mobile-header had to be represented in rgba form
      // to get around the opacity issue when disabled is used ie 1 vs 0.65
      #promoCode.form-control:focus {
        & ~ .input-group-append {
          button {
            opacity: 1;
            color: rgba(69, 20, 0, 1);
            border-bottom: 1px solid $catering-chocolate !important;
          }

          button:disabled {
            opacity: 1;
            color: rgba(69, 20, 0, 0.65);
            border-bottom: 1px solid $catering-chocolate !important;
          }
        }
      }

      .input-apply-btn {
        background-color: $catering-white;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 1px solid $catering-silver;
        color: $catering-mobile-header;
        font-size: 1rem;
        font-stretch: normal;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 2.25px;
        margin: 0.225rem 0 0 0;
        text-rendering: optimizeLegibility;
      }
    }
  }

  .pill-container {
    padding-top: 28px;
    display: flex;
    align-items: center;

    .pill {
      background-color: $catering-counter-circle-unfilled;
      border: 1px solid $catering-chocolate;
      border-radius: 22px;
      box-shadow: $catering-box-shadow;
      display: inline-block;
      height: $bag-promotion-pill-height;
      width: 168px;
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      span {
        font-family: $font-family-sans-serif;
      }
    }

    .deduction-container {
      width: calc(100% - 168px);

      .deduction {
        height: calc($bag-promotion-content-height / 2);
      }
    }
  }
}
</style>
