import {
  OPEN_BAG,
  CLOSE_BAG,
  TOGGLE_BAG,
  OPEN_LOGIN_DRAWER,
  CLOSE_LOGIN_DRAWER,
  TOGGLE_LOGIN_DRAWER,
  CLOSE_OPEN_DRAWER,
} from '@/store/mutation-types/drawersMutations'
import { CHECKOUT, LOGIN, CONFIRMATION } from '@/router/routeNames'

const drawers = {
  bag: 'bag',
  login: 'login',
}

const state = {
  openDrawer: null,
}

const getters = {
  bagIsOpened: (state) => state.openDrawer === drawers.bag,
  loginDrawerIsOpened: (state) => state.openDrawer === drawers.login,
  canOpenDrawers: (state, getters, rootState, rootGetters) => {
    const { name } = rootState.route
    return (
      ![CHECKOUT, LOGIN].some((route) => route === name) &&
      (name !== CONFIRMATION || rootGetters.isFullUser)
    )
  },
}

const actions = {
  [OPEN_LOGIN_DRAWER]({ commit, getters }) {
    if (getters.canOpenDrawers) {
      commit(OPEN_LOGIN_DRAWER)
    }
  },
  [CLOSE_LOGIN_DRAWER]({ commit, getters }) {
    if (getters.loginDrawerIsOpened) {
      commit(CLOSE_LOGIN_DRAWER)
    }
  },
  [TOGGLE_LOGIN_DRAWER]({ dispatch, getters }) {
    if (getters.loginDrawerIsOpened) {
      dispatch(CLOSE_LOGIN_DRAWER)
      return
    }
    dispatch(OPEN_LOGIN_DRAWER)
  },
  [OPEN_BAG]({ commit, getters }) {
    if (getters.canOpenDrawers) {
      commit(OPEN_BAG)
    }
  },
  [CLOSE_BAG]({ commit, getters }) {
    if (getters.bagIsOpened) {
      commit(CLOSE_BAG)
    }
  },
  [TOGGLE_BAG]({ dispatch, getters }) {
    if (getters.bagIsOpened) {
      dispatch(CLOSE_BAG)
      return
    }
    dispatch(OPEN_BAG)
  },
  [CLOSE_OPEN_DRAWER]({ dispatch }) {
    dispatch(CLOSE_BAG)
    dispatch(CLOSE_LOGIN_DRAWER)
  },
}

const mutations = {
  [OPEN_LOGIN_DRAWER](state) {
    state.openDrawer = drawers.login
  },
  [CLOSE_LOGIN_DRAWER](state) {
    state.openDrawer = null
  },
  [OPEN_BAG](state) {
    state.openDrawer = drawers.bag
  },
  [CLOSE_BAG](state) {
    state.openDrawer = null
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
