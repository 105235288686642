import { days } from '@/constants/date'
import { ERROR_VALIDATION } from '@/constants/errorCodes'
import toCurrency from '@/filters/toCurrency'

const defaultFormatters = {
  C: toCurrency,
  qtyGt1: (qty) => (qty > 1 ? `${qty}X ` : ''),
}

export function onlyWholeNumber(eventArgs) {
  eventArgs.cancelBubble = true
  if (eventArgs.charCode < 48 || eventArgs.charCode > 57) {
    eventArgs.preventDefault()
    return false
  }
}

export function getLocationFullPath() {
  return window.location.pathname + window.location.search
}

// makes testing easier, none of these solutions worked: https://github.com/jsdom/jsdom/issues/2112
export function reload() {
  window.location.reload()
}

export function assignCardClass(expiration, className) {
  const month = parseInt(expiration.substr(0, 2))
  const year = parseInt(expiration.substr(-2))

  const cardDate = new Date(2000 + year, month, 0)
  const current = new Date()

  return current >= cardDate ? className : ''
}

export function getDateWithDayOfWeek(date) {
  const day = days[date.getDay()]
  const format = {
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }

  const formattedDate = date.toLocaleDateString('en-US', format)

  return `${day} ${formattedDate}`
}

export function displayFriendlyDate(iso8601String, timeZoneId) {
  const currentTimestamp = new Date(Date.now()).toISOString()
  const tempDate = getDateInTimeZone(iso8601String, timeZoneId)
  tempDate.setHours(0, 0, 0, 0)
  const time = tempDate.getTime()
  const [timeYesterday, timeToday, timeTomorrow, time7DaysFromNow] = [
    -1, 0, 1, 7,
  ].map((n) => {
    const d = getDateInTimeZone(currentTimestamp, timeZoneId)
    d.setDate(d.getDate() + n)
    d.setHours(0, 0, 0, 0)
    return d.getTime()
  })
  if (time === timeYesterday) {
    return 'Yesterday'
  }
  if (time === timeToday) {
    return 'Today'
  }
  if (time === timeTomorrow) {
    return 'Tomorrow'
  }
  const date = new Date(iso8601String)
  if (time > timeTomorrow && time < time7DaysFromNow) {
    const intlOptions = { timeZone: timeZoneId, weekday: 'long' }
    return new Intl.DateTimeFormat('en-US', intlOptions).format(date)
  }
  const intlOptions = {
    timeZone: timeZoneId,
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  }
  return new Intl.DateTimeFormat('en-US', intlOptions).format(date)
}

/**
 * TODO: this is a bit of a hack, and could be removed but would require significant refactoring.
 * JavaScript date objects are annoying to work with since they assume user's local zone, and behavior differs
 * between browsers.  It would be nicer to pass around ISOStrings and only convert into Date when necessary
 */
export function getDateInTimeZone(iso8601String, timeZoneId) {
  const parts = new Intl.DateTimeFormat('en-US', {
    timeZone: timeZoneId,
    hourCycle: 'h23',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).formatToParts(new Date(iso8601String))
  const f = (type) => parts.find((p) => p.type === type).value
  return new Date(
    `${f('year')}-${f('month')}-${f('day')}T${f('hour')}:${f('minute')}:${f(
      'second',
    )}`,
  )
}

export function getNextTime(value, addedMinutes, locale, format) {
  const addedDate = new Date(
    value.getFullYear(),
    value.getMonth(),
    value.getDate(),
    value.getHours(),
    value.getMinutes() + addedMinutes,
  )

  const addedTime = addedDate.toLocaleTimeString(locale, format)

  return addedTime
}

export function getFirstDayOfMonth(date) {
  if (!date || !(date instanceof Date)) {
    return null
  }

  return new Date(date.getFullYear(), date.getMonth(), 1)
}

export function isValidNumber(value) {
  if (typeof value === 'number') {
    return true
  }
  if (typeof value !== 'string') {
    return false
  }

  return !!value.trim() && !isNaN(value)
}

export function identity(x) {
  return x
}

export function get(path, obj, defaultValue) {
  const props = path.split(/\./)
  while (props.length) {
    const prop = props.shift()
    if (!obj || !Object.prototype.hasOwnProperty.call(obj, prop)) {
      return defaultValue
    }
    obj = obj[prop]
  }
  return obj
}

export function interpolate(template = '', data = {}, formatters) {
  formatters = formatters
    ? { ...defaultFormatters, ...formatters }
    : defaultFormatters

  return template.replace(/{\$([^}]+)}/g, (_, capturedPath) => {
    let formatter = identity
    const path = capturedPath.replace(/:(.*)$/, (_, capturedFormatter) => {
      const matchingFormatter = formatters[capturedFormatter]
      if (matchingFormatter) {
        formatter = matchingFormatter
      }
      return ''
    })
    return formatter(get(path, data, ''))
  })
}

export function isValidationError(err) {
  if (!err) {
    return false
  }
  return err.errorCode === ERROR_VALIDATION
}

export function getErrors(err) {
  if (!err) {
    return []
  }
  return isValidationError(err) ? err.validationErrors : [err]
}

export function getErrorCodes(err) {
  if (!err) {
    return []
  }
  const isValidationError = err.errorCode === ERROR_VALIDATION
  return isValidationError
    ? err.validationErrors.map((ve) => ve.errorCode)
    : [err.errorCode]
}

export function matchesErrorCode(errorCode, err) {
  return getErrorCodes(err).some((ec) => ec === errorCode)
}

export function matchesSomeErrorCodes(errorCodes, err) {
  return errorCodes.some((ec) => matchesErrorCode(ec, err))
}

export function getErrorMessageByCode(messageByCodeMap, defaultMessage, err) {
  if (!err) {
    return ''
  }
  const code = getErrorCodes(err)[0]
  return messageByCodeMap[code] || defaultMessage
}

export function loadScript(url, attributes = []) {
  return new Promise((resolve, reject) => {
    const head = document.head || document.getElementsByTagName('head')[0]
    const script = Object.assign(document.createElement('script'), {
      async: true,
      src: url,
      onload: resolve,
      onerror: reject,
    })
    attributes.forEach((attribute) => {
      script.setAttribute(attribute.name, attribute.value)
    })
    head.appendChild(script)
  })
}

export function pollPromise(
  promiseFunc,
  { waitTimeMs = 1000, retryCount = 3 },
) {
  return new Promise((resolve, reject) => {
    promiseFunc()
      .then(resolve)
      .catch((e) => {
        if (retryCount === 0) {
          return reject(e)
        }
        setTimeout(() => {
          pollPromise(promiseFunc, { waitTimeMs, retryCount: retryCount - 1 })
            .then(resolve)
            .catch(reject)
        }, waitTimeMs)
      })
  })
}

export function deepClone(obj) {
  return obj === undefined ? obj : JSON.parse(JSON.stringify(obj))
}

/**
 * Using a nested Math.round to round the specified number to the 100ths place and then again at the 10ths place to accurately
 * save the rounded number as intended
 * @param {Number} num The number value to round
 * @returns The string value of the number with 2 significant digits after the "."
 */
export function toDecimalString(num) {
  return (Math.round(Math.round(num * 1000) / 10) / 100).toFixed(2)
}
