import * as types from '@/store/mutation-types/googleMapsMutations'

const state = {
  geocoder: null,
  lastGeocodeResult: null,
}

const getters = {
  lastGeocodeResult: (state) => {
    const geocodeResult = state.lastGeocodeResult
    if (
      !!geocodeResult &&
      !!geocodeResult.results &&
      geocodeResult.results.length > 0
    ) {
      const location = geocodeResult.results[0].geometry.location
      return {
        lat: location.lat(),
        lng: location.lng(),
      }
    }
    return null
  },
}

const actions = {
  [types.GET_GOOGLE_MAPS_LIBRARIES](_storeHelpers, libraries) {
    // The importLibrary method is defined on index.html.
    // This dynamically loads the Google Maps scripts and returns the maps library.
    // Will just return the library if it has already been loaded.
    return Promise.all(
      libraries.map((l) => window.google.maps.importLibrary(l)),
    )
  },
  [types.GET_GEOCODE_OF_SEARCH_QUERY]({ dispatch, state, commit }, address) {
    const getGeocode = (req) => {
      return state.geocoder.geocode(req).then((response) => {
        commit(types.UPDATE_LAST_GEOCODE_RESULT, response)
        return response
      })
    }

    if (!state.geocoder) {
      return dispatch(types.GET_GOOGLE_MAPS_LIBRARIES, ['maps']).then(() => {
        commit(types.UPDATE_GEOCODER, new window.google.maps.Geocoder())
        return getGeocode({ address })
      })
    }
    return getGeocode({ address })
  },
}

const mutations = {
  [types.UPDATE_GEOCODER](state, geocoder) {
    state.geocoder = geocoder
  },
  [types.UPDATE_LAST_GEOCODE_RESULT](state, result) {
    state.lastGeocodeResult = result
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
