<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :style="svgStyle"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    class="check-svg"
    @click="svgClick"
  >
    <g fill="none" fill-rule="evenodd" transform="translate(-.05)">
      <circle cx="10.049" cy="10" r="10" fill="#C08B15" />
      <g fill="#FFF">
        <path d="M6.277 8.974l3.346 3.346-.865.865L5.412 9.84z" />
        <path d="M7.948 12.32l5.856-5.856.866.866-5.856 5.855z" />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    pixelSize: {
      type: String,
      default: '',
    },
  },
  computed: {
    svgStyle() {
      return this.pixelSize
        ? { height: this.pixelSize, width: this.pixelSize }
        : ''
    },
  },
  methods: {
    svgClick(e) {
      this.$emit('checksvg-click', e)
    },
  },
}
</script>
