<template>
  <div id="bag-empty">
    <div class="bag-empty-contents">
      <div class="bag-empty-watermark">
        <img
          src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
          class="bag-watermark"
        />
      </div>
      <div class="bag-empty-msg">
        GIVE YOUR GUESTS WHAT THEY REALLY WANT.
        <br />
        START YOUR CATERING ORDER NOW.
      </div>
    </div>

    <div class="bag-btn-group">
      <b-button
        variant="primary"
        block
        size="md"
        class="bag-empty-btn-order-catering"
        @click="navClose"
      >
        START A CATERING ORDER
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'empty-bag',
  methods: {
    navClose() {
      this.$root.$emit('close-bag')
      this.$router.push({ path: '/' })
    },
  },
}
</script>

<style lang="scss" scoped>
#bag-empty {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.bag-watermark {
  width: 94px;
  height: 121px;
  object-fit: contain;
  background-image: url('@/static/img/bag-watermark.svg');
  background-position: center;
}

.bag-empty-msg {
  color: $catering-chocolate;
  font-family: $font-family-sans-serif;
  font-size: 15px;
  font-weight: bold;
  margin-top: 30px;
}

.bag-empty-btn-order-catering {
  position: absolute;
  width: 100%;
  height: 44px;
  bottom: 12px;
  font-size: 16px;
  letter-spacing: -0.3px;
}

@include media-breakpoint-up(lg) {
  .bag-empty-btn-order-catering {
    width: 290px;
    left: 50%;
    margin-left: -145px;
  }
}
</style>
