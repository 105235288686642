var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',_vm._g(_vm._b({class:[
    'custom-modal',
    {
      'always-full-screen': _vm.alwaysFullScreen,
      'mobile-full-screen': _vm.mobileFullScreen,
      'expose-site-header': !_vm.coverSiteHeader,
      'position-top-left': _vm.position === 'top-left',
      'position-top-right': _vm.position === 'top-right',
      'hide-header': !_vm.showHeaderText,
    },
  ],scopedSlots:_vm._u([(_vm.showDefaultHeaderSlot)?{key:"modal-header",fn:function(){return [(_vm.showHeaderText)?_c('h2',{staticClass:"h5 modal-title"},[_vm._v(" "+_vm._s(_vm.attributes.title)+" ")]):_vm._e(),(!_vm.hideHeaderClose)?_c('button',{class:[
        'modal-close',
        {
          'modal-close-background': _vm.showModalCloseBackground,
          'modal-close-no-background': !_vm.showModalCloseBackground,
        },
      ],attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return _vm.$emit('change', false)}}},[_vm._v(" × ")]):_vm._e()]},proxy:true}:null,_vm._l((_vm.$slots),function(_,name){return {key:name,fn:function(){return [_vm._t(name)]},proxy:true}})],null,true)},'b-modal',_vm.attributes,false),_vm.listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }