<template>
    <div>
        <div v-html="footerHtml" />
    </div>
</template>

<script>
import axios from 'axios';
import { parse } from 'node-html-parser';

export default {
  name: 'AemFooter',
  data() {
    return {
        footerHtml: '<div></div>',
    }
  },
  components: {
    
  },
  mounted() {
    this.getFooterXfHtml()
  },
  methods: {
    async getFooterXfHtml () {
        const aemXfPath = process.env.VUE_APP_CHIPOTLE_URL;
        return await axios.get(`${aemXfPath}/content/experience-fragments/chipotle/us/en/xf-footer/catering.html`)
        .then((res)=>{
            if(res) {
                const doc = parse(res.data);
                const innerFooterContainer = doc.getElementById("footer-container");
                const clientBase = doc.querySelector('link[href*="clientlib-base"]');
                const cleanedClientBase = clientBase.toString().replace("/etc", `${aemXfPath}/etc`);

                //clean style script src
                const innerScripts = innerFooterContainer.querySelectorAll(".clientliblist");
                innerScripts.forEach(inner => {
                    const tempInner = inner.innerHTML;
                    const cleanedInner = tempInner.replace("/etc", `${aemXfPath}/etc`);
                    inner.innerHTML = cleanedInner;
                });

                //clean image script src
                const footerImages = innerFooterContainer.querySelectorAll(".image");
                footerImages.forEach(inner => {
                    const tempInner = inner.innerHTML;
                    const cleanedInner = tempInner.replace(new RegExp('/adobe', 'g'), `${aemXfPath}/adobe`);
                    inner.innerHTML = cleanedInner;
                });

                //clean cookie pref image path
                const cookiePreferences = innerFooterContainer.querySelectorAll(".open-cookie-preferences");
                const cleanedCookieImage = cookiePreferences[1].innerHTML.replace("/content", `${aemXfPath}/content`);
                cookiePreferences[1].setAttribute("onclick", "window.semaphore.push(['showPreferences', { tab: 'consentTab', showOverviewTab: false }])");
                cookiePreferences[1].innerHTML = cleanedCookieImage;
                
                //set html to cleaned version
                this.footerHtml = cleanedClientBase + innerFooterContainer.toString();
            }
        }).catch((err) => {
            console.log('error fetching aem footer', err)
        });
}
  }

}

</script>

<style lang="scss">
  #footer-tertiary-links p {
    a:hover {
      color: $catering-light-grey-brown
    }
  }

  #footer-container .container:nth-child(6) {
    grid-column: 1 !important;
  }

  .responsivegrid {
    padding-left: 0 !important;
    max-width: none !important;
  }

  @media (max-width: 991.98px) {
    #footer-secondary-links p a{
      font-size: 1.25rem !important;
      letter-spacing: 0;
    }

    #footer-apps-socials h3{
      font-size: 1.38rem !important;
      letter-spacing: 0;
    }
  }

  @media (min-width: 768px) {
    #footer-container .container:nth-child(6) {
      grid-column: 1/3 !important;
      max-width: none;
      padding-right: 0;
    }
  }
</style>
