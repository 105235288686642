<template>
  <custom-modal
    hide-footer
    hide-header
    always-full-screen
    :visible="isModalVisible"
    @change="setModalVisibility"
  >
    <div class="account-verifier">
      <component
        :is="internalModalComponentDictionary[verificationStep]"
        v-bind="internalModalPropsDict[verificationStep]"
        @phoneVerification:resend-code="resendVerificationCode"
        @phoneVerification:verify-phone="verifyPhoneNumber"
        @passwordVerification:verify-password="verifyPasswordValue"
        @passwordVerification:cancel-verification="setModalVisibility(false)"
        @phoneConfirmation:send-verification-code="sendVerificationCode"
      />
    </div>
  </custom-modal>
</template>

<script>
import {
  ERROR_VERIFICATION_INVALID_CODE,
  ERROR_VERIFICATION_ACCOUNT_LOCKED,
  ERROR_VERIFICATION_CODE_EXPIRED,
  ERROR_VERIFICATION_PASSWORD_FAILED,
} from '@/constants/errorCodes'
import {
  CANCEL_ACCOUNT_VERIFICATION,
  VERIFY_ACCOUNT_TOKEN,
  RESEND_VERIFICATION,
  VERIFY_ACCOUNT_PASSWORD,
  SEND_PHONE_VERIFICATION_CODE,
} from '@/store/mutation-types/accountVerificationMutations'
import * as accountVerificationSteps from '@/constants/accountVerificationSteps'
import { AccountUpdatedWalletClearedMessage } from '@/constants/dialogs'
import { SHOW_CONFIRM_DIALOG } from '@/store/mutation-types/confirmDialogMutations'
import { mapActions, mapGetters } from 'vuex'
import {
  PhoneVerificationUnexpectedError,
  CancelPhoneVerificationWarning,
} from '@/constants'
import { validityStateInitialize } from '@/validators/index'
import CustomModal from '@/components/common/CustomModal'
import AccountVerifyPassword from './AccountVerifyPassword.vue'
import AccountVerifyPhone from '@/components/account/AccountVerifyPhone.vue'
import AccountConfirmPhone from '@/components/account/AccountConfirmPhone.vue'
import { removeNonNumericMask } from '@/masks'

const errorMessageByErrorCode = {
  [ERROR_VERIFICATION_INVALID_CODE]: 'Please enter a valid verification code',
  [ERROR_VERIFICATION_CODE_EXPIRED]: 'Verification code has expired',
  [ERROR_VERIFICATION_ACCOUNT_LOCKED]:
    "Verification failed. You've used up all of your attempts. Try again in 30 minutes. Good thing you can still order Chipotle as a guest.",
  [ERROR_VERIFICATION_PASSWORD_FAILED]:
    "The password you entered isn't quite right. As a reminder your password contains: at least 8 characters | ABC | abc | 123 | !@%",
}

export default {
  name: 'account-verify-modal',
  components: {
    CustomModal,
    AccountVerifyPhone,
    AccountVerifyPassword,
    AccountConfirmPhone,
  },
  data() {
    return {
      errorCode: '',
      phoneWasUpdated: false,
      verifyPayload: { verifyValue: '' },
    }
  },
  computed: {
    ...mapGetters({
      phoneNumber: 'accountVerificationPhoneNumber',
      verificationFinalAttempt: 'accountVerificationErrorIsFinalAttempt',
      isModalVisible: 'isVerificationInProgress',
      verificationStep: 'verificationStep',
    }),
    errorMessage() {
      if (
        this.errorCode === ERROR_VERIFICATION_INVALID_CODE &&
        this.verificationFinalAttempt
      ) {
        return 'This is your last attempt. If unsuccessful, you can still order Chipotle as a guest.'
      } else {
        return errorMessageByErrorCode[this.errorCode] || ''
      }
    },
    isAccountLockedOut() {
      return this.errorCode === ERROR_VERIFICATION_ACCOUNT_LOCKED
    },
    internalModalComponentDictionary() {
      return {
        [accountVerificationSteps.PHONE_VERIFICATION]: AccountVerifyPhone,
        [accountVerificationSteps.PASSWORD_VERIFICATION]: AccountVerifyPassword,
        [accountVerificationSteps.PHONE_CONFIRMATION]: AccountConfirmPhone,
      }
    },
    modalValidationState: { get: () => validityStateInitialize() },
    internalModalPropsDict() {
      return {
        [accountVerificationSteps.PHONE_VERIFICATION]: {
          verifyPayload: this.verifyPayload,
          phoneNumber: this.phoneNumber,
          errorMessage: this.errorMessage,
          accountLocked: this.isAccountLockedOut,
          validityState: this.modalValidationState,
        },
        [accountVerificationSteps.PASSWORD_VERIFICATION]: {
          verifyPayload: this.verifyPayload,
          errorMessage: this.errorMessage,
          accountLocked: this.isAccountLockedOut,
          validityState: this.modalValidationState,
        },
        [accountVerificationSteps.PHONE_CONFIRMATION]: {
          phoneNumber: this.phoneNumber,
          errorMessage: this.errorMessage,
          accountLocked: this.isAccountLockedOut,
          validityState: this.modalValidationState,
        },
      }
    },
  },
  methods: {
    ...mapActions({
      verificationCancelled: CANCEL_ACCOUNT_VERIFICATION,
      verifyAccountToken: VERIFY_ACCOUNT_TOKEN,
      resend: RESEND_VERIFICATION,
      verifyPassword: VERIFY_ACCOUNT_PASSWORD,
      sendPhoneVerificationCode: SEND_PHONE_VERIFICATION_CODE,
    }),
    resetPhoneWasUpdated() {
      this.phoneWasUpdated = false
    },
    verifyPhoneNumber(token) {
      this.clearState()
      this.verifyAccountToken(token)
        .then(() => {
          // if phone was updated, let user know that credit card information was cleared
          if (this.phoneWasUpdated) {
            this.$store.dispatch(SHOW_CONFIRM_DIALOG, {
              ...AccountUpdatedWalletClearedMessage,
              okCallback: this.resetPhoneWasUpdated,
            })
          }
        })
        .catch((e) => {
          this.errorCode = e.errorCode
          if (!this.errorMessage) {
            this.handleUnexpectedErrorMessage()
          }
        })
    },
    verifyPasswordValue(password) {
      this.clearState()
      this.verifyPassword(password).catch((e) => {
        this.errorCode = e.errorCode
        if (!this.errorMessage) {
          this.handleUnexpectedErrorMessage()
        }
      })
    },
    clearState() {
      this.verifyPayload = { verifyValue: '' }
      this.errorCode = ''
      this.modalValidationState.reset()
    },
    sendVerificationCode(phoneNumber) {
      this.clearState()
      const overridePhoneNumber = removeNonNumericMask(phoneNumber)

      // if user updated phone number, display wipe the wallet at the end of 2sv flow
      if (overridePhoneNumber !== '') {
        this.phoneWasUpdated = true
      }

      this.sendPhoneVerificationCode(overridePhoneNumber).catch(() => {
        this.handleUnexpectedErrorMessage()
      })
    },
    resendVerificationCode() {
      this.clearState()
      this.resend()
        .then(() => {
          this.$store.dispatch(SHOW_CONFIRM_DIALOG, {
            headerText: '',
            text: 'CODE HAS BEEN SENT.',
            subText: 'Please wait 1 minute before trying again.',
            okText: 'OKAY',
            hideCancel: true,
          })
        })
        .catch(() => {
          this.handleUnexpectedErrorMessage()
        })
    },
    cancelVerification() {
      this.clearState()
      this.verificationCancelled()
    },
    setModalVisibility(newValue) {
      // Closing the modal
      if (!newValue && this.isModalVisible) {
        if (this.isAccountLockedOut) {
          this.cancelVerification()
        } else {
          this.$store.dispatch(SHOW_CONFIRM_DIALOG, {
            ...CancelPhoneVerificationWarning,
            cancelCallback: this.cancelVerification,
          })
        }
      }
    },
    handleUnexpectedErrorMessage() {
      this.$store.dispatch(SHOW_CONFIRM_DIALOG, {
        ...PhoneVerificationUnexpectedError,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.account-verifier {
  display: flex;
  justify-content: center;
}
</style>
