import { createClient } from '@/services/clients/createClient'
import store from '@/store'
import router from '@/router'
import { SHOW_CONFIRM_DIALOG } from '@/store/mutation-types/confirmDialogMutations'
import { RESET_USER, TRACK_USER } from '@/store/mutation-types/userMutations'
import { UNDO_SUBMITTED_ORDER_FOR_UPDATE } from '@/store/mutation-types/orderMutations'
import { HOME } from '@/router/routeNames'
import {
  ERROR_SERVICE_DEPENDENCY_UNAVAILABLE,
  ERROR_VALIDATION_UPDATE_BAD_STATUS,
} from '@/constants/errorCodes'
import { matchesErrorCode } from '@/utilityFunctions'

const { VUE_APP_VERSION, VUE_APP_BASE_URL } = process.env
const options = {
  baseURL: `${VUE_APP_BASE_URL}${VUE_APP_VERSION}`,
  withCredentials: true,
}
export const cateringOrderClient = createClient(options, cateringErrorHandler)

function handleInvalidToken(error, shouldHandle) {
  if (
    shouldHandle &&
    error &&
    error.response &&
    error.response.status === 401 &&
    error.response.headers['invalid-token']
  ) {
    store.dispatch(RESET_USER)
    store.dispatch(SHOW_CONFIRM_DIALOG, {
      text: 'BAD NEWS. YOUR SESSION HAS EXPIRED!',
      subText:
        'Good news. You can still order burritos. You just might want to Sign In or continue as guest.',
      okText: 'OKAY',
      hideCancel: true,
      okCallback: () => router.push({ name: HOME }),
    })
  }
}

function handleOrderNotEditable(errorData, shouldHandle) {
  if (
    shouldHandle &&
    matchesErrorCode(ERROR_VALIDATION_UPDATE_BAD_STATUS, errorData)
  ) {
    store.dispatch(UNDO_SUBMITTED_ORDER_FOR_UPDATE).catch(() => {})
    store.dispatch(SHOW_CONFIRM_DIALOG, {
      headerText: 'Cannot Edit Order',
      text: 'This order cannot be edited.',
      okText: 'OKAY',
      hideCancel: true,
      okCallback: () => router.push({ name: HOME }),
    })
  }
}

function handleCriticalError(errorData, shouldHandle) {
  let errorHandled = false
  if (
    shouldHandle &&
    matchesErrorCode(ERROR_SERVICE_DEPENDENCY_UNAVAILABLE, errorData)
  ) {
    store.dispatch(SHOW_CONFIRM_DIALOG, {
      text: "Sorry, something's not right",
      subText: 'Please try again later.',
      okText: 'OKAY',
      hideCancel: true,
    })
    errorHandled = true
  }
  return errorHandled
}

// TODO: somehow move error handling to the action to remove service dependency on store (circular dependency)
export function cateringErrorHandler(error, options) {
  handleInvalidToken(error, !options.callerHandlesInvalidToken)

  // get directly to the meat of the response for easier access in the components
  if (error && error.response && error.response.data) {
    if (
      error.response.headers &&
      error.response.headers.has('X-Account-Hash')
    ) {
      store.dispatch(TRACK_USER, error.response)
    }

    error = error.response.data
  }

  if (!handleCriticalError(error, !options.callerHandlesCriticalError)) {
    handleOrderNotEditable(error, !options.callerHandlesOrderNotEditable)
  }

  // don't make an error handler call here.
  // let the invoker determine if it needs to
  // to log any errors.
  throw error
}
