// actions
export const GET_WALLET = 'GET_WALLET'
export const SAVE_CARD_TO_WALLET = 'SAVE_CARD_TO_WALLET'
export const DELETE_CARD_FROM_WALLET = 'DELETE_CARD_FROM_WALLET'
export const GET_PAYMENT_PROVIDER = 'GET_PAYMENT_PROVIDER'
export const INIT_PAYMENT_PROVIDER_TOKENIZATION =
  'INIT_PAYMENT_PROVIDER_TOKENIZATION'
export const GET_TOKENIZED_PAYMENT_INFO = 'GET_TOKENIZED_PAYMENT_INFO'
// mutations
export const UPDATE_SEARCH_INFO = 'UPDATE_SEARCH_INFO'
export const UPDATE_WALLET = 'UPDATE_WALLET'
