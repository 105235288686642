import seon from '@seontechnologies/seon-javascript-sdk'
import errorService from '@/services/errorService'
import { createNewGuid } from '@/common/guid'

let isSeonLoaded = false

export function getIsSeonLoaded() {
  return isSeonLoaded
}

// setter should only be used for unit testing.
// local variable should only be modified via seon service or unit tests
export function setIsSeonLoaded(newVal) {
  isSeonLoaded = newVal
}

export function loadSeon() {
  if (!getIsSeonLoaded()) {
    const { VUE_APP_SEON_CDN_HOST, VUE_APP_IS_LOCAL } = process.env

    seon.config({
      host: VUE_APP_SEON_CDN_HOST,
      session_id: createNewGuid(),
      audio_fingerprint: true,
      canvas_fingerprint: true,
      webgl_fingerprint: true,
      silent_mode: !VUE_APP_IS_LOCAL,
      max_proxy_delay: 1000,
      onSuccess: (_) => {
        setIsSeonLoaded(true)
      },
      onError: (message) => {
        if (message) {
          errorService.handle(`SEON library failed to load: ${message}`)
        } else {
          errorService.handle(`SEON library failed to load`)
        }
      },
    })
  }
}

export function getSession() {
  return getIsSeonLoaded()
    ? seon.getBase64Session().catch((err) => {
        errorService.handle(`Unable to generate SEON session: ${err}`)
        return undefined
      })
    : Promise.resolve(undefined)
}
