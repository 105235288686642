import * as types from '@/store/mutation-types/restaurantDataMutations'
import * as loaderTypes from '@/store/mutation-types/loaderMutations'
import restaurantDataService from '@/services/restaurantDataService'
import toStringTime from '@/filters/toStringTime'
import { toDecimalString } from '@/utilityFunctions'
import { GET_GEOCODE_OF_SEARCH_QUERY } from '@/store/mutation-types/googleMapsMutations'

const state = {
  pickupTimeData: [],
  deliveryTimeData: [],
  searchResults: [],
  deliveryRestaurantResults: [],
  restaurant: {},
  restaurantDeliveryRealHours: [],
  restaurantPickupRealHours: [],
  confirmationRestaurant: {},
  searchInfo: {
    currentIndex: 0,
    currentSearchString: '',
    hasSearched: false,
    searching: false,
    pagingInfo: null,
    menuId: null,
  },
  search: {
    show: false,
    queryString: '',
  },
  searchLocation: {
    lat: '',
    lng: '',
  },
  loaderName: 'restaurantSearch',
}

const getters = {
  currentRestaurant: (state) => state.restaurant,
  confirmationRestaurant: (state) => state.confirmationRestaurant,
  deliveryRestaurantResults: (state) => state.deliveryRestaurantResults,
  hasAvailableDeliveryTimes: (state) => {
    return !!(
      state.deliveryTimeData &&
      state.deliveryTimeData.length &&
      state.deliveryTimeData.some((t) => !t.disabled)
    )
  },
  hasAvailablePickupTimes: (state) => {
    return !!(
      state.pickupTimeData &&
      state.pickupTimeData.length &&
      state.pickupTimeData.some((t) => !t.disabled)
    )
  },
  restaurantPickupTimes: (state) => state.pickupTimeData,
  restaurantDeliveryTimes: (state) => state.deliveryTimeData,
  restaurantDeliveryRealHours: (state) => state.restaurantDeliveryRealHours,
  restaurantPickupRealHours: (state) => state.restaurantPickupRealHours,
  restaurantSearchHasSearched: (state) => state.searchInfo.hasSearched,
  restaurantSearchLocation: (state) => state.searchLocation,
  restaurantSearchIsSearching: (state) => state.searchInfo.searching,
  restaurantSearchResults: (state) => state.searchResults,
  restaurantDistance: (state) => (item) => {
    if (!item.distance) {
      return ''
    }

    const distance = Number(item.distance)
    const mileConversionFactor = 0.000621371
    const feetConversionFactor = 3.28084

    if (distance > 305) {
      // converts to about 1000 ft, so use miles
      return toDecimalString(distance * mileConversionFactor) + ' mi'
    } else {
      return toDecimalString(distance * feetConversionFactor) + ' ft'
    }
  },
  restaurantCrossStreet: (state) => (item) => {
    let tempCrossStreet = ''
    if (item.directions) {
      if (
        item.directions.crossStreet1 &&
        item.directions.crossStreet1 !== '?'
      ) {
        tempCrossStreet = 'On ' + item.directions.crossStreet1
      }

      if (
        item.directions.crossStreet2 &&
        item.directions.crossStreet2 !== '?'
      ) {
        if (tempCrossStreet) {
          tempCrossStreet += ' & '
        } else {
          tempCrossStreet += 'On '
        }
        tempCrossStreet += item.directions.crossStreet2
      }

      if (item.directions.landmark) {
        if (tempCrossStreet) {
          tempCrossStreet += ', '
        }
        tempCrossStreet += item.directions.landmark
      }
    }
    return tempCrossStreet
  },
  restaurantAddress: (state) => (item) => {
    let tempAddress = ''
    if (item.addresses && item.addresses.length > 0) {
      const addressItem = item.addresses[0]

      if (addressItem.addressLine1) {
        tempAddress = addressItem.addressLine1
      }

      if (addressItem.addressLine2) {
        if (tempAddress) {
          tempAddress += '\n'
        }
        tempAddress += addressItem.addressLine2
      }

      if (addressItem.locality) {
        if (tempAddress) {
          tempAddress += '\n'
        }
        tempAddress += addressItem.locality
      }

      if (addressItem.administrativeArea) {
        if (addressItem.locality && tempAddress) {
          tempAddress += ', '
        } else if (tempAddress) {
          tempAddress += '\n'
        }
        tempAddress += addressItem.administrativeArea
      }

      if (addressItem.postalCode) {
        if (
          (addressItem.locality || addressItem.administrativeArea) &&
          tempAddress
        ) {
          tempAddress += ' '
        } else if (tempAddress) {
          tempAddress += '\n'
        }
        tempAddress += addressItem.postalCode
      }
    }

    return tempAddress
  },
  restaurantOffersDelivery: (state) => (item) => {
    if (item.delivery) {
      return item.delivery.cateringDeliveryEnabled
    } else {
      return false
    }
  },
  restaurantHours: (state) => (item) => {
    let tempHours = ''

    if (item.realHours && item.realHours.length > 0) {
      tempHours = ''
      let currentOpenTime, currentCloseTime, startDay
      let currentItem, lastItem, lastStartDay
      let currentItemOpenTime, currentItemCloseTime

      currentOpenTime = formatTime(item.realHours[0].openDateTime)
      currentCloseTime = formatTime(item.realHours[0].closeDateTime)
      startDay = item.realHours[0].dayOfWeek

      for (let index = 0; index < item.realHours.length; index++) {
        currentItem = item.realHours[index]
        if (index !== 0) {
          lastItem = item.realHours[index - 1]
          lastStartDay = lastItem.dayOfWeek
        }

        currentItemOpenTime = formatTime(currentItem.openDateTime)
        currentItemCloseTime = formatTime(currentItem.closeDateTime)
        if (
          currentOpenTime !== currentItemOpenTime ||
          currentCloseTime !== currentItemCloseTime
        ) {
          tempHours += formatDay(startDay)
          if (startDay !== lastStartDay) {
            tempHours += ' - ' + formatDay(lastStartDay)
          }
          tempHours += ': ' + currentOpenTime
          if (currentCloseTime !== 'Closed') {
            tempHours += ' - ' + currentCloseTime
          }
          currentOpenTime = currentItemOpenTime
          currentCloseTime = currentItemCloseTime
          startDay = currentItem.dayOfWeek
          tempHours += '\n'
        }
      }

      const currentStartDay = currentItem.dayOfWeek
      tempHours += formatDay(startDay)
      if (startDay !== currentStartDay) {
        tempHours += ' - ' + formatDay(currentStartDay)
      }
      tempHours += ': ' + currentOpenTime
      if (currentCloseTime !== 'Closed') {
        tempHours += ' - ' + currentCloseTime
      }
    }
    return tempHours
  },
  restaurantSearch: (state) => state.search,
}

const actions = {
  [types.GET_RESTAURANT_TIMES]({ commit, dispatch, state }, request) {
    dispatch(loaderTypes.QUEUE_LOADER, {
      name: state.loaderName,
      mainText: 'Getting restaurant times',
      subText: '',
    })
    const restaurantTimesMutationName = request.isDelivery
      ? types.UPDATE_RESTAURANT_DELIVERY_TIMES
      : types.UPDATE_RESTAURANT_PICKUP_TIMES

    return restaurantDataService
      .getRestaurantTimes(request)
      .then((response) => {
        // this endpoint will always return an array of data
        // in the below expected format on a successful call.
        const restaurantTimes = response.data.map((interval) => {
          // safari is not constructing a date in an identical manner to other browsers when
          // utilizing the default new Date('string') constructor.
          // use the following to split the incoming timestamp and use the subsequent parts
          // to initialize a compatible dateTime value.  Taken from:
          // https://stackoverflow.com/a/6427318/1165998
          const parts = interval.intervalStartTime.split(/[^0-9]/)
          const pickupTime = new Date(
            parts[0],
            parts[1] - 1,
            parts[2],
            parts[3],
            parts[4],
            parts[5],
          )

          return {
            text: toStringTime(pickupTime),
            // even tho it already was a utc string, we do this to remove any extra 0 digits so that the === works
            value: new Date(interval.intervalStartTimeUtc).toISOString(),
            hours: pickupTime.getHours(),
            minutes: pickupTime.getMinutes(),
            disabled: !interval.isIntervalAvailable,
            restaurantNumbers: interval.restaurantNumbers,
          }
        })

        commit(restaurantTimesMutationName, restaurantTimes)
      })
      .catch((error) => {
        // dispatch order validate? make sure restaurant is still available
        commit(restaurantTimesMutationName, [])
        throw error
      })
      .finally(() => {
        dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
      })
  },

  [types.GET_RESTAURANT_PICKUP_HOURS](
    { commit, dispatch, state },
    restaurantNumber,
  ) {
    dispatch(loaderTypes.QUEUE_LOADER, {
      name: state.loaderName,
      mainText: 'Getting restaurant dates',
      subText: '',
    })

    return restaurantDataService
      .getRestaurantRealHours(restaurantNumber)
      .then((response) => {
        commit(types.UPDATE_RESTAURANT_PICKUP_HOURS, response.data)
      })
      .catch((e) => {
        commit(types.UPDATE_RESTAURANT_PICKUP_HOURS, [])
      })
      .finally(() => {
        dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
      })
  },
  [types.SEARCH_FOR_RESTAURANTS]({ commit, dispatch, state }, params) {
    if (state.searchInfo.searching || !params.searchString) {
      return
    }

    dispatch(loaderTypes.QUEUE_LOADER, {
      name: state.loaderName,
      mainText: 'Searching for restaurants',
      subText: '',
    })

    commit(types.UPDATE_RESTAURANT_SEARCH_INFO, {
      ...state.searchInfo,
      ...{ hasSearched: false, searching: true },
    })

    const menuIds = []
    if (params.menuId) {
      menuIds.push(params.menuId)
    }

    return dispatch(GET_GEOCODE_OF_SEARCH_QUERY, params.searchString)
      .then((response) => {
        const geocode = {
          latitude: response.results[0].geometry.location.lat(),
          longitude: response.results[0].geometry.location.lng(),
        }
        return restaurantDataService.search(geocode, 0, menuIds)
      })
      .then((response) => {
        commit(types.UPDATE_RESTAURANT_SEARCH_INFO, {
          ...state.searchInfo,
          ...{
            currentIndex: 0,
            currentSearchString: params.searchString,
            hasSearched: true,
            searching: false,
            menuId: params.menuId,
          },
        })
        commit(types.UPDATE_RESTAURANT_SEARCH, response.data)
      })
      .catch((e) => {
        commit(types.UPDATE_RESTAURANT_SEARCH_INFO, {
          ...state.searchInfo,
          ...{
            currentIndex: 0,
            currentSearchString: params.searchString,
            hasSearched: true,
            searching: false,
            menuId: params.menuId,
          },
        })
        commit(types.UPDATE_RESTAURANT_SEARCH, { data: [] })
      })
      .finally(() => {
        dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
      })
  },
  [types.SEARCH_BY_LATLONG_FOR_RESTAURANTS](
    { commit, dispatch, state },
    params,
  ) {
    if (state.searchInfo.searching) {
      return
    }

    commit(types.UPDATE_RESTAURANT_SEARCH_INFO, {
      ...state.searchInfo,
      ...{ hasSearched: false, searching: true },
    })

    dispatch(loaderTypes.QUEUE_LOADER, {
      name: state.loaderName,
      mainText: 'Searching for restaurants',
      subText: '',
    })

    const menuIds = params.menuId ? [params.menuId] : []
    const { lat: latitude, lng: longitude } = params.latLong

    restaurantDataService
      .searchByLatLong({ latitude, longitude }, 0, menuIds)
      .then((response) => {
        commit(types.UPDATE_RESTAURANT_SEARCH_INFO, {
          ...state.searchInfo,
          ...{
            currentIndex: 0,
            hasSearched: true,
            searching: false,
            menuId: params.menuId,
          },
        })
        commit(types.UPDATE_RESTAURANT_SEARCH, {
          ...response.data,
          latitude,
          longitude,
        })
      })
      .catch((e) => {
        commit(types.UPDATE_RESTAURANT_SEARCH_INFO, {
          ...state.searchInfo,
          ...{
            currentIndex: 0,
            hasSearched: true,
            searching: false,
            menuId: params.menuId,
          },
        })
        commit(types.UPDATE_RESTAURANT_SEARCH, { data: [] })
      })
      .finally(() => {
        dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
      })
  },
  [types.GET_RESTAURANT]({ commit, dispatch, state }, restaurantId) {
    return getRestaurant(
      commit,
      dispatch,
      state,
      restaurantId,
      types.UPDATE_RESTAURANT_INFO,
    )
  },
  [types.GET_CONFIRMATION_RESTAURANT](
    { commit, dispatch, state },
    restaurantId,
  ) {
    return getRestaurant(
      commit,
      dispatch,
      state,
      restaurantId,
      types.UPDATE_CONFIRMATION_RESTAURANT_INFO,
    )
  },
  [types.GET_DELIVERY_RESTAURANTS]({ commit, dispatch, state }, request) {
    // the text isn't strictly accurate to what's happening, but
    // from the user's perspective, the restaurant-picking is
    // entirely behind the scenes.
    dispatch(loaderTypes.QUEUE_LOADER, {
      name: state.loaderName,
      mainText: 'Finding a restaurant',
      subText: '',
    })
    commit(types.UPDATE_RESTAURANT_SEARCH_INFO, {
      ...state.searchInfo,
      hasSearched: false,
      searching: true,
    })

    return restaurantDataService
      .getDeliveryRestaurantsAndHours(request)
      .then((res) => {
        commit(types.UPDATE_DELIVERY_RESTAURANTS, res.data.restaurants)
        commit(
          types.UPDATE_RESTAURANT_DELIVERY_HOURS,
          res.data.combinedRealHours,
        )
      })
      .catch((err) => {
        commit(types.UPDATE_DELIVERY_RESTAURANTS, [])
        commit(types.UPDATE_RESTAURANT_DELIVERY_HOURS, [])
        throw err
      })
      .finally(() => {
        commit(types.UPDATE_RESTAURANT_SEARCH_INFO, {
          ...state.searchInfo,
          currentIndex: 0,
          hasSearched: true,
          searching: false,
        })
        dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
      })
  },
}

const mutations = {
  [types.UPDATE_RESTAURANT_PICKUP_TIMES](state, newTimes) {
    state.pickupTimeData = newTimes
  },
  [types.UPDATE_RESTAURANT_DELIVERY_TIMES](state, newTimes) {
    state.deliveryTimeData = newTimes
  },
  [types.UPDATE_RESTAURANT_PICKUP_HOURS](state, realHoursResponse) {
    const realHours = realHoursResponse
      .filter((hours) => hours && hours.openDateTime && hours.closeDateTime)
      .map((hours) => {
        const date = new Date(hours.openDateTime)
        hours.date = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
        )
        return hours
      })

    state.restaurantPickupRealHours = realHours
  },
  [types.UPDATE_RESTAURANT_DELIVERY_HOURS](state, realHoursResponse) {
    const realHours = realHoursResponse
      .filter((hours) => hours && hours.openDateTime && hours.closeDateTime)
      .map((hours) => {
        const date = new Date(hours.openDateTime)
        hours.date = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
        )
        return hours
      })

    state.restaurantDeliveryRealHours = realHours
  },
  [types.UPDATE_RESTAURANT_SEARCH](state, newSearch) {
    state.searchResults = newSearch.data
    state.searchLocation.lat = newSearch.latitude
    state.searchLocation.lng = newSearch.longitude

    if (state.searchResults.length === 0) {
      state.searchInfo.pagingInfo = null
    } else {
      state.searchInfo.pagingInfo = newSearch.pagingInfo
    }
  },
  [types.APPEND_RESTAURANT_SEARCH](state, newSearch) {
    state.searchResults.push(...(newSearch.data || []))
    state.searchInfo.pagingInfo = newSearch.pagingInfo
  },
  [types.UPDATE_RESTAURANT_INFO](state, restaurant) {
    if (
      restaurant.data &&
      restaurant.data.data &&
      restaurant.data.data.length > 0
    ) {
      state.restaurant = restaurant.data.data[0]
    } else {
      state.restaurant = {}
    }
  },
  [types.UPDATE_CONFIRMATION_RESTAURANT_INFO](state, restaurant) {
    if (
      restaurant.data &&
      restaurant.data.data &&
      restaurant.data.data.length > 0
    ) {
      state.confirmationRestaurant = restaurant.data.data[0]
    } else {
      state.confirmationRestaurant = {}
    }
  },
  [types.UPDATE_RESTAURANT_SEARCH_INFO](state, newValue) {
    state.searchInfo = newValue
  },
  [types.UPDATE_DELIVERY_RESTAURANTS](state, restaurants) {
    state.deliveryRestaurantResults = restaurants
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}

function formatDay(day) {
  if (day) {
    return day
      .replace('nesday', '')
      .replace('rsday', '')
      .replace('urday', '')
      .replace('day', '')
  } else {
    return ''
  }
}

function formatTime(dateTime) {
  if (!dateTime) {
    return 'Closed'
  }

  const dateTimeParts = dateTime.split('T')
  const time = dateTimeParts[1].split(':')

  let hours = Number(time[0])
  const minutes = Number(time[1])
  let amPM = ' am'
  let returnValue = ''

  if (hours === 0) {
    returnValue = '12'
  } else if (hours < 10) {
    returnValue = '0' + hours.toString()
  } else if (hours >= 12) {
    amPM = ' pm'
    hours -= 12
    returnValue = hours.toString()
  } else {
    returnValue = hours.toString()
  }
  returnValue += ':'

  if (minutes < 10) {
    returnValue += '0'
  }
  returnValue += minutes.toString() + amPM
  return returnValue
}

function getRestaurant(commit, dispatch, state, restaurantId, commitType) {
  dispatch(loaderTypes.QUEUE_LOADER, {
    name: state.loaderName,
    mainText: 'Getting restaurant',
    subText: '',
  })

  return new Promise((resolve, reject) => {
    restaurantDataService
      .getRestaurant(restaurantId)
      .then((response) => {
        commit(commitType, response)
        resolve(state.restaurant)
      })
      .catch((e) => {
        commit(commitType, { data: {} })
        reject(e)
      })
      .finally(() => {
        dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
      })
  })
}
