import { cateringOrderClient } from '@/services/clients'

export default {
  getStarted() {
    return cateringOrderClient.get('/customerauth/getstarted', {
      includeCustomerInfo: true,
    })
  },
  register(registerCustomerRequest) {
    return cateringOrderClient.post('/customerauth/register', {
      isGuest: false,
      ...registerCustomerRequest,
    })
  },
  login(userName, password, shouldTimeout) {
    const request = {
      userName,
      password,
      shouldTimeout,
    }
    return cateringOrderClient.post('/customerauth/login', request)
  },
  upgrade(upgradeCustomerRequest) {
    return cateringOrderClient.post(
      '/customerauth/upgrade',
      upgradeCustomerRequest,
    )
  },
  logout() {
    return cateringOrderClient.get('/customerauth/logout')
  },
  changePassword(request) {
    return cateringOrderClient.put('/customerauth/changepassword', request)
  },
  verifyInitialize(request) {
    return cateringOrderClient.post('/customerauth/verify/initialize', request)
  },
  verifyFinalize(request) {
    return cateringOrderClient.post('/customerauth/verify/finalize', request)
  },
}
