import { cateringOrderClient } from '@/services/clients'

export default {
  getPaymentProvider(restaurantNumber) {
    return cateringOrderClient.get(`/transaction/provider`, {
      restaurantNumber,
    })
  },
  initializePaymentProviderRequest(paymentProviderId) {
    return cateringOrderClient.get(`/transaction/provider/${paymentProviderId}`)
  },
  getPaymentToken({ clientToken, paymentProviderId, accessToken }) {
    let url = `/transaction/tokenize/${paymentProviderId}/${clientToken}`
    if (accessToken) {
      url += `?accessToken=${accessToken}`;
    }
    return cateringOrderClient.get(url);
  },
  getWallet() {
    return cateringOrderClient.get(`/transaction/wallet`)
  },
  createWallet(cardInfo) {
    return cateringOrderClient.post(`/transaction/wallet`, cardInfo)
  },
  deleteWallet(tokenId) {
    return cateringOrderClient.delete(`/transaction/wallet/${tokenId}`)
  },
}
