import { createClient } from '@/services/clients/createClient'

const { VUE_APP_CHIPOTLE_URL } = process.env
const options = {
  baseURL: `${VUE_APP_CHIPOTLE_URL}/content-fragments`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
}
export const cmsClient = createClient(options)
