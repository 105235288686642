export default function toStringTime(value, format) {
  if (!value || !value.toLocaleDateString) {
    return value
  }

  if (!format) {
    format = { hour: 'numeric', minute: 'numeric' }
  }
  return value.toLocaleTimeString('en-US', format)
}
