import { SET_BUILDER_IS_DIRTY } from '../mutation-types/builderMutations'

const state = {
  isDirty: false,
}

const getters = {
  isBuilderDirty: (state) => state.isDirty,
}

const actions = {
  [SET_BUILDER_IS_DIRTY]({ commit }, isDirty) {
    commit(SET_BUILDER_IS_DIRTY, isDirty)
  },
}

const mutations = {
  [SET_BUILDER_IS_DIRTY](state, isDirty) {
    state.isDirty = isDirty
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
