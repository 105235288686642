import { getDateWithDayOfWeek } from '@/utilityFunctions'

export default function toStringDayOfWeekDateTime(value) {
  if (!value || !value.toLocaleDateString) {
    return value
  }

  const date = new Date(value)
  const result = getDateWithDayOfWeek(date)

  return result.toUpperCase()
}
