import { cmsClient } from '@/services/clients'

export default {
  getHeroBannerContent() {
    return cmsClient.get('catering-hero-banner.model.json', undefined, {
      // prevent using default headers
      headers: {},
    })
  },
}
