import * as types from '@/store/mutation-types/userMutations'
import * as orderTypes from '@/store/mutation-types/orderMutations'
import * as loaderTypes from '@/store/mutation-types/loaderMutations'
import { UPDATE_WALLET } from '@/store/mutation-types/transactionMutations'
import { UPDATE_ALL_SAVED_ADDRESSES } from '@/store/mutation-types/customerDeliveryMutations'
import { RESET_ALL_REWARDS_DATA } from '@/store/mutation-types/rewardsMutations'
import { GET_HOME_MENU } from '@/store/mutation-types/homeMenuMutations'
import customerAuthService from '@/services/customerAuthService'
import customerService from '@/services/customerService'
import analyticsService from '@/services/analyticsService'
import { AxiosHeaders } from 'axios'

const state = {
  isLoggedIn: false,
  isGuest: false,
  cateringEditOrderId: '',
  user: {},
}

const getters = {
  isLoggedIn: (state) => state.isLoggedIn,
  isGuest: (state) => state.isGuest,
  isFullUser: (state) => state.isLoggedIn && !state.isGuest,
  currentUser: (state) => state.user,
  isInEdit: (state) => state.user && !!state.user.cateringEditOrderId,
}

const actions = {
  [types.GET_STARTED]({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      dispatch(loaderTypes.SHOW_LOADER, {
        name: state.loaderName,
        mainText: 'Getting Ready',
        subText: '',
      })

      customerAuthService
        .getStarted()
        .then((response) => {
          dispatch(types.TRACK_USER, response)
          commit(types.UPDATE_USER, response.data)
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
        .finally(() => {
          dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
        })
    })
  },
  [types.GET_CUSTOMER]({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      dispatch(loaderTypes.SHOW_LOADER, {
        name: state.loaderName,
        mainText: 'Getting Your Information',
        subText: '',
      })

      customerService
        .getCustomer()
        .then((response) => {
          commit(types.UPDATE_USER, response.data)
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
        .finally(() => {
          dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
        })
    })
  },
  [types.CREATE_ACCOUNT]({ commit, dispatch, rootState, getters }, request) {
    if (getters.isFullUser) {
      return
    }
    dispatch(loaderTypes.SHOW_LOADER, {
      name: state.loaderName,
      mainText: 'Creating Account',
      subText: '',
    })
    return (
      getters.isGuest
        ? customerAuthService.upgrade(request)
        : customerAuthService.register(request)
    )
      .then((response) => {
        dispatch(types.TRACK_USER, response)
        commit(types.UPDATE_USER, response.data)
        const { title } = rootState.route.meta
        analyticsService.createAccountSuccess(title)
      })
      .catch((e) => {
        analyticsService.createAccountFailure()
        throw e
      })
      .finally(() => {
        dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
      })
  },
  [types.LOGIN]({ commit, dispatch, rootState }, request) {
    return new Promise((resolve, reject) => {
      dispatch(loaderTypes.SHOW_LOADER, {
        name: state.loaderName,
        mainText: 'Authenticating',
        subText: '',
      })

      customerAuthService
        .login(request.username, request.password, !request.shouldRemember)
        .then((response) => {
          dispatch(types.TRACK_USER, response)
          commit(types.UPDATE_USER, response.data)
          const { title } = rootState.route.meta
          analyticsService.userLoginSuccess(title)
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
        .finally(() => {
          dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
        })
    })
  },
  [types.LOGOUT]({ commit, dispatch }) {
    dispatch(loaderTypes.SHOW_LOADER, {
      name: state.loaderName,
      mainText: 'Signing off',
      subText: '',
    })

    return customerAuthService
      .logout()
      .then(() => {
        dispatch(types.UNTRACK_USER)
        dispatch(types.RESET_USER)
      })
      .finally(() => {
        dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
      })
  },
  [types.UPDATE_CUSTOMER_PROFILE]({ commit, dispatch, state }, request) {
    return new Promise((resolve, reject) => {
      dispatch(loaderTypes.SHOW_LOADER, {
        name: state.loaderName,
        mainText: 'Updating Account',
        subText: '',
      })

      customerService
        .updateCustomer(request)
        .then(() => {
          commit(types.UPDATE_USER, { ...state.user, ...request })
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
        .finally(() => {
          dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
        })
    })
  },
  [types.CHANGE_PASSWORD]({ commit, dispatch }, request) {
    return new Promise((resolve, reject) => {
      dispatch(loaderTypes.SHOW_LOADER, {
        name: state.loaderName,
        mainText: 'Changing Password',
        subText: '',
      })

      customerAuthService
        .changePassword(request)
        .then((response) => {
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
        .finally(() => {
          dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
        })
    })
  },
  [types.RESET_USER]({ commit, dispatch }) {
    commit(types.UPDATE_USER, null)
    commit(orderTypes.UPDATE_ORDER, {})
    commit(UPDATE_WALLET, [])
    commit(UPDATE_ALL_SAVED_ADDRESSES, [])
    dispatch(RESET_ALL_REWARDS_DATA)
    dispatch(GET_HOME_MENU)
  },
  [types.TRACK_USER]({ commit, dispatch }, response) {
    const headers = response?.headers
    if (
      headers &&
      headers instanceof AxiosHeaders &&
      headers.has('X-Account-Hash')
    ) {
      const accountHash = headers.get('X-Account-Hash')
      if (
        accountHash instanceof AxiosHeaders ||
        accountHash instanceof Object
      ) {
        localStorage.setItem('cmg-pvc', JSON.stringify(accountHash))
      } else if (typeof accountHash === 'string') {
        localStorage.setItem('cmg-pvc', accountHash)
      }
    }
  },
  [types.UNTRACK_USER]({ commit, dispatch }) {
    localStorage.removeItem('cmg-pvc')
  },
}

const mutations = {
  [types.UPDATE_USER](state, user) {
    state.isLoggedIn = (typeof user !== 'undefined' && user !== null) || false
    state.isGuest = user ? user.isGuest : false
    state.cateringEditOrderId = user ? user.cateringEditOrderId : ''
    state.user = user
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
