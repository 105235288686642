<template>
  <custom-modal
    v-if="value"
    class="create-an-account"
    title="CREATE AN ACCOUNT"
    :visible="value"
    hide-footer
    @change="$emit('input', $event)"
  >
    <div id="createAccountPopup" class="create-account-popup">
      <div class="create-account-container">
        <user-info
          id="mobile"
          @create-account-success="registerSucceeded"
          @create-account-verification-failed="verificationFailed"
        />
      </div>
    </div>
  </custom-modal>
</template>

<script>
import CustomModal from '@/components/common/CustomModal'
import UserInfo from '@/components/account/UserInfo'
import { TRANSFER_CART } from '@/store/mutation-types/orderMutations'

export default {
  name: 'CreateAnAccount',
  components: {
    CustomModal,
    UserInfo,
  },
  props: {
    value: { type: Boolean, default: false },
  },
  methods: {
    registerSucceeded() {
      this.$emit('input', false)
      // if no cart, this call fails but that's okay
      this.$store.dispatch(TRANSFER_CART).finally(() => {
        this.$emit('account-created')
      })
    },
    verificationFailed() {
      this.$emit('input', false)
    },
  },
}
</script>

<style lang="scss">
.create-an-account {
  .create-account-popup {
    min-height: 100%;
    background-color: $catering-bg-grey;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @include media-breakpoint-up(sm) {
      width: 500px;
    }

    .create-account-container {
      height: 100%;
      width: 100%;
      padding: 30px;

      form {
        width: 100%;
      }
    }
  }

  .custom-select {
    background-color: $catering-bg-grey;
  }
}
</style>
