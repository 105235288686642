import { cateringOrderClient } from '@/services/clients'

export default {
  createDeliveryAddress: (request) => {
    return cateringOrderClient.post('/customer/delivery', request)
  },
  updateDeliveryAddress: (request) => {
    return cateringOrderClient.put('/customer/delivery', request)
  },
  deleteDeliveryAddress: (addressId) => {
    return cateringOrderClient.delete(`/customer/delivery/${addressId}`)
  },
  getDeliveryAddresses: () => {
    return cateringOrderClient.get('/customer/delivery')
  },
}
