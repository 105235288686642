import foodGroup from '@/components/builder/FoodGroup'
import readonlyFoodGroup from '@/components/builder/ReadonlyFoodGroup'
import orderInfo from '@/components/builder/OrderInfo'
import orderSummaryBand from '@/components/common/OrderSummaryBand'
import rollingTally from '@/components/builder/RollingTally'
import { SHOW_CONFIRM_DIALOG } from '@/store/mutation-types/confirmDialogMutations'
import { SAVE_ORDER_ITEM } from '@/store/mutation-types/orderMutations'
import { OPEN_BAG } from '@/store/mutation-types/drawersMutations'
import { BuilderInputCountTypes } from '@/constants'
import analyticsService from '@/services/analyticsService'
import { deepClone, interpolate } from '@/utilityFunctions'
import cloneDeep from 'lodash.clonedeep'
import { SET_BUILDER_IS_DIRTY } from '@/store/mutation-types/builderMutations'
import { CONFIRM_UNSAVED_CHANGES } from '@/constants/messages'

export const commonBuilderMixin = {
  components: {
    foodGroup,
    readonlyFoodGroup,
    orderInfo,
    orderSummaryBand,
    rollingTally,
  },
  data() {
    return {
      total: 0,
      isValid: false,
      errorMessage: '',
      showErrors: false,
      numberItemsData: 1,

      trackFoodChange: {
        bases: {
          id: 'bases',
          name: 'Base',
          isDirty: false,
          isValid: false,
          errorMessage: '',
        },
        proteins: {
          id: 'proteins',
          name: 'Protein',
          isDirty: false,
          isValid: false,
          errorMessage: '',
        },
        toppings: {
          id: 'toppings',
          name: 'Toppings/Veggie',
          isDirty: false,
          isValid: false,
          errorMessage: '',
        },
        premiumTopping: {
          id: 'premiumTopping',
          name: 'Premium Topping',
          isDirty: false,
          isValid: false,
          errorMessage: '',
        },
        salsas: {
          id: 'salsas',
          name: 'Salsa',
          isDirty: false,
          isValid: false,
          errorMessage: '',
        },
        tortilla: {
          id: 'tortilla',
          name: 'Tortilla',
          isDirty: false,
          isValid: false,
          errorMessage: '',
        },
      },
    }
  },
  computed: {
    addToBagLabel() {
      if (this.$route.params.orderItemId) {
        return 'Update Bag'
      }

      return 'Add to Bag'
    },
    builderInputCountTypes() {
      return BuilderInputCountTypes
    },
    numberItems: {
      get() {
        return this.numberItemsData
      },
      set(value) {
        this.numberItemsData = value
      },
    },
    basesInfo() {
      if (!this.menuItem) {
        return {}
      }

      return {
        foodInfos: deepClone(this.menuItem.bases),
        max: this.baseMax,
        groupName: this.trackFoodChange.bases.name,
      }
    },
    proteinsInfo() {
      if (!this.menuItem) {
        return {}
      }

      return {
        foodInfos: deepClone(this.menuItem.proteins),
        max: this.proteinMax,
        groupName: this.trackFoodChange.proteins.name,
      }
    },
    toppingsInfo() {
      if (!this.menuItem) {
        return {}
      }

      return {
        foodInfos: deepClone(this.menuItem.toppings),
        max: this.toppingMax,
        groupName: 'Topping',
      }
    },
    premiumToppingInfo() {
      if (!this.menuItem) {
        return {}
      }

      return {
        foodInfos: deepClone(this.menuItem.premiumToppings),
        max: this.premiumToppingMax,
        groupName: this.trackFoodChange.premiumTopping.name,
      }
    },
    salsasInfo() {
      if (!this.menuItem) {
        return {}
      }

      return {
        foodInfos: deepClone(this.menuItem.salsas),
        max: this.salsaMax,
        groupName: this.trackFoodChange.salsas.name,
      }
    },
    tortillaInfo() {
      if (!this.menuItem) {
        return {}
      }

      return {
        foodInfos: deepClone(this.menuItem.tortillas),
        max: this.tortillaMax,
        groupName: this.trackFoodChange.tortilla.name,
      }
    },
    tallySections() {
      const trackerHolder = this.trackFoodChange // to avoid the "Unexpected side effect" error
      if (this.menuItem && this.menuItem.config) {
        trackerHolder.bases.max = this.baseMax
        trackerHolder.proteins.max = this.proteinMax
        trackerHolder.toppings.max = this.toppingMax
        trackerHolder.premiumTopping.max = this.premiumToppingMax
        trackerHolder.salsas.max = this.salsaMax
        trackerHolder.tortilla.max = this.tortillaMax
      }
      return trackerHolder
    },
    baseMax() {
      return this.menuItem.config.baseCount
    },
    proteinMax() {
      return this.menuItem.config.proteinCount
    },
    toppingMax() {
      return this.menuItem.config.toppingCount
    },
    premiumToppingMax() {
      return this.menuItem.config.premiumToppingCount
    },
    salsaMax() {
      return this.menuItem.config.salsaCount
    },
    tortillaMax() {
      return this.menuItem.config.tortillaCount
    },
    menuItem() {
      if (!this.$store.getters.restaurantMenu) {
        return null
      }

      const menuTypeId = this.menuType
      const possibleItem = this.$store.getters.restaurantMenu.filter(function(
        item,
      ) {
        return item.id === menuTypeId
      })
      return possibleItem.length > 0
        ? possibleItem[0]
        : { config: {}, itemConfig: {} }
    },
    menuItemPrice() {
      if (this.menuItem) {
        return this.menuItem.price
          ? this.menuItem.price
          : this.menuItem.minPrice
      } else {
        return 0
      }
    },
    menuType() {
      return this.$route.params.menuType
    },
    fillingsFoodInfos() {
      if (
        !this.menuItem ||
        !this.menuItem.config ||
        !this.menuItem.config.readonly
      ) {
        return {}
      } else {
        return this.menuItem.config.readonly.fillings
      }
    },
    sidesFoodInfos() {
      if (
        !this.menuItem ||
        !this.menuItem.config ||
        !this.menuItem.config.readonly
      ) {
        return {}
      } else {
        return this.menuItem.config.readonly.sides
      }
    },
    salsasFoodInfos() {
      if (
        !this.menuItem ||
        !this.menuItem.config ||
        !this.menuItem.config.readonly
      ) {
        return {}
      } else {
        return this.menuItem.config.readonly.salsas
      }
    },
    includedItems() {
      let included = ''

      const hasFillings =
        this.fillingsFoodInfos && this.fillingsFoodInfos.length !== undefined
      const hasSides =
        this.sidesFoodInfos && this.sidesFoodInfos.length !== undefined
      const hasSalsas =
        this.salsasFoodInfos && this.salsasFoodInfos.length !== undefined

      if (hasFillings) {
        for (let index = 0; index < this.fillingsFoodInfos.length; index++) {
          if (
            included !== '' &&
            !hasSides &&
            !hasSalsas &&
            index === this.fillingsFoodInfos.length - 1
          ) {
            included += ' and '
          } else if (included !== '') {
            included += ', '
          }
          included += this.fillingsFoodInfos[index].name
        }
      }

      if (hasSides) {
        for (let index = 0; index < this.sidesFoodInfos.length; index++) {
          if (
            included !== '' &&
            !hasSalsas &&
            index === this.sidesFoodInfos.length - 1
          ) {
            included += ' and '
          } else if (included !== '') {
            included += ', '
          }
          included += this.sidesFoodInfos[index].name
        }
      }

      if (hasSalsas) {
        for (let index = 0; index < this.salsasFoodInfos.length; index++) {
          if (included !== '' && index === this.salsasFoodInfos.length - 1) {
            included += ' and '
          } else if (included !== '') {
            included += ', '
          }
          included += this.salsasFoodInfos[index].name
        }
      }

      return included
    },
    orderItemId() {
      return this.$route.params.orderItemId
    },
    orderItem() {
      if (
        !this.orderItemId ||
        !this.$store.getters.currentOrder ||
        !this.$store.getters.currentOrder.orderDetail ||
        !this.$store.getters.currentOrder.orderDetail.orderItems
      ) {
        return null
      }

      const that = this
      const possibleItem = this.$store.getters.currentOrder.orderDetail.orderItems.filter(
        function(item) {
          return item.orderItemId === that.orderItemId
        },
      )

      if (possibleItem.length === 0) {
        return null
      }

      return possibleItem[0]
    },
    shouldShowCloseWithoutUpdating() {
      // do not show the Close without updating button if we do not
      // have an action to redirect to in this.route()
      const route = this.$store.state.route
      return !!(route.params.orderItemId && route.query.action)
    },
    loadBuilder() {
      // coerce truthy value in order to return a bool
      return !!(
        this.menuItem &&
        this.$store.getters.currentOrder &&
        this.$store.getters.currentOrder.orderNumber
      )
    },
    displayName() {
      return this.menuItem.formatDisplayName
        ? interpolate(this.menuItem.formatDisplayName, {
            item: { quantity: this.numberItems, price: this.menuItemPrice },
            menu: this.menuItem,
          })
        : this.menuItem.displayName
    },
    servesText() {
      return interpolate(this.menuItem.formatServesText, {
        item: { quantity: this.numberItems, price: this.menuItemPrice },
        menu: this.menuItem,
      })
    },
    isDirty: {
      get() {
        return this.$store.getters.isBuilderDirty
      },
      set(value) {
        this.$store.dispatch(SET_BUILDER_IS_DIRTY, value)
      },
    },
  },
  created() {
    this.isDirty = false
    if (this.orderItem) {
      this.numberItems = this.orderItem.quantity
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isDirty || to.query.signedOut) {
      next()
      return
    }
    this.$store.dispatch(SHOW_CONFIRM_DIALOG, {
      text: CONFIRM_UNSAVED_CHANGES,
      okCallback: next,
      cancelCallback: () => next(false),
    })
  },
  methods: {
    createOrderItems() {
      /* this method should be overwritten in a builder
       * to create the subitems for the order
       * returns an array of items in the expected form
       */
      return []
    },
    createOrderItemToSave() {
      const orderToSave = this.orderItem
        ? cloneDeep(this.orderItem)
        : {
            name: this.menuItem.name,
            isOrderAmountFromSubItems: this.menuItem.isAmountFromSubItems,
            createdTimestamp: new Date().toISOString(),
            menuId: this.menuType,
            price: this.menuItemPrice,
            subTotal: this.total,
            tax: 0,
            total: this.total,
            displaySubName: this.menuItem.displaySubName,
          }

      orderToSave.subItems = this.createOrderItems()
      orderToSave.quantity = this.numberItems
      /**
       * TODO: Revisit whether this data should be stamped on the order or not...
       * this is inconsistent with COM behavior which isn't aware of displayName and servesText
       */

      // Set display properties used on emails, checkout, and confirmation
      // these values change based on quantities selected.
      orderToSave.displayName = this.displayName
      orderToSave.servesText = this.servesText

      return orderToSave
    },
    initializeFood(subItem, menuItems, foodGroupRef) {
      const hasMatch = menuItems.filter(function(foodItem) {
        return foodItem.id === subItem.menuId
      })
      if (hasMatch.length > 0) {
        foodGroupRef.inputChange(subItem.menuId, subItem.quantity, true)
      }
    },
    updateTotal(newTotal) {
      this.total = newTotal
      this.afterUpdateTotal(newTotal)
    },
    afterUpdateTotal(newTotal) {},
    updateFoodChange(eventArgs) {
      this.trackFoodChange[eventArgs.id] = {
        ...this.trackFoodChange[eventArgs.id],
        ...eventArgs,
      }

      this.afterUpdateFoodChange(eventArgs)

      this.updateIsDirty()
      this.updateIsValid()
      this.updateErrorMessage()
    },
    afterUpdateFoodChange(eventArgs) {},
    updateIsDirty() {
      let isDirty = false
      const keys = Object.keys(this.trackFoodChange)
      for (let index = 0; index < keys.length; index++) {
        isDirty = isDirty || this.trackFoodChange[keys[index]].isDirty
      }

      this.isDirty = isDirty
    },
    updateIsValid() {
      let isValid = true
      const keys = Object.keys(this.trackFoodChange)
      for (let index = 0; index < keys.length; index++) {
        isValid = isValid && this.trackFoodChange[keys[index]].isValid
      }

      this.isValid = isValid
    },
    updateErrorMessage() {
      let errorMessage = ''
      const keys = Object.keys(this.trackFoodChange)
      for (let index = 0; index < keys.length; index++) {
        if (this.trackFoodChange[keys[index]].errorMessage) {
          if (errorMessage !== '') {
            errorMessage += ', '
          }
          errorMessage += this.trackFoodChange[keys[index]].errorMessage
        }
      }

      const pleaseSelect = errorMessage.length ? 'Please select ' : ''
      this.errorMessage = pleaseSelect + errorMessage.toLowerCase()
    },
    addToBag() {
      if (this.$refs.bases) {
        this.$refs.bases.showErrors()
      }
      if (this.$refs.proteins) {
        this.$refs.proteins.showErrors()
      }
      if (this.$refs.toppings) {
        this.$refs.toppings.showErrors()
      }
      if (this.$refs.premiumTopping) {
        this.$refs.premiumTopping.showErrors()
      }
      if (this.$refs.salsas) {
        this.$refs.salsas.showErrors()
      }
      if (this.$refs.tortilla) {
        this.$refs.tortilla.showErrors()
      }
      if (this.isValid) {
        this.showErrors = false
        this.isDirty = false
        this.$store
          .dispatch(SAVE_ORDER_ITEM, this.createOrderItemToSave())
          .then(() => {
            // if the route does not yet contain the orderItemId,
            // replace the route so that the back button and redirects
            // will properly return to the saved menuItem instead of
            // trying to generate a new one.
            const routeOrderItemId = this.$store.state.route.params.orderItemId

            if (!routeOrderItemId) {
              const orderItems = this.$store.getters.currentOrder.orderDetail
                .orderItems
              let orderItem

              for (let i = 0; i < orderItems.length; i++) {
                if (orderItems[i].menuId === this.menuItem.id) {
                  orderItem = orderItems[i]
                  break
                }
              }

              // if we somehow do not have a matching orderItem
              // or orderItem.orderItemId, we cannot replace the route
              if (orderItem && orderItem.orderItemId) {
                const route = { ...this.$store.state.route }
                route.params.orderItemId = orderItem.orderItemId
                this.$router.replace(route)
              }
            }

            this.route()

            this.$store.dispatch(OPEN_BAG)
          })
          .catch((e) => {
            this.$store.dispatch(SHOW_CONFIRM_DIALOG, {
              text: 'We encountered a problem.',
              subText: 'Please try again or contact customer support.',
              hideCancel: true,
            })
          })
      } else {
        this.showErrors = true

        const invalidFoodGroups = []
        Object.keys(this.trackFoodChange).forEach((foodGroup) => {
          if (!this.trackFoodChange[foodGroup].isValid) {
            invalidFoodGroups.push(this.trackFoodChange[foodGroup].name)
          }
        })

        analyticsService.builderQuantityErrors(invalidFoodGroups)
      }
    },
    closeWithoutSaving() {
      this.route()
    },
    navigateAway(routeName) {
      const { name, params } = this.$store.state.route
      this.$router.push({
        name: routeName,
        params: {
          menuType: this.item.menuId,
          orderItemId: this.item.orderItemId,
        },
        query: {
          action: name,
          orderItemId: params.orderItemId,
          returnMenuType: params.menuType,
        },
      })
    },
    editItemFromBag(routeName) {
      if (!this.item.popularBuildItemId) {
        this.navigateAway(routeName)
        return
      }
      this.$store.dispatch(SHOW_CONFIRM_DIALOG, {
        headerText: 'HEADS UP',
        text: `We curated this popular build to be a ${this.item.displayName}.  If you make changes, it will be your own.`,
        okText: 'CONTINUE',
        okCallback: () => {
          analyticsService.editPopularBuildInBag(this.item.displayName)
          this.navigateAway(routeName)
        },
      })
    },
    numberPeopleChange(eventArgs) {
      this.numberItems = eventArgs
    },
    addToArray(subItems, newItems) {
      for (let index = 0; index < newItems.length; index++) {
        subItems.push(newItems[index])
      }
    },
    route() {
      const routeName = this.$store.state.route.query.action
      if (!routeName) {
        return
      }

      // if we are routing back to a different builder, use that menuType value instead.
      const query = this.$store.state.route.query
      const menuType =
        query.returnMenuType && query.returnMenuType.length
          ? query.returnMenuType
          : this.menuType

      // if orderItemId is populated on the querystring, we are redirecting back to an existing builder.
      const orderItemId = query.orderItemId ? query.orderItemId : undefined

      const routeInfo = { name: routeName, params: { menuType, orderItemId } }
      if (this.$store.state.route.query.hash) {
        routeInfo.hash = '#' + this.$store.state.route.query.hash
      }
      this.$router.push(routeInfo)
    },
    selectedSubItems(item, menuItem, category, includeCount = false) {
      const submenus = menuItem[category]
      const descriptions = []
      if (submenus && item.subItems) {
        item.subItems.forEach((si) => {
          if (si.quantity <= 0) {
            return
          }
          const menu = submenus.filter((x) => {
            return x.id === si.menuId
          })
          if (menu && menu.length > 0) {
            if (includeCount) {
              descriptions.push(si.quantity + ' ' + menu[0].name)
            } else {
              descriptions.push(menu[0].name)
            }
          }
        })
      }

      return descriptions.join(', ')
    },
  },
}
