import {
  CREATE_ACCOUNT_VERIFICATION_INITIALIZE,
  UPDATE_ACCOUNT_VERIFICATION_INITIALIZE,
  LOGIN_ACCOUNT_VERIFICATION_INITIALIZE,
  VERIFY_ACCOUNT_TOKEN,
  CANCEL_ACCOUNT_VERIFICATION,
  RESEND_VERIFICATION,
  VERIFY_ACCOUNT_PASSWORD,
  SEND_PHONE_VERIFICATION_CODE,
  SET_RESEND_VERIFICATION_SUCCESS,
  SET_ACCOUNT_VERIFICATION_ERROR_CODE,
  SET_ACCOUNT_VERIFICATION_ERROR_IS_FINAL_ATTEMPT,
  SET_ACCOUNT_VERIFICATION_SUCCESS,
  SET_ACCOUNT_VERIFICATION_FAILED,
  SET_ACCOUNT_VERIFICATION_CANCELLED,
  SET_PHONE_CONFIRMATION_SUCCESS,
  SET_VERIFY_ACCOUNT_PASSWORD_SUCCESS,
} from '@/store/mutation-types/accountVerificationMutations'
import {
  SHOW_LOADER,
  CLEAR_LOADER,
} from '@/store/mutation-types/loaderMutations'
import { UPDATE_USER } from '@/store/mutation-types/userMutations'
import customerauth from '@/services/customerAuthService'
import {
  ERROR_VERIFICATION_REGISTRATION_NEEDED,
  ERROR_VERIFICATION_LOGIN_NEEDED,
  ERROR_VERIFICATION_ACCOUNT_LOCKED,
  ERROR_VERIFICATION_UPDATE_EMAIL_NEEDED,
  ERROR_VERIFICATION_UPDATE_PHONE_NEEDED,
} from '@/constants/errorCodes'
import * as verificationErrorTypes from '@/constants/accountVerificationErrorTypes'
import * as verificationSteps from '@/constants/accountVerificationSteps'
import analyticsService from '@/services/analyticsService'

const state = resetState({})

export const ACCOUNT_CREATION_CONTEXT = 'ACCOUNT_CREATION'
export const ACCOUNT_UPDATE_CONTEXT = 'ACCOUNT_UPDATE'
export const ACCOUNT_LOGIN_CONTEXT = 'ACCOUNT_LOGIN'

function resetState(stateParam) {
  stateParam.context = ''
  stateParam.phoneNumber = ''
  stateParam.overridePhoneNumber = ''
  stateParam.email = ''
  stateParam.isVerificationInProgress = false
  stateParam.verificationErrorIsFinalAttempt = false
  stateParam.verificationErrorCode = ''
  stateParam.loaderName = 'accountVerificationLoader'
  stateParam.verificationStep = verificationSteps.PHONE_VERIFICATION
  stateParam.userPassword = ''
  stateParam.verificationSuccessCallback = null
  stateParam.verificationFailureCallback = null
  return stateParam
}

const getters = {
  accountVerificationPhoneNumber: (state) => state.phoneNumber,
  accountVerificationErrorIsFinalAttempt: (state) =>
    state.verificationErrorIsFinalAttempt,
  isVerificationInProgress: (state) => state.isVerificationInProgress,
  verificationStep: (state) => state.verificationStep,
  isPhoneVerificationStep: (state) =>
    state.verificationStep === verificationSteps.PHONE_VERIFICATION,
  isPasswordVerificationStep: (state) =>
    state.verificationStep === verificationSteps.PASSWORD_VERIFICATION,
}

const actions = {
  [CREATE_ACCOUNT_VERIFICATION_INITIALIZE](storeOptions, payload) {
    return initializeAccountVerification(
      storeOptions,
      payload,
      CREATE_ACCOUNT_VERIFICATION_INITIALIZE,
    )
  },
  [UPDATE_ACCOUNT_VERIFICATION_INITIALIZE](
    storeOptions,
    { phoneNumber, errorCode },
  ) {
    const { email } = storeOptions.getters.currentUser
    return initializeAccountVerification(
      storeOptions,
      { phoneNumber, email, errorCode },
      UPDATE_ACCOUNT_VERIFICATION_INITIALIZE,
    )
  },
  [LOGIN_ACCOUNT_VERIFICATION_INITIALIZE](storeOptions, { email, error }) {
    return initializeAccountVerification(
      storeOptions,
      { phoneNumber: error.phoneLastFour, email, errorCode: error.errorCode },
      LOGIN_ACCOUNT_VERIFICATION_INITIALIZE,
    )
  },
  [RESEND_VERIFICATION]({ commit, state, dispatch }) {
    dispatch(SHOW_LOADER, {
      name: state.loaderName,
      mainText: 'Resending Verification Code',
      subText: '',
      includeOverlay: true,
    })

    return customerauth
      .verifyInitialize({
        email: state.email,
        password: state.userPassword,
        phoneNumber: state.overridePhoneNumber,
      })
      .then(() => {
        commit(SET_RESEND_VERIFICATION_SUCCESS)
      })
      .catch((e) => {
        commit(SET_ACCOUNT_VERIFICATION_ERROR_CODE, e.errorCode)
        throw e
      })
      .finally(() => {
        dispatch(CLEAR_LOADER, { name: state.loaderName })
      })
  },
  [VERIFY_ACCOUNT_TOKEN]({ commit, state, dispatch, rootState }, token) {
    dispatch(SHOW_LOADER, {
      name: state.loaderName,
      mainText: 'Verifying Code',
      subText: '',
      includeOverlay: true,
    })

    return customerauth
      .verifyFinalize({
        code: token,
        email: state.email,
        phoneNumber: state.overridePhoneNumber,
      })
      .then((response) => {
        const { title } = rootState.route.meta
        if (state.context === ACCOUNT_CREATION_CONTEXT) {
          commit(UPDATE_USER, response.data)
          analyticsService.createAccountSuccess(title)
        } else if (state.context === ACCOUNT_LOGIN_CONTEXT) {
          commit(UPDATE_USER, response.data)
          analyticsService.userLoginSuccess(title)
        }

        analyticsService.twoStepVerificationCompleted()
        commit(SET_ACCOUNT_VERIFICATION_SUCCESS)
      })
      .catch((e) => {
        commit(SET_ACCOUNT_VERIFICATION_ERROR_CODE, e.errorCode)
        if (
          e.errorCount &&
          e.errorMaxTries &&
          e.errorCount === e.errorMaxTries - 1
        ) {
          commit(SET_ACCOUNT_VERIFICATION_ERROR_IS_FINAL_ATTEMPT)
        }
        if (state.verificationErrorCode === ERROR_VERIFICATION_ACCOUNT_LOCKED) {
          analyticsService.twoStepVerificationLockedOut()
        }

        throw e
      })
      .finally(() => {
        dispatch(CLEAR_LOADER, { name: state.loaderName })
      })
  },
  [VERIFY_ACCOUNT_PASSWORD]({ commit, state, dispatch }, password) {
    dispatch(SHOW_LOADER, {
      name: state.loaderName,
      mainText: 'Verifying Password',
      subText: '',
      includeOverlay: true,
    })
    return customerauth
      .verifyInitialize({
        email: state.email,
        password,
      })
      .then(() => {
        commit(SET_VERIFY_ACCOUNT_PASSWORD_SUCCESS, password)
      })
      .catch((e) => {
        commit(SET_ACCOUNT_VERIFICATION_ERROR_CODE, e.errorCode)
        throw e
      })
      .finally(() => {
        dispatch(CLEAR_LOADER, { name: state.loaderName })
      })
  },
  [SEND_PHONE_VERIFICATION_CODE]({ commit, state, dispatch }, phoneNumber) {
    dispatch(SHOW_LOADER, {
      name: state.loaderName,
      mainText: 'Sending Verification Code',
      subText: '',
      includeOverlay: true,
    })
    return customerauth
      .verifyInitialize({
        email: state.email,
        phoneNumber,
      })
      .then(() => {
        commit(SET_PHONE_CONFIRMATION_SUCCESS, phoneNumber)
      })
      .catch((e) => {
        commit(SET_ACCOUNT_VERIFICATION_ERROR_CODE, e.errorCode)
        throw e
      })
      .finally(() => {
        dispatch(CLEAR_LOADER, { name: state.loaderName })
      })
  },
  [CANCEL_ACCOUNT_VERIFICATION]({ state, commit }) {
    if (state.verificationErrorCode === ERROR_VERIFICATION_ACCOUNT_LOCKED) {
      commit(SET_ACCOUNT_VERIFICATION_FAILED)
    } else {
      commit(SET_ACCOUNT_VERIFICATION_CANCELLED)
    }
  },
}

const mutations = {
  [CREATE_ACCOUNT_VERIFICATION_INITIALIZE](state, payload) {
    setAccountVerificationInitializeState(
      state,
      payload,
      ACCOUNT_CREATION_CONTEXT,
    )
  },
  [UPDATE_ACCOUNT_VERIFICATION_INITIALIZE](state, payload) {
    setAccountVerificationInitializeState(
      state,
      payload,
      ACCOUNT_UPDATE_CONTEXT,
    )
  },
  [LOGIN_ACCOUNT_VERIFICATION_INITIALIZE](state, payload) {
    setAccountVerificationInitializeState(state, payload, ACCOUNT_LOGIN_CONTEXT)
  },
  [SET_RESEND_VERIFICATION_SUCCESS](state) {
    state.isVerificationInProgress = true
    state.verificationErrorCode = ''
  },
  [SET_ACCOUNT_VERIFICATION_SUCCESS](state) {
    state.verificationSuccessCallback()
    resetState(state)
  },
  [SET_ACCOUNT_VERIFICATION_CANCELLED](state) {
    state.verificationFailureCallback(
      new Error(verificationErrorTypes.ACCOUNT_VERIFICATION_CANCELLED),
    )
    resetState(state)
  },
  [SET_ACCOUNT_VERIFICATION_FAILED](state) {
    state.verificationFailureCallback(
      new Error(verificationErrorTypes.ACCOUNT_VERIFICATION_FAILED),
    )
    resetState(state)
  },
  [SET_ACCOUNT_VERIFICATION_ERROR_CODE](state, verificationErrorCode) {
    state.verificationErrorCode = verificationErrorCode
  },
  [SET_ACCOUNT_VERIFICATION_ERROR_IS_FINAL_ATTEMPT](state) {
    state.verificationErrorIsFinalAttempt = true
  },
  [SET_VERIFY_ACCOUNT_PASSWORD_SUCCESS](state, password) {
    state.userPassword = password
    state.verificationStep = verificationSteps.PHONE_VERIFICATION
  },
  [SET_PHONE_CONFIRMATION_SUCCESS](state, phoneNumber) {
    if (phoneNumber) {
      state.phoneNumber = phoneNumber
      state.overridePhoneNumber = phoneNumber
    }
    state.verificationStep = verificationSteps.PHONE_VERIFICATION
  },
}

function initializeAccountVerification(
  { commit, dispatch },
  { phoneNumber, email, errorCode },
  successMutationName,
) {
  return new Promise((resolve, reject) => {
    if (
      successMutationName === LOGIN_ACCOUNT_VERIFICATION_INITIALIZE &&
      errorCode === ERROR_VERIFICATION_REGISTRATION_NEEDED
    ) {
      analyticsService.twoStepVerificationInitialized()
      return commit(successMutationName, {
        phoneNumber,
        email,
        verificationStep: verificationSteps.PHONE_CONFIRMATION,
        successCallback: resolve,
        failureCallback: reject,
      })
    }

    if (
      errorCode === ERROR_VERIFICATION_LOGIN_NEEDED ||
      errorCode === ERROR_VERIFICATION_UPDATE_EMAIL_NEEDED ||
      errorCode === ERROR_VERIFICATION_REGISTRATION_NEEDED
    ) {
      dispatch(SHOW_LOADER, {
        name: state.loaderName,
        mainText: 'Initializing Verification',
        subText: '',
        includeOverlay: true,
      })
      customerauth
        .verifyInitialize({
          email,
        })
        .then(() => {
          analyticsService.twoStepVerificationInitialized()
          commit(successMutationName, {
            phoneNumber,
            email,
            successCallback: resolve,
            failureCallback: reject,
          })
        })
        .catch((e) => {
          commit(SET_ACCOUNT_VERIFICATION_ERROR_CODE, e.errorCode)
          reject(
            new Error(
              verificationErrorTypes.ACCOUNT_VERIFICATION_INITIALIZATION_FAILED,
            ),
          )
        })
        .finally(() => {
          dispatch(CLEAR_LOADER, { name: state.loaderName })
        })
    } else if (errorCode === ERROR_VERIFICATION_UPDATE_PHONE_NEEDED) {
      analyticsService.twoStepVerificationInitialized()
      commit(successMutationName, {
        phoneNumber,
        email,
        verificationStep: verificationSteps.PASSWORD_VERIFICATION,
        successCallback: resolve,
        failureCallback: reject,
      })
    } else if (errorCode === ERROR_VERIFICATION_ACCOUNT_LOCKED) {
      reject(new Error(verificationErrorTypes.ACCOUNT_VERIFICATION_FAILED))
    } else {
      reject(
        new Error(verificationErrorTypes.ACCOUNT_VERIFICATION_UNKNOWN_ERROR),
      )
    }
  })
}

function setAccountVerificationInitializeState(
  state,
  {
    phoneNumber,
    email,
    verificationStep = verificationSteps.PHONE_VERIFICATION,
    successCallback,
    failureCallback,
  },
  context,
) {
  state.phoneNumber = phoneNumber
  state.email = email
  state.isVerificationInProgress = true
  state.verificationErrorCode = ''
  state.verificationErrorIsFinalAttempt = false
  state.context = context
  state.verificationStep = verificationStep
  state.userPassword = ''
  state.verificationSuccessCallback = successCallback
  state.verificationFailureCallback = failureCallback
}

export default {
  state,
  getters,
  actions,
  mutations,
}
