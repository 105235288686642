import { cateringOrderClient } from '@/services/clients'

export default {
  getCart() {
    return cateringOrderClient.get('/order/cart')
  },
  getOrder(orderId) {
    return cateringOrderClient.get(`/order/${orderId}`)
  },
  createOrder(order) {
    return cateringOrderClient.post('/order', order)
  },
  updateOrder(order) {
    return cateringOrderClient.put('/order', order)
  },
  validateOrder(orderId) {
    return cateringOrderClient.get(`/order/${orderId}/validate`, undefined, {
      callerHandlesCriticalError: true,
    })
  },
  transferOrder(orderId) {
    return cateringOrderClient.put(`/order/${orderId}/transfer`)
  },
  reorder(orderId, reorderId) {
    return cateringOrderClient.post(`/order/${orderId}/reorder/${reorderId}`)
  },
  addPopularBuildToOrder(orderId, popularBuildId, headcount) {
    return cateringOrderClient.post(
      `/order/${orderId}/popularbuilds/${popularBuildId}`,
      {
        headcount,
      },
    )
  },
  createOrderItem(orderId, orderItem) {
    return cateringOrderClient.post(`/order/${orderId}/item`, orderItem)
  },
  updateOrderItem(orderId, orderItem) {
    return cateringOrderClient.put(`/order/${orderId}/item`, orderItem)
  },
  deleteOrderItem(orderId, orderItemId) {
    return cateringOrderClient.delete(`/order/${orderId}/item/${orderItemId}`)
  },
  submitOrder(orderId, paymentInfo) {
    return cateringOrderClient.put(`/order/${orderId}/submit`, paymentInfo)
  },
  cancelOrder(orderId) {
    return cateringOrderClient.delete(`/order/${orderId}`)
  },
  getUserOrderHistory() {
    return cateringOrderClient.get('/order/userhistory')
  },
  saveFavoriteOrder({ orderId, favoriteName }) {
    return cateringOrderClient.put(`/order/${orderId}/favorite`, {
      favoriteName,
    })
  },
  removeFavoriteOrder(orderId) {
    return cateringOrderClient.delete(`/order/${orderId}/favorite`)
  },
  isChangeable(orderId) {
    return cateringOrderClient.get(
      `/order/${orderId}/isChangeable`,
      undefined,
      {
        callerHandlesOrderNotEditable: true,
      },
    )
  },
  markSubmittedOrderForUpdate(orderId) {
    return cateringOrderClient.put(`/order/${orderId}/markforupdate`)
  },
  undoSubmittedOrderForUpdate() {
    return cateringOrderClient.put('/order/undoforupdate')
  },
  applyOrderPromotion(orderId, promoCode) {
    return cateringOrderClient.post(`order/${orderId}/promotions/${promoCode}`)
  },
  removeOrderPromotion(orderId, promoCode) {
    return cateringOrderClient.delete(
      `order/${orderId}/promotions/${promoCode}`,
    )
  },
  validateAllOrderPromotions(orderId) {
    return cateringOrderClient.get(`order/${orderId}/promotions/validate`)
  },
}
