<template>
  <custom-modal hide-header no-close-on-backdrop :visible="isVisible">
    <div class="m-5">
      <h4 class="text-center m-3">WELCOME BACK</h4>
      <p id="label-select-country">
        Please help us complete your account by selecting your country.
      </p>
      <div role="radiogroup" aria-labelledby="label-select-country">
        <div v-for="(country, i) in countryCodes" :key="country.value">
          <hr v-visible="i > 0" />
          <div
            class="
              clickable
              d-flex
              flex-row
              align-items-center
              justify-content-between
            "
            tabindex="0"
            role="radio"
            :aria-checked="isCountrySelected(country.value)"
            @keydown.enter="selectCountry(country.value)"
            @click="selectCountry(country.value)"
          >
            <div>
              <img
                :src="getFlagImageUrl(country.value)"
                class="flag mr-3"
                :alt="`${country.value} flag`"
              />
              <span class="country-text font-weight-bold">
                {{ country.text }}
              </span>
            </div>
            <checkmark-toggle-svg
              class="d-inline-block"
              :is-checked="isCountrySelected(country.value)"
              :size="30"
            />
          </div>
        </div>
      </div>
    </div>
    <div slot="modal-footer" class="w-100">
      <b-button
        variant="primary"
        block
        size="md"
        class="update-user-btn"
        @click="updateCountry"
      >
        SAVE
      </b-button>
    </div>
  </custom-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { UPDATE_CUSTOMER_PROFILE } from '@/store/mutation-types/userMutations'
import CustomModal from '@/components/common/CustomModal'
import CheckmarkToggleSvg from '@/components/common/CheckmarkToggleSvg'

const flagUrlsByCountryCode = {
  US: require('@/static/img/flags/US.png'),
  CA: require('@/static/img/flags/CA.png'),
}

export default {
  name: 'UpdateCountryModal',
  components: {
    CheckmarkToggleSvg,
    CustomModal,
  },
  data() {
    return {
      selectedCountry: null,
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'isFullUser', 'countryCodes']),
    isVisible() {
      return this.isFullUser && !this.currentUser.country
    },
  },
  methods: {
    ...mapActions({
      updateCustomer: UPDATE_CUSTOMER_PROFILE,
    }),
    isCountrySelected(value) {
      return this.selectedCountry === value
    },
    selectCountry(value) {
      this.selectedCountry = value
    },
    updateCountry() {
      if (this.selectedCountry) {
        this.updateCustomer({
          ...this.currentUser,
          country: this.selectedCountry,
        })
      }
    },
    getFlagImageUrl(countryCode) {
      return flagUrlsByCountryCode[countryCode]
    },
  },
}
</script>

<style lang="scss" scoped>
p {
  color: $catering-grey-brown;
}

.country-text {
  color: $catering-chocolate;
}

.flag {
  height: 32px;
  width: 32px;
}
</style>
