function getDefaultCountryCodes() {
  return [
    { text: 'United States', value: 'US' },
    { text: 'Canada', value: 'CA' },
  ]
}

const state = {
  countryCodes: getDefaultCountryCodes(),
}

const getters = {
  countryCodes: (state) => state.countryCodes,
}

const actions = {}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}
