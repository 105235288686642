import { HomeMenuIds } from '@/constants'

export const eventCategories = {
  CATERING_BUILDER: 'catering-mealbuilder',
  CATERING_INTAKE: 'catering-intake',
  CATERING_POPULAR_BUILD: 'catering-popular-build',
  CATERING_PRICING: 'catering-pricing',
  CATERING_PROMO: 'catering-promo',
  CATERING_REORDER: 'catering-reorder',
  CUSTOMER: 'customer',
  DELIVERY: 'delivery',
  PICKUP: 'pickup',
  REGISTER: 'register',
  TWO_STEP_VERIFICATION: '2sv',
}

export const eventActions = {
  ADD_TO_BAG: 'add-to-bag',
  APPLY_PROMO: 'apply-promo',
  BULIDER_QUANTITY_ERROR: 'quantity-error',
  CREATE_ACCOUNT_FAILURE: 'failure',
  CREATE_ACCOUNT_FORM_ERROR: 'form-error',
  CREATE_ACCOUNT_SUCCESS: 'success',
  DELIVERY_ADDRESS_UNAVAILABLE: 'delivery-address-unavailable',
  DELIVERY_DATETIME_SUCCESS: 'delivery-date-success',
  DELIVERY_DATETIME_UNAVAILABLE: 'delivery-date-time-unavailable',
  EDIT_BUILD_IN_BAG: 'edit-build-in-bag',
  FULL_USER_CHECKOUT_SUCCESS: 'account-checkout-success',
  GENERAL_PRICING_DISPLAYED: 'general-pricing-displayed',
  GUEST_CHECKOUT_SUCCESS: 'guest-checkout-success',
  PICKUP_DATETIME_SUCCESS: 'pickup-date-success',
  PICKUP_DATETIME_UNAVAILABLE: 'pickup-date-time-unavailable',
  REORDER_FAVORITES_CLICK: 'select-reorder-favorites',
  REORDER_RECENTS_CLICK: 'select-reorder-recents',
  SAVE_PAYMENT_CARD_SUCCESS: 'save-paymentcard',
  SAVE_EVENT_TYPE_SUCCESS: 'save-event-type',
  SUBMIT: 'submit',
  USER_LOGIN_SUCCESS: 'sign-in',
  BEGIN: 'begin',
  COMPLETE: 'complete',
  LOCKED_OUT: 'lockout',
}

export const eventLabels = {
  ADDRESS_NOT_VALID: 'address-not-valid',
  ADDRESS_OUT_OF_RANGE: 'address-out-of-range',
  PROMO_APPLY_CLICKED: 'promo-apply-button-is-clicked',
  PROMO_APPLIED_SUCCESSFULLY: 'promo-applied-successfully',
  REORDER_FAVORITES_BUTTON_DESCRIPTION:
    'reorder-button-on-favorites-order-is-clicked',
  REORDER_RECENTS_BUTTON_DESCRIPTION:
    'reorder-button-on-recents-order-is-clicked',
  SUBMIT_PROMO_ORDER: 'submit-promo-order',
  SUCCESS: 'success',
}

function customEvent(eventCategory, eventAction, eventLabel) {
  window.gtag &&
    window.gtag('event', eventAction, {
      event_category: eventCategory,
      event_label: eventLabel,
    })
}

export default {
  pageView(path, title) {
    window.gtag &&
      window.gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_ID, {
        page_title: title,
        page_path: path,
        custom_map: {
          dimension1: 'order_type',
        },
      })
  },
  ecommercePurchaseSuccess(order) {
    window.gtag &&
      window.gtag('event', 'purchase', {
        transaction_id: order.orderNumber,
        affiliation: order.isGuest ? 'guest' : 'checkout',
        order_type: order.orderDetail.isDelivery ? 'delivery' : 'pickup',
        value: order.orderDetail.total,
        tax: order.orderDetail.taxTotal,
        items: getPurchasedItems(),
      })

    function getPurchasedItems() {
      return order.orderDetail.orderItems.map((item) => {
        return {
          id: item.menuId,
          name: getName(item),
          brand: '',
          category: '',
          quantity: item.quantity,
          price: getPrice(item),
        }
      })
    }

    function getPrice(item) {
      let price = item.price
      if (item.isOrderAmountFromSubItems) {
        // only proteins are known to have price in sub items
        const subItems = item.subItems.filter((si) => si.price > 0)
        price = subItems.length > 0 ? subItems[0].price : 0
      }
      return price
    }

    function getName(item) {
      const isBYO = HomeMenuIds.BuildYourOwnIds.some((id) => id === item.menuId)
      return isBYO ? item.displaySubName : item.displayName
    }
  },
  // custom events
  addPopularBuildToBag(popularBuildName) {
    customEvent(
      eventCategories.CATERING_POPULAR_BUILD,
      eventActions.ADD_TO_BAG,
      popularBuildName,
    )
  },
  applyPromotionClick() {
    customEvent(
      eventCategories.CATERING_PROMO,
      eventActions.APPLY_PROMO,
      eventLabels.PROMO_APPLY_CLICKED,
    )
  },
  applyPromotionSuccess() {
    customEvent(
      eventCategories.CATERING_PROMO,
      eventActions.APPLY_PROMO,
      eventLabels.PROMO_APPLIED_SUCCESSFULLY,
    )
  },
  builderQuantityErrors(invalidFoodGroups = []) {
    invalidFoodGroups.forEach((foodGroup) => {
      customEvent(
        eventCategories.CATERING_BUILDER,
        eventActions.BULIDER_QUANTITY_ERROR,
        foodGroup,
      )
    })
  },
  checkoutOrderWithPromotion() {
    customEvent(
      eventCategories.CATERING_PROMO,
      eventActions.SUBMIT,
      eventLabels.SUBMIT_PROMO_ORDER,
    )
  },
  checkoutSuccessUserType(isFullUser) {
    const eventAction = isFullUser
      ? eventActions.FULL_USER_CHECKOUT_SUCCESS
      : eventActions.GUEST_CHECKOUT_SUCCESS
    customEvent(eventCategories.CUSTOMER, eventAction)
  },
  checkoutSuccessOrderType(isDelivery) {
    const eventCategory = isDelivery
      ? eventCategories.DELIVERY
      : eventCategories.PICKUP
    customEvent(eventCategory, eventActions.SUBMIT, eventLabels.SUCCESS)
  },
  createAccountFailure() {
    customEvent(eventCategories.REGISTER, eventActions.CREATE_ACCOUNT_FAILURE)
  },
  createAccountFieldErrors(errorFields) {
    errorFields.forEach((field) => {
      customEvent(
        eventCategories.REGISTER,
        eventActions.CREATE_ACCOUNT_FORM_ERROR,
        field,
      )
    })
  },
  createAccountSuccess(pageTitle) {
    customEvent(
      eventCategories.REGISTER,
      eventActions.CREATE_ACCOUNT_SUCCESS,
      pageTitle,
    )
  },
  deliveryAddressUnavailable(eventLabel) {
    customEvent(
      eventCategories.DELIVERY,
      eventActions.DELIVERY_ADDRESS_UNAVAILABLE,
      eventLabel,
    )
  },
  deliveryDateTimeSuccess() {
    customEvent(
      eventCategories.DELIVERY,
      eventActions.DELIVERY_DATETIME_SUCCESS,
    )
  },
  deliveryDateTimeUnavailable() {
    customEvent(
      eventCategories.DELIVERY,
      eventActions.DELIVERY_DATETIME_UNAVAILABLE,
    )
  },
  editPopularBuildInBag(popularBuildName) {
    customEvent(
      eventCategories.CATERING_POPULAR_BUILD,
      eventActions.EDIT_BUILD_IN_BAG,
      popularBuildName,
    )
  },
  generalPricingDisplayed() {
    customEvent(
      eventCategories.CATERING_PRICING,
      eventActions.GENERAL_PRICING_DISPLAYED,
    )
  },
  pickupDateTimeSuccess() {
    customEvent(eventCategories.PICKUP, eventActions.PICKUP_DATETIME_SUCCESS)
  },
  pickupDateTimeUnavailable() {
    customEvent(
      eventCategories.PICKUP,
      eventActions.PICKUP_DATETIME_UNAVAILABLE,
    )
  },
  reorderFavorites() {
    customEvent(
      eventCategories.CATERING_REORDER,
      eventActions.REORDER_FAVORITES_CLICK,
      eventLabels.REORDER_FAVORITES_BUTTON_DESCRIPTION,
    )
  },
  reorderRecents() {
    customEvent(
      eventCategories.CATERING_REORDER,
      eventActions.REORDER_RECENTS_CLICK,
      eventLabels.REORDER_RECENTS_BUTTON_DESCRIPTION,
    )
  },
  savePaymentCardSuccess() {
    customEvent(
      eventCategories.CUSTOMER,
      eventActions.SAVE_PAYMENT_CARD_SUCCESS,
    )
  },
  saveEventTypeSuccess(cateringEventType) {
    customEvent(
      eventCategories.CATERING_INTAKE,
      eventActions.SAVE_EVENT_TYPE_SUCCESS,
      cateringEventType,
    )
  },
  userLoginSuccess(pageTitle) {
    customEvent(
      eventCategories.CUSTOMER,
      eventActions.USER_LOGIN_SUCCESS,
      pageTitle,
    )
  },
  twoStepVerificationInitialized() {
    customEvent(eventCategories.TWO_STEP_VERIFICATION, eventActions.BEGIN)
  },
  twoStepVerificationCompleted() {
    customEvent(eventCategories.TWO_STEP_VERIFICATION, eventActions.COMPLETE)
  },
  twoStepVerificationLockedOut() {
    customEvent(eventCategories.TWO_STEP_VERIFICATION, eventActions.LOCKED_OUT)
  },
}
