export default {
  links: [
    {
      type: 'text',
      text: 'Order',
      url: process.env.VUE_APP_CHIPOTLE_URL,
      location: 'external',
      target: '_self',
    },
    {
      type: 'text',
      text: 'Catering',
      url: '/',
      location: 'internal',
      target: '_self',
    },
    {
      type: 'text',
      text: 'Rewards',
      url: process.env.VUE_APP_REWARDS_URL,
      location: 'external',
      target: '_self',
    },
    {
      type: 'text',
      text: 'Our Values',
      url: process.env.VUE_APP_OUR_VALUES_URL,
      location: 'external',
      target: '_self',
    },
    {
      type: 'text',
      text: 'Nutrition',
      url: process.env.VUE_APP_NUTRITION_CALCULATOR_URL,
      location: 'external',
      target: '_self',
    },
  ],
}
