<template>
  <custom-modal
    class="bag"
    title="CATERING BAG"
    :visible="value"
    :cover-site-header="false"
    position="top-right"
    :hide-header="$mq | mq({ xs: false, sm: true })"
    :hide-header-close="$mq | mq({ xs: false, sm: true })"
    hide-footer
    @change="$emit('input', $event)"
    :noFade="true"
  >
    <div
      id="bagPopup"
      class="bag-popup"
      :class="{
        'bag-popup-with': $store.getters.orderHasItems,
      }"
    >
      <div class="bag-contents" v-show="!showDelete">
        <div v-show="isCartLoading" class="pepper-spinner" />
        <empty-bag v-show="!isCartLoading && !$store.getters.orderHasItems" />
        <full-bag v-show="!isCartLoading && $store.getters.orderHasItems" />
      </div>
      <transition name="delete-bag-item">
        <div class="bag-contents bag-contents-delete" v-show="showDelete">
          <div class="dialog-header remove-meal-header">
            <div class="dialog-header-title">REMOVE MEAL?</div>
            <div class="dialog-header-close" @click.stop="closeDelete">
              &times;
            </div>
          </div>
          <div class="remove-meal-message">
            <div class="remove-meal-message-title">REMOVE THIS MEAL?</div>
            <div class="remove-meal-message-body">
              Are you sure you want to remove this
              <br />
              meal from your bag?
            </div>
          </div>
          <b-button
            class="remove-meal-button-yes"
            variant="primary"
            size="md"
            @click="deleteItem"
          >
            YES, REMOVE THIS ITEM
          </b-button>
          <b-button class="remove-meal-button-no" @click="closeDelete">
            NO, I CHANGED MY MIND
          </b-button>
        </div>
      </transition>
    </div>
  </custom-modal>
</template>

<script>
import { mapGetters } from 'vuex'

import CustomModal from '@/components/common/CustomModal'
import EmptyBag from '@/components/bag/EmptyBag'
import FullBag from '@/components/bag/FullBag'
import { DELETE_ORDER_ITEM } from '@/store/mutation-types/orderMutations'
import { SHOW_CONFIRM_DIALOG } from '@/store/mutation-types/confirmDialogMutations'
import * as routeNames from '@/router/routeNames'

export default {
  name: 'Bag',
  mounted() {
    this.$root.$on('close-bag', () => this.close())
    this.$root.$on('open-bag-delete', (id) => this.openDelete(id))
  },
  components: {
    CustomModal,
    EmptyBag,
    FullBag,
  },
  props: {
    value: { type: Boolean, default: false },
  },
  data() {
    return {
      showDelete: false,
      deleteId: null,
    }
  },
  computed: {
    ...mapGetters(['isCartLoading', 'bagIsOpened']),
  },
  methods: {
    close() {
      this.$emit('input', false)
    },
    openDelete(id) {
      this.showDelete = true
      this.deleteId = id
    },
    closeDelete() {
      this.showDelete = false
    },
    deleteItem() {
      this.$store
        .dispatch(DELETE_ORDER_ITEM, this.deleteId)
        .then(() => {
          if (
            routeNames.BUILDERS.includes(this.$route.name) &&
            this.$route.params.orderItemId &&
            !this.$store.getters.orderItems.some(
              (oi) => oi.orderItemId === this.$route.params.orderItemId,
            )
          ) {
            this.$router.push({ name: routeNames.HOME })
          }
        })
        .catch(() => {
          this.$store.dispatch(SHOW_CONFIRM_DIALOG, {
            text: 'Something unexpected happened.',
            subText: 'Please try again or contact customer support.',
            okText: 'OK',
            hideCancel: true,
          })
        })
        .finally(() => {
          this.showDelete = false
        })
    },
  },
  watch: {
    bagIsOpened(newVal) {
      if (!newVal) {
        this.showDelete = false
      }
    },
  },
}
</script>

<style lang="scss">
.bag {
  // HACK: fixes Safari only issue causing height: 100% on child not to be computed
  // Revisit this in the CustomModal when all component instances can be regression tested...
  .modal-body {
    display: flex;
  }

  .bag-item-container {
    color: $catering-chocolate;
    margin: 15px 15px 0 15px;
    font-family: $font-family-helvetica;
    font-size: 13px;
    letter-spacing: 0;

    .bag-item-spacer {
      border-top: 1px solid $catering-light-brown;
      margin-top: 15px;
    }

    .bag-item-menu-header {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 18px;
      display: flex;
      justify-content: space-between;

      .bag-item-menu-header-label {
        font-family: $font-family-sans-serif;
      }
    }

    .bag-item-menu-footer {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 16px;
      text-align: center;
    }

    .bag-item-category-header {
      font-weight: bold;
    }

    .bag-item-btn-group {
      margin-left: -2px;
      display: flex;

      .bag-item-btn {
        color: $catering-gold;
        margin-right: 10px;
        font-family: $font-family-sans-serif;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        border: none;
        background: none;
        cursor: pointer;
      }
    }
  }

  .bag-contents {
    display: flex;
    height: 100%;
    width: 100%;
  }

  .bag-contents-delete {
    // This fixes the opacity transitions for IE/Edge
    // Opacity styles only work for IE/Edge if they have a non-static position
    position: absolute;
  }

  .pepper-spinner {
    background-image: url('@/static/img/pepper-spinner.gif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    margin: auto;
    width: 150px;
  }

  .delete-bag-item-enter,
  .delete-bag-item-leave-active {
    opacity: 0;
  }

  .delete-bag-item-enter-active,
  .delete-bag-item-leave-active {
    transition: opacity 0.5s ease;
  }

  .bag-popup {
    background-color: $catering-bg-grey;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: 440px;
      height: 450px;
    }
  }

  .bag-popup-with {
    justify-content: flex-start;
  }

  .bag-header {
    position: absolute;
    top: 0;
  }

  .remove-meal-header {
    position: absolute;
    top: 0;
  }

  .bag-btn-group {
    position: absolute;
    display: flex;
    justify-content: space-between;
    left: 26px;
    bottom: 18px;
    right: 26px;
  }

  .remove-meal-message {
    position: absolute;
    top: 88.5px;
    width: 100%;
    text-align: center;
    color: $catering-chocolate;
  }

  .remove-meal-message-title {
    font-family: $font-family-sans-serif;
    font-size: 22px;
    font-weight: bold;
  }

  .remove-meal-message-body {
    font-family: $font-family-helvetica;
    font-size: 13px;
  }

  .remove-meal-button-yes {
    position: absolute;
    width: 280px;
    height: 40px;
    bottom: 71.3px;
    left: 50%;
    margin-left: -140px;
    font-size: 16px;
    letter-spacing: -0.3px;
  }

  .remove-meal-button-no {
    position: absolute;
    width: 200px;
    height: 30px;
    bottom: 20.6px;
    left: 50%;
    margin-left: -100px;
    color: $catering-gold;
    border: none;
    background: none;
    padding: 0;
    font-size: 13px;
    letter-spacing: 0px;
  }

  @include media-breakpoint-up(sm) {
    .bag-popup {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .bag-header {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: auto;
      text-align: center;
      background-color: $catering-bg-grey;
      box-shadow: none;
      margin-top: 21px;
    }

    .bag-title {
      color: $catering-chocolate;
      font-family: $font-family-sans-serif;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.4px;
      padding-left: 0px;
    }

    .bag-close {
      display: none;
    }

    .remove-meal-header {
      display: none;
    }
  }
}
</style>
