export class Address {
  constructor() {
    this.addressId = null
    this.address1 = null
    this.address2 = null
    this.city = null
    this.state = null
    this.postalCode = null
    this.country = null
    this.firstName = null
    this.lastName = null
    this.email = null
    this.company = null
    this.phone = null
    this.extension = null
    this.instructions = null
    this.addressName = 'OFFICE'
    this.latitude = null
    this.longitude = null
  }
}
