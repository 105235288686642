<template>
  <component
    :is="component"
    :class="['common-link', link.type]"
    v-bind="componentProps"
  >
    <template v-if="isText">
      <span class="common-link-text">{{ link.text }}</span>
      <img
        v-if="link.imageUrl"
        class="inline-icon"
        :src="link.imageUrl"
        :alt="link.altText"
      />
    </template>

    <img
      v-else-if="isImgOrIcon"
      class="common-link-img"
      :src="link.imageUrl"
      :alt="link.altText"
    />
  </component>
</template>

<script>
export default {
  name: 'CommonLink',
  props: {
    link: { type: Object, required: true },
  },
  computed: {
    isText() {
      return this.link.type === 'text'
    },
    isImgOrIcon() {
      return ['image', 'icon'].indexOf(this.link.type) > -1
    },
    component() {
      const { location, target, action } = this.link
      if (action) {
        return 'span'
      } else if (location === 'internal' && target === '_self') {
        return 'router-link'
      }
      return 'a'
    },
    componentProps() {
      let result
      switch (this.component) {
        case 'span':
          result = { onclick: this.link.action }
          break
        case 'router-link':
          result = { to: this.link.url, activeClass: 'link-active' }
          break
        default:
          result = { href: this.link.url, target: this.link.target }
      }
      return result
    },
  },
}
</script>

<style lang="scss">
.common-link {
  &.text {
    text-decoration: none;
    cursor: pointer;
  }
  .inline-icon {
    height: 1em;
  }
}
</style>
