export const LOGIN_ACCOUNT_VERIFICATION_INITIALIZE =
  'LOGIN_ACCOUNT_VERIFICATION_INITIALIZE'
export const CREATE_ACCOUNT_VERIFICATION_INITIALIZE =
  'ACCOUNT_VERIFICATION_INITIALIZE'
export const UPDATE_ACCOUNT_VERIFICATION_INITIALIZE =
  'UPDATE_ACCOUNT_VERIFICATION_INITIALIZE'
export const VERIFY_ACCOUNT_TOKEN = 'VERIFY_ACCOUNT_TOKEN'
export const RESEND_VERIFICATION = 'RESEND_VERIFICATION'
export const CANCEL_ACCOUNT_VERIFICATION = 'CANCEL_ACCOUNT_VERIFICATION'
export const VERIFY_ACCOUNT_PASSWORD = 'VERIFY_ACCOUNT_PASSWORD'
export const SEND_PHONE_VERIFICATION_CODE = 'SEND_PHONE_VERIFICATION_CODE'

export const SET_RESEND_VERIFICATION_SUCCESS = 'SET_RESEND_VERIFICATION_SUCCESS'
export const SET_ACCOUNT_VERIFICATION_FAILED = 'SET_ACCOUNT_VERIFICATION_FAILED'
export const SET_ACCOUNT_VERIFICATION_CANCELLED =
  'SET_ACCOUNT_VERIFICATION_CANCELLED'
export const SET_ACCOUNT_VERIFICATION_ERROR_CODE =
  'SET_ACCOUNT_VERIFICATION_ERROR_CODE'
export const SET_ACCOUNT_VERIFICATION_ERROR_IS_FINAL_ATTEMPT =
  'SET_ACCOUNT_VERIFICATION_ERROR_COUNT'
export const SET_ACCOUNT_VERIFICATION_SUCCESS =
  'SET_ACCOUNT_VERIFICATION_SUCCESS'
export const SET_ACCOUNT_VERIFICATION_STEP = 'SET_ACCOUNT_VERIFICATION_STEP'
export const SET_ACCOUNT_PASSWORD = 'SET_ACCOUNT_PASSWORD'
export const SET_ACCOUNT_PHONE_NUMBER = 'SET_ACCOUNT_PHONE_NUMBER'
export const SET_PHONE_CONFIRMATION_SUCCESS = 'SET_PHONE_CONFIRMATION_SUCCESS'
export const SET_VERIFY_ACCOUNT_PASSWORD_SUCCESS =
  'SET_VERIFY_ACCOUNT_PASSWORD_SUCCESS'
