<template>
  <div>
    <div class="bag-item-menu-header">
      <div class="bag-item-menu-header-label">{{ item.displayName }}</div>
      <div>{{ item.subTotal | toCurrency() }}</div>
    </div>
    <div>
      <span style="font-weight: bold">{{ tier }}</span>
      {{ tagLine }}
    </div>
    <div>{{ item.servesText }}</div>
    <div v-show="showItems('bases')" class="bag-item-category-header">
      <br />
      Bases
    </div>
    <div>{{ selectedSubItems(item, menuItem, 'bases') }}</div>
    <div v-show="showItems('proteins')" class="bag-item-category-header">
      <br />
      Proteins
    </div>
    <div>{{ selectedSubItems(item, menuItem, 'proteins') }}</div>
    <div v-show="showItems('toppings')" class="bag-item-category-header">
      <br />
      Toppings
    </div>
    <div>{{ selectedSubItems(item, menuItem, 'toppings') }}</div>
    <div v-show="showItems('premiumToppings')" class="bag-item-category-header">
      <br />
      Premium Toppings
    </div>
    <div>{{ selectedSubItems(item, menuItem, 'premiumToppings') }}</div>
    <div v-show="showItems('salsas')" class="bag-item-category-header">
      <br />
      Salsas
    </div>
    <div>{{ selectedSubItems(item, menuItem, 'salsas') }}</div>
    <div v-show="showItems('tortillas')" class="bag-item-category-header">
      <br />
      Tortillas
    </div>
    <div>{{ selectedSubItems(item, menuItem, 'tortillas') }}</div>
    <br />
    <div class="bag-item-btn-group">
      <gold-link class="bag-item-btn" @click="editClose">EDIT</gold-link>
      <gold-link class="bag-item-btn" @click="showRemove">REMOVE</gold-link>
    </div>
  </div>
</template>

<script>
import GoldLink from '@/components/common/GoldLink'
import { commonBuilderMixin } from '@/mixins/commonBuilder'
import { BUILD_YOUR_OWN } from '@/router/routeNames'

export default {
  name: 'bag-item-build-your-own',
  props: ['item'],
  components: { GoldLink },
  mixins: [commonBuilderMixin],
  methods: {
    editClose() {
      this.$root.$emit('close-bag')
      this.editItemFromBag(BUILD_YOUR_OWN)
    },
    showRemove() {
      this.$root.$emit('open-bag-delete', this.item.orderItemId)
    },
    showItems(category) {
      return (
        this.selectedSubItems(this.item, this.menuItem, category).length > 0
      )
    },
  },
  computed: {
    tier: {
      get() {
        return this.menuItem ? this.menuItem.name : ''
      },
    },
    tagLine: {
      get() {
        return this.menuItem && this.menuItem.tagline
          ? ' - ' + this.menuItem.tagline
          : ''
      },
    },
    menuItem: {
      get() {
        return this.$store.getters.getMenuItem(this.item.menuId)
      },
    },
  },
}
</script>
