<template>
  <div
    class="gold-link"
    role="button"
    tabindex="0"
    @keydown.enter="emitClickEvent"
    @click="emitClickEvent"
  >
    <slot />
  </div>
</template>

<script>
export default {
  methods: {
    emitClickEvent() {
      this.$emit('click')
    },
  },
}
</script>
