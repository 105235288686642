import { cateringOrderClient } from '@/services/clients'

function createSearchRequest(index, menuIds) {
  const params = {
    timeStamp: new Date().toISOString(),
    restaurantStatuses: ['OPEN'],
    conceptIds: ['CMG'],
    pageSize: 15,
    pageIndex: index,
    orderBy: 'distance',
    radius: 80467, // 50 miles in meters
    embeds: {
      addressTypes: ['MAIN'],
      directions: true,
      realHours: true,
      delivery: true,
      catering: true,
      timezone: true,
    },
  }
  if (menuIds) {
    params.menuIds = menuIds
  }
  return params
}

export default {
  searchByLatLong({ latitude, longitude }, index, menuIds) {
    const params = {
      ...createSearchRequest(index, menuIds),
      latitude,
      longitude,
    }
    return cateringOrderClient.post('/restaurant/search', params)
  },
  search({ latitude, longitude }, index, menuIds) {
    const params = {
      ...createSearchRequest(index, menuIds),
      latitude,
      longitude,
    }
    return cateringOrderClient.post('/restaurant/search', params)
  },
  getRestaurant(restaurantId, menuIds) {
    const params = {
      ...createSearchRequest(0, menuIds),
      restaurantNumbers: [restaurantId],
    }
    return cateringOrderClient.post('/restaurant/search', params)
  },
  getRestaurantRealHours(restaurantNumber) {
    return cateringOrderClient.get(`/restaurant/${restaurantNumber}/realhours`)
  },
  getRestaurantTimes(request) {
    return cateringOrderClient.post('/capacity', request)
  },
  getDeliveryRestaurantsAndHours(request) {
    return cateringOrderClient.post('/restaurant/delivery', request)
  },
}
