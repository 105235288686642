<template>
  <div class="user-info-control">
    <b-container fluid>
      <b-form @submit.prevent.stop="onSubmit" novalidate>
        <inputbox
          :id="id + 'uiFirstNameInput'"
          type="text"
          v-model="firstName"
          placeholder="Enter your first name"
          auto-complete="given-name"
          :required="true"
          :validity-state="validityState"
          :max-length="35"
          :rules="[rules.required('First Name is required')]"
        />

        <inputbox
          :id="id + 'uiLastNameInput'"
          type="text"
          v-model="lastName"
          placeholder="Enter your last name"
          auto-complete="family-name"
          :required="true"
          :validity-state="validityState"
          :max-length="50"
          :rules="[rules.required('Last Name is required')]"
        />

        <inputbox
          :id="id + 'uiEmailInput'"
          type="text"
          v-model="email"
          placeholder="Enter your email"
          auto-complete="username"
          :required="true"
          :validity-state="validityState"
          :max-length="50"
          :rules="[
            rules.required('Email is required'),
            rules.emailRegEx('Email is invalid'),
          ]"
        />

        <inputbox
          :id="id + 'uiPhoneNumberInput'"
          type="text"
          v-model="phoneNumber"
          placeholder="Enter your phone number"
          :required="true"
          :validity-state="validityState"
          :mask="phoneMask"
          :rules="[
            rules.required('Enter your phone number'),
            rules.phoneNumber('Phone Number must contain 10 digits'),
          ]"
        />
        <p class="small-text">
          Enter a valid mobile number that can accept SMS messages. This will be
          used for two-step verification for account access, and for
          order-related notifications.
        </p>

        <b-form-group
          label="Country*"
          label-text-align="left"
          class="form-input-group-label"
        >
          <b-form-select
            :id="id + 'uiCountrySelect'"
            v-model="country"
            class="pl-0"
            :options="countryCodes"
          />
        </b-form-group>

        <inputbox
          :id="id + 'uiPasswordInput'"
          :type="passwordFieldType"
          v-model="password"
          placeholder="Enter your password"
          auto-complete="new-password"
          :required="true"
          :validity-state="validityState"
          :max-length="50"
          :rules="[
            rules.required('Password is required'),
            (v) =>
              rules.evaluatePassword(v) >= 2 ||
              'Password must have at least 8 characters and a minimum of one lowercase letter, one uppercase letter, one number, and one special character',
          ]"
        >
          <span slot="icon">
            <eye-open-svg
              v-show="!shouldShowPassword"
              aria-label="Show password"
              :aria-hidden="shouldShowPassword"
              role="button"
              tabindex="0"
              @eyeopensvg-click="showPassword"
              @keydown.native.enter="showPassword"
            />
            <eye-closed-svg
              v-show="shouldShowPassword"
              aria-label="Hide password"
              :aria-hidden="!shouldShowPassword"
              role="button"
              tabindex="0"
              @eyeclosedsvg-click="hidePassword"
              @keydown.native.enter="hidePassword"
            />
          </span>
        </inputbox>

        <div class="error-container error">{{ errorMessage }}</div>
        <a
          class="privacy-policy d-block"
          href="https://www.chipotle.com/privacy-policy"
          target="_blank"
        >
          PRIVACY POLICY
        </a>

        <b-button
          variant="primary"
          block
          size="md"
          class="continue-btn"
          type="submit"
        >
          CREATE ACCOUNT
        </b-button>
      </b-form>
    </b-container>
  </div>
</template>

<script>
import eyeOpenSvg from '@/components/common/EyeOpenSvg'
import eyeClosedSvg from '@/components/common/EyeClosedSvg'
import validation from '@/validators/accountValidationService'
import inputbox from '@/components/common/Inputbox'
import {
  emailRegEx,
  phoneNumber,
  required,
  validityStateInitialize,
} from '@/validators/index'
import { CREATE_ACCOUNT } from '@/store/mutation-types/userMutations'
import { CREATE_ACCOUNT_VERIFICATION_INITIALIZE } from '@/store/mutation-types/accountVerificationMutations'
import { phoneMask, removeNonNumericMask } from '@/masks'
import analyticsService from '@/services/analyticsService'
import { mapGetters } from 'vuex'
import * as verificationErrorTypes from '@/constants/accountVerificationErrorTypes'
import { SHOW_CONFIRM_DIALOG } from '@/store/mutation-types/confirmDialogMutations'
import { PhoneVerificationUnexpectedError } from '@/constants'

export default {
  name: 'user-info',
  props: ['id'],
  components: {
    eyeOpenSvg,
    eyeClosedSvg,
    inputbox,
  },
  data() {
    return {
      shouldShowPassword: false,
      passwordFieldType: 'password',
      firstName: null,
      lastName: null,
      email: null,
      phoneNumber: null,
      country: 'US',
      password: null,
      errorMessage: null,
      rules: {
        emailRegEx,
        evaluatePassword: validation.evaluatePassword,
        phoneNumber,
        required,
      },
    }
  },
  computed: {
    ...mapGetters(['countryCodes']),
    validityState: { get: () => validityStateInitialize() },
    phoneMask() {
      return phoneMask
    },
  },
  methods: {
    showPassword() {
      this.shouldShowPassword = true
      this.passwordFieldType = 'text'
    },
    hidePassword() {
      this.shouldShowPassword = false
      this.passwordFieldType = 'password'
    },
    onSubmit() {
      if (!this.validityState.isValid()) {
        const errorFields = Object.keys(this.validityState.state).filter(
          (key) => !this.validityState.state[key].isValid,
        )
        analyticsService.createAccountFieldErrors(errorFields)
        return
      }
      this.errorMessage = null
      this.$store
        .dispatch(CREATE_ACCOUNT, {
          firstName: this.firstName,
          lastName: this.lastName,
          emailAddress: this.email,
          country: this.country,
          password: this.password,
          phoneNumber: removeNonNumericMask(this.phoneNumber),
        })
        .then(() => {
          this.$emit('create-account-success')
        })
        .catch((e) => {
          this.$store
            .dispatch(CREATE_ACCOUNT_VERIFICATION_INITIALIZE, {
              phoneNumber: this.phoneNumber,
              email: this.email,
              errorCode: e.errorCode,
            })
            .then(() => {
              this.$emit('create-account-success')
            })
            .catch((e) => {
              this.errorMessage =
                'Failed to create an account. Please try again.'
              if (
                e.message ===
                verificationErrorTypes.ACCOUNT_VERIFICATION_INITIALIZATION_FAILED
              ) {
                this.$store.dispatch(SHOW_CONFIRM_DIALOG, {
                  ...PhoneVerificationUnexpectedError,
                })
              }
              if (
                e.message !==
                verificationErrorTypes.ACCOUNT_VERIFICATION_UNKNOWN_ERROR
              ) {
                this.$emit('create-account-verification-failed')
              }
            })
        })
    },
  },
}
</script>

<style lang="scss">
.user-info-control {
  .title {
    width: 100%;
    font-family: $font-family-sans-serif;
    font-weight: bold;
    font-size: 22px;
    text-transform: capitalize;
    text-align: center;
  }

  .description {
    width: 100%;
    font-family: $font-family-helvetica;
    font-size: 13px;
    text-align: center;
    letter-spacing: -0.2px;
  }

  .small-text {
    display: block;
    color: $catering-medium-brown;
    font-size: 10px;
    line-height: normal;
    margin-top: -0.5rem;
  }

  .form-group {
    & > div {
      position: relative;
    }

    .seeing-eye {
      position: absolute;
      top: 6px;
      left: 94%;
      cursor: pointer;

      path {
        fill: $catering-chocolate;
      }
      circle {
        stroke: $catering-chocolate;
      }
    }
  }

  input[type='text'],
  input[type='password'] {
    background-color: transparent;
    font-size: 19px;
    letter-spacing: normal;
  }

  .error-container {
    padding-bottom: 15px;
  }

  .privacy-policy {
    color: $catering-gold;
    font-family: $font-family-sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    border: none;
    background: none;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 15px;
    cursor: pointer;
  }

  .continue-btn {
    height: 40px;
    font-size: 16px;
    letter-spacing: -0.3px;
  }
}
</style>
