// route-level code splitting
export const BuildYourOwn = () =>
  import(
    /* webpackChunkName: "BuildYourOwn" */ '@/components/builder/BuildYourOwn'
  )
export const BurritosByTheBox = () =>
  import(
    /* webpackChunkName: "BurritosByTheBox" */ '@/components/builder/BurritosByTheBox'
  )
export const ChangePassword = () =>
  import(
    /* webpackChunkName: "ChangePassword" */ '@/components/account/ChangePassword'
  )
export const Checkout = () =>
  import(/* webpackChunkName: "Checkout" */ '@/components/checkout/Checkout')
export const ChipsAndSalsa = () =>
  import(
    /* webpackChunkName: "ChipsAndSalsa" */ '@/components/builder/ChipsAndSalsa'
  )
export const Confirmation = () =>
  import(
    /* webpackChunkName: "Confirmation" */ '@/components/confirmation/ConfirmationHome'
  )
export const CreateAccount = () =>
  import(
    /* webpackChunkName: "CreateAccount" */ '@/components/account/CreateAnAccountLanding'
  )
export const Home = () =>
  import(/* webpackChunkName: "Home" */ '@/components/home/Home')
export const IntakeDetail = () =>
  import(/* webpackChunkName: "IntakeDetail" */ '@/components/intake/Detail')
export const Login = () =>
  import(/* webpackChunkName: "Login" */ '@/components/account/LoginLanding')
export const OrderHistory = () =>
  import(
    /* webpackChunkName: "OrderHistory" */ '@/components/account/OrderHistory'
  )
export const PageNotFound = () =>
  import(
    /* webpackChunkName: "PageNotFound" */ '@/components/pageNotFound/PageNotFound'
  )
export const PaymentMethods = () =>
  import(
    /* webpackChunkName: "PaymentMethods" */ '@/components/account/PaymentMethods'
  )
export const SavedAddresses = () =>
  import(
    /* webpackChunkName: "SavedAddresses" */ '@/components/account/SavedAddresses'
  )
export const UpdateUser = () =>
  import(/* webpackChunkName: "UpdateUser" */ '@/components/account/UpdateUser')
