import * as types from '@/store/mutation-types/eventTypeMutations'
import * as loaderTypes from '@/store/mutation-types/loaderMutations'
import eventTypeService from '@/services/eventTypeService'
import { CacheTimes } from '@/constants'

const state = {
  searchResults: [],
  loaderName: 'eventTypeSearch',
  searchInfo: {
    searching: false,
    lastUpdated: null,
    error: null,
  },
}

const getters = {
  eventTypes: (state) => {
    return state.searchResults
  },
  eventTypesHaveError: (state) => {
    return !!state.searchInfo.error
  },
}

const actions = {
  [types.GET_EVENT_TYPES]({ commit, dispatch, state }) {
    if (state.searching) {
      return
    }

    if (state.searchInfo.lastUpdated) {
      let lastUpdated = state.searchInfo.lastUpdated
      const currentDatetime = new Date()

      if (!lastUpdated.getTime) {
        // the date is a string for some reason
        lastUpdated = new Date(lastUpdated)
      }

      if (
        state.searchResults.length > 0 &&
        currentDatetime.getTime() - lastUpdated.getTime() <= CacheTimes.Long
      ) {
        return
      }
    }

    dispatch(loaderTypes.QUEUE_LOADER, {
      name: state.loaderName,
      mainText: 'Searching for event types',
      subText: '',
    })

    commit(types.UPDATE_EVENT_TYPES_SEARCH_INFO, {
      ...state.searchInfo,
      ...{ searching: true },
    })
    return eventTypeService
      .getEventTypes()
      .then((response) => {
        commit(types.UPDATE_EVENT_TYPES_SEARCH_INFO, {
          ...state.searchInfo,
          ...{ searching: false, lastUpdated: new Date(), error: null },
        })
        commit(types.UPDATE_EVENT_TYPES, response)
      })
      .catch((e) => {
        commit(types.UPDATE_EVENT_TYPES_SEARCH_INFO, {
          ...state.searchInfo,
          ...{ searching: false, error: e },
        })
        commit(types.UPDATE_EVENT_TYPES, { data: [] })
        throw e
      })
      .finally(() => {
        dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
      })
  },
}

const mutations = {
  [types.UPDATE_EVENT_TYPES](state, newSearch) {
    state.searchResults = newSearch.data
  },
  [types.UPDATE_EVENT_TYPES_SEARCH_INFO](state, newValue) {
    state.searchInfo = newValue
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
