<template>
  <div
    class="checkmark-toggle"
    :class="{ 'checkmark-toggle-checked': isChecked }"
    :style="{ height: pixelSize, width: pixelSize }"
  >
    <check-svg v-visible="isChecked" :pixelSize="svgPixelSize" />
  </div>
</template>

<script>
import CheckSvg from '@/components/common/CheckSvg'

export default {
  components: {
    CheckSvg,
  },
  props: {
    isChecked: Boolean,
    size: {
      type: Number,
      default: 18,
    },
  },
  computed: {
    pixelSize() {
      return this.size + 'px'
    },
    svgPixelSize() {
      return this.size - 1 + 'px'
    },
  },
}
</script>

<style lang="scss">
.checkmark-toggle {
  background-color: transparent;
  border: 1px solid $catering-light-grey-brown;
  border-radius: 50%;

  &.checkmark-toggle-checked {
    background-color: $catering-yellow;
    border-color: $catering-yellow;
  }

  svg {
    display: block;
  }
}
</style>
