export default function toStringDate(value, format) {
  if (!value || !value.toLocaleDateString) {
    return value
  }

  if (!format) {
    format = { month: 'long', day: 'numeric', year: 'numeric' }
  }
  return value.toLocaleDateString('en-US', format)
}
