<template>
  <div>
    <div class="loader-overlay" v-if="loaderIncludeOverlay" />
    <div class="loader">
      <div class="main-text">{{ mainText }}</div>
      <div class="sub-text">{{ subText }}</div>
      <div class="spinner">
        <pepper-svg />
        <div class="halfclip">
          <div class="halfcircle clipped" />
        </div>
        <div class="halfcircle fixed" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import pepperSvg from './PepperSvg'

export default {
  props: [],
  components: {
    pepperSvg,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['loaderIncludeOverlay']),
    mainText: {
      get() {
        return this.$store.getters.loaderMainText
      },
    },
    subText: {
      get() {
        return this.$store.getters.loaderSubText
      },
    },
  },
}
</script>
<style lang="scss">
$time: 1s;

.loader {
  width: 300px;
  height: 253px;
  background-color: $catering-white;
  margin: 0 auto;
  text-align: center;
  border-radius: 5px;
  border: solid $catering-light-brown 1px;
  position: fixed;

  .sub-text {
    font-family: $font-family-helvetica;
    color: $catering-grey-brown;
    line-height: 1.47;
    font-size: 15px;
    margin-bottom: 12px;
  }

  .main-text {
    text-transform: uppercase;
    font-weight: bold;
    font-family: $font-family-sans-serif;
    color: $catering-chocolate;
    font-size: 18px;
    line-height: 1.22;
    margin-top: 65px;
  }

  .spinner {
    position: relative;
    width: 64px;
    height: 64px;
    animation: colors $time infinite; //4s
    margin: 0 auto;

    .pepper {
      color: $catering-mobile-header;
      position: absolute;
      width: 33px;
      height: 33px;
      top: calc(50% - 16.5px);
      left: calc(50% - 16.5px);
    }

    .halfclip {
      width: 50%;
      height: 100%;
      right: 0px;
      position: absolute;
      overflow: hidden;
      transform-origin: left center;
      animation: cliprotate $time * 4 steps(2) infinite; // 16s
      -webkit-animation: cliprotate $time * 4 steps(2) infinite; // 16s
    }

    .halfcircle {
      box-sizing: border-box;
      height: 100%;
      right: 0px;
      position: absolute;
      border: solid 2px transparent;
      border-top-color: $catering-chocolate;
      border-left-color: $catering-chocolate;
      border-radius: 50%;
    }

    .clipped {
      width: 200%;
      animation: rotate $time * 2 linear infinite; // 8s
      -webkit-animation: rotate $time * 2 linear infinite; // 8s
    }

    .fixed {
      width: 100%;
      transform: rotate(135deg);
      animation: showfixed $time * 4 steps(2) infinite; // 16s
      -webkit-animation: showfixed $time * 4 linear infinite; // 16s
    }

    @-webkit-keyframes cliprotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes cliprotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    @-webkit-keyframes rotate {
      0% {
        transform: rotate(-45deg);
      }
      100% {
        transform: rotate(135deg);
      }
    }

    @keyframes rotate {
      0% {
        transform: rotate(-45deg);
      }
      100% {
        transform: rotate(135deg);
      }
    }

    @-webkit-keyframes showfixed {
      0% {
        opacity: 0;
      }
      49.9% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes showfixed {
      0% {
        opacity: 0;
      }
      49.9% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 1;
      }
    }
  }
}

.loader-overlay {
  background-color: $catering-popup-shadow;
  opacity: 0.5;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
</style>
