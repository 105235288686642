import betweenLength from './betweenLength'
import cardNumberRegEx from './cardNumberRegEx'
import cardZipCodeRegEx from './cardZipCodeRegEx'
import emailRegEx from './emailRegEx'
import phoneNumber from './phoneNumber'
import required from './required'
import * as validityState from './validityState'
import usCanadaOptionalZipCodeRegEx from './usCanadaOptionalZipCodeRegEx'
import birthdayHyphenOptionalRegEx from './birthdayHyphenOptionalRegEx'

// Created this function to support mocking in unit tests
function validityStateInitialize() {
  return validityState.validityStateInitialize()
}

export {
  betweenLength,
  cardNumberRegEx,
  cardZipCodeRegEx,
  emailRegEx,
  phoneNumber,
  required,
  validityStateInitialize,
  usCanadaOptionalZipCodeRegEx,
  birthdayHyphenOptionalRegEx,
}
