import * as types from '@/store/mutation-types/transactionMutations'
import * as loaderTypes from '@/store/mutation-types/loaderMutations'
import transactionService from '@/services/transactionService'
import analyticsService from '@/services/analyticsService'
import { pollPromise } from '@/utilityFunctions'
import { UCOM_PROVIDER_ID } from '@/constants/paymentProviders'

const state = {
  loaderName: 'transaction',
  wallet: [],
}

const getters = {
  wallet: (state) => {
    return state.wallet
  },
}

const actions = {
  [types.GET_WALLET]({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      dispatch(loaderTypes.SHOW_LOADER, {
        name: state.loaderName,
        mainText: 'Getting wallet',
        subText: '',
      })

      transactionService
        .getWallet()
        .then((response) => {
          commit(
            types.UPDATE_WALLET,
            response.data.filter((x) => !x.isGiftCard),
          )
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
        .finally(() => {
          dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
        })
    })
  },
  [types.SAVE_CARD_TO_WALLET]({ commit, dispatch, state }, tokenizedCardInfo) {
    dispatch(loaderTypes.SHOW_LOADER, {
      name: state.loaderName,
      mainText: 'Adding card',
      subText: '',
    })

    return transactionService
      .createWallet(tokenizedCardInfo)
      .then((response) => {
        if (response.data) {
          tokenizedCardInfo.tokenId =
            typeof response.data === 'object'
              ? response.data.tokenId
              : response.data
          commit(types.SAVE_CARD_TO_WALLET, tokenizedCardInfo)
          analyticsService.savePaymentCardSuccess()
        }
        return tokenizedCardInfo
      })
      .finally(() => {
        dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
      })
  },
  [types.DELETE_CARD_FROM_WALLET]({ commit, dispatch, state }, tokenId) {
    return new Promise((resolve, reject) => {
      dispatch(loaderTypes.SHOW_LOADER, {
        name: state.loaderName,
        mainText: 'Removing card',
        subText: '',
      })

      transactionService
        .deleteWallet(tokenId)
        .then(() => {
          commit(types.DELETE_CARD_FROM_WALLET, tokenId)
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
        .finally(() => {
          dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
        })
    })
  },
  [types.GET_PAYMENT_PROVIDER]({ dispatch, state }, restaurantNumber) {
    dispatch(loaderTypes.SHOW_LOADER, {
      name: state.loaderName,
      mainText: 'Loading Payment Form',
      subText: '',
    })

    return transactionService
      .getPaymentProvider(restaurantNumber)
      .finally(() => {
        dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
      })
  },
  [types.INIT_PAYMENT_PROVIDER_TOKENIZATION](
    { dispatch, state },
    paymentProviderId,
  ) {
    dispatch(loaderTypes.SHOW_LOADER, {
      name: state.loaderName,
      mainText: 'Validating card',
      subText: '',
    })

    return transactionService
      .initializePaymentProviderRequest(paymentProviderId)
      .finally(() => {
        dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
      })
  },
  [types.GET_TOKENIZED_PAYMENT_INFO]({ dispatch, state }, paymentTokenRequest) {
    dispatch(loaderTypes.SHOW_LOADER, {
      name: state.loaderName,
      mainText: 'Securing card',
      subText: '',
    })

    // Function to run in retry logic
    // Transaction Api relies on payment providers to call webhooks with the tokenized result, which happens asynchronously
    const actionFunc = () => {
      return transactionService
        .getPaymentToken(paymentTokenRequest)
        .then((res) => {
          if (res.data.isSuccess) {
            if (paymentTokenRequest.paymentProviderId === UCOM_PROVIDER_ID && res.data.card.exp.year.length === 2) {
              const currentYear = new Date().getFullYear()
              const currentCenturyPrefix = Math.floor(currentYear / 100)
              const century = parseInt(res.data.card.exp.year) >= (currentYear % 100) ? currentCenturyPrefix : currentCenturyPrefix + 1
              res.data.card.exp.year = `${century}${res.data.card.exp.year}`
            }
            return res.data.card
          }
          throw new Error(res.errorReason)
        })
    }

    return pollPromise(actionFunc, { waitTime: 300, retryCount: 0 })
      .then((cardInfo) => {
        return {
          tokenizedAccountNumber: cardInfo.token,
          expiration: `${cardInfo.exp.month}${cardInfo.exp.year.substr(2)}`,
          expirationMonth: cardInfo.exp.month,
          expirationYear: cardInfo.exp.year.substr(2),
          cardHolderName: cardInfo.name,
          paymentMethod: cardInfo.brand.replace('-', ' '),
          paymentProviderId: paymentTokenRequest.paymentProviderId,
          billingAddress: '',
          billingCity: '',
          billingCountry: '',
          billingStateProvince: '',
          billingZip: cardInfo.billingZip,
        }
      })
      .finally(() => {
        dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
      })
  },
}

const mutations = {
  [types.SAVE_CARD_TO_WALLET](state, card) {
    state.wallet.push(card)
  },
  [types.DELETE_CARD_FROM_WALLET](state, tokenId) {
    let idx = -1
    for (let i = 0; i < state.wallet.length; i++) {
      const card = state.wallet[i]
      if (card.tokenId === tokenId) {
        idx = i
        break
      }
    }
    if (idx > -1) {
      state.wallet.splice(idx, 1)
    }
  },
  [types.UPDATE_WALLET](state, wallet) {
    state.wallet = wallet
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
