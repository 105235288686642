import tokens from './masker/tokens'

const defaultMask = '#### #### #### ####'

// https://github.com/payeezy/payeezy_js/blob/master/javascript/payeezy_us_v5.1.js
// based off of the currently accepted payeezy card formats
export const creditCardMask = {
  mask: (input) => {
    if (typeof input !== 'string' || !input || !input.length) {
      return defaultMask
    }

    if (/^4/.test(input)) {
      // visa
      return defaultMask
    } else if (/^5[1-5]/.test(input)) {
      // mastercard
      return defaultMask
    } else if (/^3[47]/.test(input)) {
      // amex
      return '#### ###### #####'
    } else if (/^3(?:0[0-5]|[68][0-9])/.test(input)) {
      // diners
      return '#### ###### ####'
    } else if (/^6(?:011|5[0-9]{2})/.test(input)) {
      // discover
      return defaultMask
    } else if (/^(?:2131|1800|35\d{3})/.test(input)) {
      // jcb
      return defaultMask
    }

    // return numeric-only mask to prevent alpha
    // entry while still allowing up to 19 digits
    // for any potentially supported card format.
    return '###################'
  },
  tokens,
}
