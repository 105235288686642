window._trackJs = {
  token: process.env.VUE_APP_TRACK_JS_TOKEN,
  application: process.env.VUE_APP_TRACK_JS_APPLICATION,
  enabled: process.env.NODE_ENV === 'production',
  onError: (payload) => {
    payload.network = payload.network.filter(function (item) {
      if (item.statusCode !== 401) {
        return true
      } else {
        // suppress 401 errors since we expect to get them
        // when the cart/session is expired.
        return false
      }
    })

    return true
  },
}

// load trackjs after the token has been
// attached to the window context
require('trackjs')
