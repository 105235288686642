import { shouldPolyfill as shouldPolyfillGetCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill'
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill'
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill'
import { shouldPolyfill as shouldPolyfillNumberFormat } from '@formatjs/intl-numberformat/should-polyfill'
import { shouldPolyfill as shouldPolyfillDateTimeFormat } from '@formatjs/intl-datetimeformat/should-polyfill'

export default function polyfill() {
  const polyfills = []
  const dataPolyfills = []
  if (shouldPolyfillGetCanonicalLocales()) {
    polyfills.push(
      import(
        /* webpackChunkName: "getcanonicallocales-polyfill" */ '@formatjs/intl-getcanonicallocales/polyfill'
      ),
    )
  }
  if (shouldPolyfillLocale()) {
    polyfills.push(
      import(
        /* webpackChunkName: "locale-polyfill" */ '@formatjs/intl-locale/polyfill'
      ),
    )
  }
  if (shouldPolyfillPluralRules()) {
    polyfills.push(
      import(
        /* webpackChunkName: "pluralrules-polyfill" */ '@formatjs/intl-pluralrules/polyfill'
      ),
    )
  }
  if (shouldPolyfillNumberFormat()) {
    polyfills.push(
      import(
        /* webpackChunkName: "numberformat-polyfill" */ '@formatjs/intl-numberformat/polyfill'
      ),
    )
  }
  if (shouldPolyfillDateTimeFormat()) {
    polyfills.push(
      import(
        /* webpackChunkName: "datetimeformat-polyfill" */ '@formatjs/intl-datetimeformat/polyfill'
      ),
    )
  }
  return Promise.all(polyfills).then(() => {
    if (Intl.PluralRules.polyfilled) {
      dataPolyfills.push(
        import(
          /* webpackChunkName: "pluralrules-en" */ '@formatjs/intl-pluralrules/locale-data/en'
        ),
      )
    }
    if (Intl.NumberFormat.polyfilled) {
      dataPolyfills.push(
        import(
          /* webpackChunkName: "numberformat-en" */ '@formatjs/intl-numberformat/locale-data/en'
        ),
      )
    }
    if (Intl.DateTimeFormat.polyfilled) {
      dataPolyfills.push(
        import(
          /* webpackChunkName: "datetimeformat-tz" */ '@formatjs/intl-datetimeformat/add-golden-tz'
        ),
        import(
          /* webpackChunkName: "datetimeformat-en" */ '@formatjs/intl-datetimeformat/locale-data/en'
        ),
      )
    }
    return Promise.all(dataPolyfills)
  })
}
