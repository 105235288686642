import * as names from '@/router/routeNames'
import * as views from '@/router/views'
import * as guards from '@/router/routeGuards'

export default [
  {
    path: '/',
    name: names.HOME,
    component: views.Home,
    beforeEnter: guards.beforeHomeEnter,
    meta: {
      title: 'Home',
    },
  },
  {
    path: '/order/intake',
    name: names.INTAKE_DETAIL,
    component: views.IntakeDetail,
    beforeEnter: guards.beforeIntakeDetailEnter,
    meta: {
      title: 'Order Intake',
    },
  },
  {
    path: '/builder/buildyourown/:menuType/:orderItemId?',
    name: names.BUILD_YOUR_OWN,
    component: views.BuildYourOwn,
    beforeEnter: guards.beforeBuildYourOwnEnter,
    meta: {
      title: 'Build your Own',
    },
  },
  {
    path: '/builder/burritosbythebox/:menuType/:orderItemId?',
    name: names.BURRITOS_BY_THE_BOX,
    component: views.BurritosByTheBox,
    beforeEnter: guards.beforeBurritosByTheBoxEnter,
    meta: {
      title: 'Burritos by the Box',
    },
  },
  {
    path: '/builder/chipsandsalsa/:menuType/:orderItemId?',
    name: names.CHIPS_AND_SALSA,
    component: views.ChipsAndSalsa,
    beforeEnter: guards.beforeChipsAndSalsaEnter,
    meta: {
      title: 'Chips and Salsa',
    },
  },
  {
    path: '/bag/checkout',
    name: names.CHECKOUT,
    component: views.Checkout,
    beforeEnter: guards.beforeCheckoutEnter,
    meta: {
      title: 'Order Checkout',
    },
  },
  {
    path: '/order/confirmation/:orderId',
    name: names.CONFIRMATION,
    component: views.Confirmation,
    beforeEnter: guards.beforeConfirmationEnter,
    meta: {
      title: 'Order Confirmation',
    },
  },
  {
    path: '/account/updateuser',
    name: names.UPDATE_USER,
    component: views.UpdateUser,
    beforeEnter: guards.beforeUpdateUserEnter,
    meta: {
      title: 'Personal and Preferences',
    },
  },
  {
    path: '/account/changepassword',
    name: names.CHANGE_PASSWORD,
    component: views.ChangePassword,
    beforeEnter: guards.beforeChangePasswordEnter,
    meta: {
      title: 'Change Password',
    },
  },
  {
    path: '/account/create',
    name: names.CREATE_ACCOUNT,
    component: views.CreateAccount,
    beforeEnter: guards.beforeCreateAccountEnter,
    meta: {
      title: 'Create Account',
    },
  },
  {
    path: '/account/orderhistory',
    name: names.ORDER_HISTORY,
    component: views.OrderHistory,
    beforeEnter: guards.beforeOrderHistoryEnter,
    meta: {
      title: 'Order History',
    },
  },
  {
    path: '/account/login',
    name: names.LOGIN,
    component: views.Login,
    beforeEnter: guards.beforeLoginEnter,
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/account/paymentmethods',
    name: names.PAYMENT_METHODS,
    component: views.PaymentMethods,
    beforeEnter: guards.beforePaymentMethodsEnter,
    meta: {
      title: 'Payment Methods',
    },
  },
  {
    path: '/account/savedaddresses',
    name: names.SAVED_ADDRESSES,
    component: views.SavedAddresses,
    beforeEnter: guards.beforeSavedAddressesEnter,
    meta: {
      title: 'Saved Addresses',
    },
  },
  {
    path: '/pagenotfound',
    name: names.PAGE_NOT_FOUND,
    component: views.PageNotFound,
    beforeEnter: guards.beforePageNotFoundEnter,
    meta: {
      title: 'Page not Found',
    },
  },
  {
    path: '*',
    redirect: { name: names.PAGE_NOT_FOUND },
  },
]
