// taken from https://github.com/vuejs-tips/vue-the-mask
// the library accomplishes nearly everything we need,
// but it is no longer maintained.  Replicated so that we
// can use it with omitted masked values and custom events.
import masker from './masker'
import tokens from './tokens'

// https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Creating_and_triggering_events#The_old-fashioned_way
const event = (name) => {
  const evt = document.createEvent('Event')
  evt.initEvent(name, true, true)
  evt.customEventType = 'mask'
  return evt
}

let handler

export default {
  bind(el, binding) {
    let config = binding.value

    // if there is no value, return since the binding isn't needed
    if (!config) {
      return false
    }

    if (Array.isArray(config) || typeof config === 'string') {
      config = {
        mask: config,
        tokens,
      }
    }

    if (el.tagName.toLocaleUpperCase() !== 'INPUT') {
      const els = el.getElementsByTagName('input')
      if (els.length !== 1) {
        throw new Error(
          'v-mask directive requires 1 input, found ' + els.length,
        )
      } else {
        el = els[0]
      }
    }

    const isMaskFunction = typeof config.mask === 'function'
    let mask = isMaskFunction ? config.mask(el.value) : config.mask

    handler = (evt) => {
      // avoid infinite loop
      if (evt.customEventType === 'mask') {
        return
      }

      // reevaluate the mask if it is a function
      mask = isMaskFunction ? config.mask(el.value) : config.mask

      /* other properties to try to diferentiate InputEvent of Event (custom)
      InputEvent (native)
        cancelable: false
        isTrusted: true

        composed: true
        isComposing: false
        which: 0

      Event (custom)
        cancelable: true
        isTrusted: false
      */
      // by default, keep cursor at same position as before the mask
      let position = el.selectionEnd
      // save the character just inserted
      const digit = el.value[position - 1]
      const newValue = masker(el.value, mask, true, config.tokens)

      // don't bother triggering another input event if mask is already applied
      if (newValue === el.value) {
        return
      }

      el.value = newValue
      // if the digit was changed, increment position until find the digit again
      while (
        position < el.value.length &&
        el.value.charAt(position - 1) !== digit
      ) {
        position++
      }
      if (el === document.activeElement) {
        el.setSelectionRange(position, position)
        setTimeout(() => {
          el.setSelectionRange(position, position)
        }, 0)
      }
      el.dispatchEvent(event('input'))
    }

    const newDisplay = masker(el.value, mask, true, config.tokens)
    if (newDisplay !== el.value) {
      el.value = newDisplay
      el.dispatchEvent(event('input'))
    }

    el.addEventListener('input', handler)
  },
  unbind(el) {
    el.removeEventListener('input', handler)
  },
}
