export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const GET_STARTED = 'GET_STARTED'
export const GET_CUSTOMER = 'GET_CUSTOMER'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT'
export const RESET_USER = 'RESET_USER'
export const UPDATE_CUSTOMER_PROFILE = 'UPDATE_CUSTOMER_PROFILE'
export const UPDATE_USER = 'UPDATE_USER'
export const TRACK_USER = 'TRACK_USER'
export const UNTRACK_USER = 'UNTRACK_USER'
