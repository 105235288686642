import { toDecimalString } from '@/utilityFunctions'

export default function toCurrency(value, removeZeroCents) {
  if (typeof value !== 'number') {
    return value
  }

  let parsedValue = Number(value)
  let prefix = '$'
  if (parsedValue < 0.0) {
    prefix = '-$'
    parsedValue *= -1
  }
  let returnValue = `${prefix}${toDecimalString(parsedValue).replace(
    /\d(?=(\d{3})+\.)/g,
    '$&,',
  )}`

  if (removeZeroCents === true) {
    returnValue = returnValue.replace('.00', '')
  }

  return returnValue
}
