<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    xmlns="http://www.w3.org/2000/svg"
    class="minus"
    v-on:click="svgClick"
  >
    <g>
      <ellipse
        stroke="#ffffff"
        ry="13"
        rx="13"
        id="svg_2"
        cy="13"
        cx="13"
        stroke-width="0"
        fill="#fff"
      />
      <line
        stroke="#52372c"
        stroke-linecap="null"
        stroke-linejoin="null"
        id="svg_5"
        y2="13"
        x2="20"
        y1="13"
        x1="6"
        stroke-width="2"
        fill="none"
      />
    </g>
  </svg>
</template>
<script>
export default {
  methods: {
    svgClick(e) {
      this.$emit('minussvg-click', e)
    },
  },
}
</script>
