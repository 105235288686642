// NOTE: To use this as a filter, register it in /filters/index.js
export default function toStringTimeCapacity(value) {
  if (!value || !value.toLocaleDateString) {
    return value
  }

  const originalHours = value.getHours()
  const amPm = originalHours < 12 ? 'AM' : 'PM'
  const hour = ((originalHours + 11) % 12) + 1
  const prefixHour = hour < 10 ? `0${hour}` : hour

  const minute = value.getMinutes()
  const prefixMinute = minute < 10 ? `0${minute}` : minute

  // this filter is used on order and restaurant capacity
  // to ensure there is a comparable datetime value
  return `${prefixHour}${prefixMinute}${amPm}`
}
