import * as types from '@/store/mutation-types/homeMenuMutations'
import { GET_RESTAURANT_MENU } from '@/store/mutation-types/restaurantMenuMutations'
import { GET_GEOCODE_OF_SEARCH_QUERY } from '@/store/mutation-types/googleMapsMutations'
import * as loaderTypes from '@/store/mutation-types/loaderMutations'
import homeMenuService from '@/services/menuService'
import analyticsService from '@/services/analyticsService'
import { HomeMenuIds, CacheTimes } from '@/constants'
import { deepClone } from '@/utilityFunctions'

const state = {
  homeMenuData: { menu: [] },
  homeMenuIsEstimate: true,
  loaderName: 'RestaurantMenu',
  searchInfo: {
    lastUpdated: null,
    zipCode: '',
    restaurantNumber: null,
  },
}

function compare(firstItem, secondItem) {
  if (firstItem.id < secondItem.id) {
    return -1
  }
  if (firstItem.id > secondItem.id) {
    return 1
  }
  return 0
}

const getters = {
  allTieredMenu: (state) => state.homeMenuData.menu,
  homeMenuIsEstimate: (state) => state.homeMenuIsEstimate,
  homeSearchZipCode: (state) =>
    !state.searchInfo.restaurantNumber ? state.searchInfo.zipCode : undefined,
  buildYourOwnMenu: (state) => {
    if (state.homeMenuData.menu) {
      return state.homeMenuData.menu
        .filter((item) => HomeMenuIds.BuildYourOwnIds.indexOf(item.id) > -1)
        .sort(compare)
    }

    return []
  },
  burritosByTheBoxId: () => HomeMenuIds.BurritosByTheBoxId,
  burritosByTheBoxDescription: (state) => {
    let description = ''
    if (state.homeMenuData.menu && state.homeMenuData.menu.length) {
      const possibleItem = state.homeMenuData.menu.filter(
        (item) => item.id === HomeMenuIds.BurritosByTheBoxId,
      )
      if (possibleItem.length > 0) {
        description = possibleItem[0].description
      }
    }

    return description
  },
  burritosByTheBoxCost: (state) => {
    let minPrice = ''
    if (state.homeMenuData.menu && state.homeMenuData.menu.length) {
      const possibleItem = state.homeMenuData.menu.filter(
        (item) => item.id === HomeMenuIds.BurritosByTheBoxId,
      )
      if (possibleItem.length > 0) {
        minPrice = possibleItem[0].minPrice
      }
    }

    return minPrice
  },
  chipsAndSalsaId: () => HomeMenuIds.ChipsAndSalsaId,
  chipsAndSalsaDescription: (state) => {
    let description = ''
    if (state.homeMenuData.menu && state.homeMenuData.menu.length) {
      const possibleItem = state.homeMenuData.menu.filter(
        (item) => item.id === HomeMenuIds.ChipsAndSalsaId,
      )
      if (possibleItem.length > 0) {
        description = possibleItem[0].description
      }
    }

    return description
  },
  chipsAndSalsaCost: (state) => {
    let minPrice = ''
    if (state.homeMenuData.menu && state.homeMenuData.menu.length) {
      const possibleItem = state.homeMenuData.menu.filter(
        (item) => item.id === HomeMenuIds.ChipsAndSalsaId,
      )
      if (possibleItem.length > 0) {
        minPrice = possibleItem[0].minPrice
      }
    }

    return minPrice
  },
  chipsAndSalsaServes: (state) => {
    let serves = '10-15'
    if (state.homeMenuData.menu && state.homeMenuData.menu.length) {
      const possibleItem = state.homeMenuData.menu.filter(
        (item) => item.id === HomeMenuIds.ChipsAndSalsaId,
      )
      if (possibleItem.length > 0) {
        serves = possibleItem[0].itemConfig.serves
      }
    }

    return serves
  },
}

const actions = {
  [types.GET_HOME_MENU]({ commit, dispatch, state, rootState }, zipCode) {
    const hasHomeMenuData =
      (state.homeMenuData.menu && state.homeMenuData.menu.length > 0) || false
    let isMenuCacheValid = true

    if (state.searchInfo.lastUpdated) {
      let lastUpdated = state.searchInfo.lastUpdated
      const currentDatetime = new Date()

      if (!lastUpdated.getTime) {
        // the date is a string for some reason
        lastUpdated = new Date(lastUpdated)
      }

      isMenuCacheValid =
        currentDatetime.getTime() - lastUpdated.getTime() <= CacheTimes.Medium
    }

    const hasRestaurant =
      (rootState.order.currentOrder.orderDetail &&
        rootState.order.currentOrder.orderDetail.store &&
        rootState.order.currentOrder.orderDetail.store.storeNumber &&
        rootState.order.currentOrder.orderDetail.store.storeNumber > 0) ||
      false

    const restaurantNumber = hasRestaurant
      ? rootState.order.currentOrder.orderDetail.store.storeNumber
      : null

    if (
      hasHomeMenuData &&
      isMenuCacheValid &&
      ((hasRestaurant &&
        restaurantNumber === state.searchInfo.restaurantNumber) ||
        (!hasRestaurant && zipCode === state.searchInfo.zipCode))
    ) {
      return
    }

    dispatch(loaderTypes.SHOW_LOADER, {
      name: state.loaderName,
      mainText: 'Getting Menu Info',
      subText: '',
    })

    // if we are not searching for a specific zipCode menu and
    // we do not have a restaurant context, return the full menu
    // utilizing average prices.
    if (!zipCode && !restaurantNumber) {
      return homeMenuService
        .getTieredAllMenu()
        .then((allMenuResponse) => {
          commit(types.UPDATE_HOME_MENU_SEARCH_INFO, {
            lastUpdated: new Date(),
            zipCode,
            restaurantNumber,
          })
          commit(types.UPDATE_HOME_MENU, {
            newMenu: allMenuResponse.data,
            homeMenuIsEstimate: true,
          })
          analyticsService.generalPricingDisplayed()
        })
        .catch(() => {})
        .finally(() => {
          dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
        })
    }
    let menuPromise
    if (hasRestaurant) {
      menuPromise = dispatch(GET_RESTAURANT_MENU, restaurantNumber)
    } else {
      menuPromise = dispatch(GET_GEOCODE_OF_SEARCH_QUERY, zipCode).then(
        (response) => {
          const geocode = {
            latitude: response.results[0].geometry.location.lat(),
            longitude: response.results[0].geometry.location.lng(),
          }
          return homeMenuService.getTieredMenuByGeoCode(
            geocode.latitude,
            geocode.longitude,
          )
        },
      )
    }
    return menuPromise
      .then((response) => {
        // if we dispatched the GET_RESTAURANT_MENU call, pull from state.
        // else the 2nd response property correlates to getTieredMenuByGeoCode
        const detailedResponse = hasRestaurant
          ? rootState.restaurantMenu.restaurantMenuData
          : response.data

        // re-display the menu loader since it will have cleared at the completion of the
        // GET_RESTAURANT_MENU call (if applicable)
        dispatch(loaderTypes.SHOW_LOADER, {
          name: state.loaderName,
          mainText: 'Getting Menu Info',
          subText: '',
        })

        return homeMenuService
          .getTieredAllMenu()
          .then((allMenuResponse) => {
            allMenuResponse.data.menu.forEach((item) => {
              const detailedItems = detailedResponse.menu.filter(
                (pm) => pm.id === item.id,
              )
              const detailedItem =
                detailedItems.length > 0 ? detailedItems[0] : {}

              // Use price instead of min/max price for both restaurant menu and zip code menu
              // Zip code menu just picks closest restaurant to zip code lat/long for menu pricing
              // The min/max price do not represent the min/max price range for a zip code
              item.price = detailedItem.price
              item.minPrice = detailedItem.price
              item.maxPrice = detailedItem.price
            })

            commit(types.UPDATE_HOME_MENU, {
              newMenu: allMenuResponse.data,
              homeMenuIsEstimate: !restaurantNumber,
            })
          })
          .catch((e) => {
            commit(types.UPDATE_HOME_MENU, {
              newMenu: [],
              homeMenuIsEstimate: true,
            })
          })
      })
      .catch((e) => {
        // both the Restaurant and Postal Code menu methods return a Not Found error
        // if there are no items available for that restaurant/zip code, so this additional
        // promise allows us to ignore that error and return the full tiered menu
        // with prices wiped for unavailable items.
        return homeMenuService
          .getTieredAllMenu()
          .then((allMenuResponse) => {
            allMenuResponse.data.menu.forEach((item) => {
              item.price = undefined
              item.minPrice = undefined
              item.maxPrice = undefined
            })
            commit(types.UPDATE_HOME_MENU, {
              newMenu: allMenuResponse.data,
              homeMenuIsEstimate: true,
            })
          })
          .catch((e) => {
            commit(types.UPDATE_HOME_MENU, {
              newMenu: [],
              homeMenuIsEstimate: true,
            })
          })
      })
      .finally(() => {
        commit(types.UPDATE_HOME_MENU_SEARCH_INFO, {
          lastUpdated: new Date(),
          zipCode,
          restaurantNumber,
        })
        dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
      })
  },

  [types.SET_HOME_RESTAURANT_MENU]({ commit, state }, data) {
    // This method is specifically for when the home page menu has been loaded and
    // the restaurant menu has been hydrated for the order. Once we have the restaurant
    // menu data, update the pricing on the home page. If we don't have home page
    // data, return early, and SET_HOME_MENU will load the correct data when the user
    // accesses the homepage.

    // clone the data to prevent direct state mutation
    const menuData = deepClone(state.homeMenuData)
    if (!menuData.menu || !menuData.menu.length) {
      return
    }

    menuData.menu.forEach((item) => {
      const items = data.menu.filter((pm) => pm.id === item.id)
      const restaurantItem = items.length > 0 ? items[0] : {}

      item.price = restaurantItem.price
      item.minPrice = restaurantItem.price
      item.maxPrice = restaurantItem.price
    })

    commit(types.UPDATE_HOME_MENU_SEARCH_INFO, {
      lastUpdated: new Date(),
      zipCode: '',
      restaurantNumber: data.restaurantNumber,
    })
    commit(types.UPDATE_HOME_MENU, {
      newMenu: menuData,
      homeMenuIsEstimate: false,
    })
  },
}

const mutations = {
  [types.UPDATE_HOME_MENU](state, data) {
    state.homeMenuData = data.newMenu
    state.homeMenuIsEstimate = data.homeMenuIsEstimate
  },
  [types.UPDATE_HOME_MENU_SEARCH_INFO](state, newValue) {
    state.searchInfo = newValue
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
