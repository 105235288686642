import * as types from '@/store/mutation-types/restaurantMenuMutations'
import { SET_HOME_RESTAURANT_MENU } from '@/store/mutation-types/homeMenuMutations'
import * as loaderTypes from '@/store/mutation-types/loaderMutations'
import menuService from '@/services/menuService'
import Vue from 'vue'
import {
  BuilderFoodImageList,
  BuilderReadonlyFoodImageList,
  BuilderReadonlySalsasFoodImageList,
  CacheTimes,
} from '@/constants'

const state = {
  restaurantMenuData: {},
  loaderName: 'RestaurantMenu',
  lastUpdated: null,
  confirmationRestaurantMenuData: {},
}

const getters = {
  restaurantMenu: (state) => state.restaurantMenuData.menu,
  confirmationRestaurantMenu: (state) =>
    state.confirmationRestaurantMenuData.menu,
  getMenuItem: (state, getters) => (menuItemId) => {
    if (!getters.restaurantMenu) {
      return {}
    }
    const possibleItems = getters.restaurantMenu.filter(
      (menuItem) => menuItem.id === menuItemId,
    )
    return possibleItems.length ? possibleItems[0] : {}
  },
  getConfirmationRestaurantMenuItem: (state, getters) => (menuItemId) => {
    if (!getters.confirmationRestaurantMenu) {
      return {}
    }
    const possibleItems = getters.confirmationRestaurantMenu.filter(
      (menuItem) => menuItem.id === menuItemId,
    )
    return possibleItems.length ? possibleItems[0] : {}
  },
}

const actions = {
  [types.GET_CONFIRMATION_RESTAURANT_MENU](
    { commit, dispatch, state },
    restaurantNumber,
  ) {
    return new Promise((resolve, reject) => {
      dispatch(loaderTypes.QUEUE_LOADER, {
        name: state.loaderName,
        mainText: 'Getting Restaurant Menu',
        subText: '',
      })

      menuService
        .getRestaurantTiered(restaurantNumber)
        .then((response) => {
          commit(types.UPDATE_CONFIRMATION_RESTAURANT_MENU, response.data)
          resolve()
        })
        .catch((e) => {
          commit(types.UPDATE_CONFIRMATION_RESTAURANT_MENU, { menu: [] })
          reject(e)
        })
        .finally(() => {
          dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
        })
    })
  },
  [types.GET_RESTAURANT_MENU]({ commit, dispatch, state }, restaurantNumber) {
    return new Promise((resolve, reject) => {
      if (state.lastUpdated) {
        let lastUpdated = state.lastUpdated
        const currentDatetime = new Date()

        if (!lastUpdated.getTime) {
          // the date is a string for some reason
          lastUpdated = new Date(lastUpdated)
        }

        const isCacheValid =
          currentDatetime.getTime() - lastUpdated.getTime() <=
            CacheTimes.Medium &&
          state.restaurantMenuData.restaurantNumber === restaurantNumber
        if (
          state.restaurantMenuData.menu &&
          state.restaurantMenuData.menu.length &&
          isCacheValid
        ) {
          resolve()
          return
        }
      }

      dispatch(loaderTypes.QUEUE_LOADER, {
        name: state.loaderName,
        mainText: 'Getting Restaurant Menu',
        subText: '',
      })

      menuService
        .getRestaurantTiered(restaurantNumber)
        .then((response) => {
          commit(types.UPDATE_RESTAURANT_MENU_LAST_UPDATED, new Date())
          commit(types.UPDATE_RESTAURANT_MENU, response.data)
          dispatch(SET_HOME_RESTAURANT_MENU, {
            restaurantNumber,
            menu: response.data.menu,
          })
          resolve()
        })
        .catch((e) => {
          commit(types.UPDATE_RESTAURANT_MENU, { menu: [] })
          reject(e)
        })
        .finally(() => {
          dispatch(loaderTypes.CLEAR_LOADER, { name: state.loaderName })
        })
    })
  },
}

const mutations = {
  [types.UPDATE_RESTAURANT_MENU](state, data) {
    state.restaurantMenuData = processMenuData(data)
  },
  [types.UPDATE_CONFIRMATION_RESTAURANT_MENU](state, data) {
    state.confirmationRestaurantMenuData = processMenuData(data)
  },
  [types.UPDATE_RESTAURANT_MENU_LAST_UPDATED](state, newValue) {
    state.lastUpdated = newValue
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}

function processMenuData(data) {
  for (let menuIndex = 0; menuIndex < data.menu.length; menuIndex++) {
    setProperties(data.menu[menuIndex].bases)
    setProperties(data.menu[menuIndex].proteins)
    setProperties(data.menu[menuIndex].toppings)
    setProperties(data.menu[menuIndex].premiumToppings)
    setProperties(data.menu[menuIndex].salsas)
    setProperties(data.menu[menuIndex].tortillas)

    if (data.menu[menuIndex].config && data.menu[menuIndex].config.readonly) {
      setReadonlyProperties(data.menu[menuIndex].config.readonly)
    }
  }
  return data
}

function setProperties(foodTypes) {
  if (foodTypes) {
    for (let itemIndex = 0; itemIndex < foodTypes.length; itemIndex++) {
      Vue.set(
        foodTypes[itemIndex],
        'imageUrl',
        BuilderFoodImageList[foodTypes[itemIndex].id],
      )
      Vue.set(foodTypes[itemIndex], 'manualCount', 0)
      Vue.set(foodTypes[itemIndex], 'loadAssortment', false)
      Vue.set(foodTypes[itemIndex], 'assortment', 0)
    }
  }
}

function setReadonlyProperties(readonlyItems) {
  if (readonlyItems.fillings) {
    for (let index = 0; index < readonlyItems.fillings.length; index++) {
      Vue.set(
        readonlyItems.fillings[index],
        'imageUrl',
        BuilderReadonlyFoodImageList[readonlyItems.fillings[index].id],
      )
    }
  }

  if (readonlyItems.sides) {
    for (let index = 0; index < readonlyItems.sides.length; index++) {
      Vue.set(
        readonlyItems.sides[index],
        'imageUrl',
        BuilderReadonlyFoodImageList[readonlyItems.sides[index].id],
      )
    }
  }

  if (readonlyItems.salsas) {
    for (let index = 0; index < readonlyItems.salsas.length; index++) {
      Vue.set(
        readonlyItems.salsas[index],
        'imageUrl',
        BuilderReadonlySalsasFoodImageList[readonlyItems.salsas[index].id],
      )
    }
  }
}
