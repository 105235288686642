<template>
  <div class="order-summary-band">
    <div class="order-summary-band-content">
      <div class="pickup-and-time">
        <div class="pickup-delivery">{{ pickupOrDelivery }}</div>
        <div class="date-time" v-if="isOrderValid">
          {{ scheduledTime }}
        </div>
        <div class="date-time error" v-else>
          {{ errorMessage }}
        </div>
      </div>
      <div
        aria-label="Edit Order Intake"
        class="edit"
        role="button"
        tabindex="0"
        @click="gotoIntake"
        @keydown.enter="gotoIntake"
      >
        <div class="edit-text">Edit</div>
      </div>
    </div>
  </div>
</template>

<script>
import toStringDateTimeRange from '@/filters/toStringDateTimeRange'
import toStringDate from '@/filters/toStringDate'
import { INTAKE_DETAIL } from '@/router/routeNames'
import { getDateInTimeZone } from '@/utilityFunctions'

export default {
  name: 'OrderSummaryBand',
  computed: {
    errorMessage() {
      const intakeValidation = this.$store.getters.intakeValidation
      const baseError =
        "There's an error with your " +
        this.pickupOrDelivery.toLowerCase() +
        ' '

      // cascading IFs to enforce error priority: restaurant is a bigger deal than time.
      if (!intakeValidation.isRestaurantValid) {
        return baseError + 'restaurant.'
      }
      if (!intakeValidation.isDateValid) {
        return baseError + 'date.'
      }
      if (!intakeValidation.isTimeValid) {
        return baseError + 'time.'
      }

      return baseError + 'details.'
    },
    isOrderValid() {
      return this.$store.getters.isCurrentOrderValid
    },

    pickupOrDelivery() {
      return this.$store.getters.currentOrder.orderDetail.isDelivery
        ? 'Delivery'
        : 'Pickup'
    },
    scheduledTime() {
      if (this.$store.getters.currentOrder.orderDetail.isDelivery) {
        const deliveryDate = this.$store.getters.currentOrder.orderDetail
          .deliveryInfo.deliveryDate
        const timezoneId = this.$store.getters.currentOrder.orderDetail.store
          .timezoneId
        const localDeliveryDate = getDateInTimeZone(deliveryDate, timezoneId)
        return toStringDateTimeRange(localDeliveryDate)
      } else {
        const pickupDate = this.$store.getters.currentOrder.orderDetail
          .pickupInfo.pickupDate
        const timezoneId = this.$store.getters.currentOrder.orderDetail.store
          .timezoneId
        const localPickupDate = getDateInTimeZone(pickupDate, timezoneId)
        return toStringDate(localPickupDate, {
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })
      }
    },
  },
  methods: {
    gotoIntake() {
      /**
       * TODO: This doesn't handle the case where you are editing an order item, go to intake,
       * then expect to be navigated back. Refactor action out of qs and use prevPath pattern
       * instead (which should be renamed returnPath)
       */
      const {
        menuType: menuItemId,
        orderItemId,
      } = this.$store.state.route.params
      const routeTo = !menuItemId
        ? {
            name: INTAKE_DETAIL,
            query: { action: this.$store.state.route.name },
          }
        : { name: INTAKE_DETAIL, query: { menuItemId, orderItemId } }
      this.$router.push(routeTo)
    },
  },
}
</script>
<style lang="scss">
#content {
  .order-summary-band {
    font-family: $font-family-sans-serif;
    background-color: $catering-white;
    box-shadow: $catering-box-shadow;
    height: 50px;
    padding-left: 15px;
    padding-right: 15px;
    position: fixed;
    width: 100%;
    z-index: 2;

    .order-summary-band-content {
      position: relative;
      max-width: $max-width-order-info;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 0.5fr 1fr 0.5fr;
      grid-template-rows: 100%;
      align-items: center;
      justify-items: center;
      justify-content: center;
      height: 100%;
    }

    .pickup-and-time {
      grid-column-start: 2;
      align-self: center;
      justify-self: center;
    }

    .pickup-delivery {
      font-size: 13px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: $catering-mobile-header;
      text-transform: uppercase;
    }

    .date-time {
      font-family: $font-family-helvetica;
      font-size: 13px;
      // TODO: one font-weight lighter than the container
      // may want to consider moving normal weight from
      // 400 to 300 across the site based on the designs.
      font-weight: normal;
      line-height: normal;
      text-align: center;
      color: $catering-grey-brown;
      white-space: nowrap;
    }

    .edit {
      font-family: $font-family-bold-condensed;
      font-size: 13px;
      color: $catering-gold;
      cursor: pointer;
      text-transform: uppercase;
      grid-column-start: 3;
      justify-self: end;
      // is not set by autoprefixer
      -ms-grid-column-align: end;
      align-self: center;
      display: flex;
      align-items: center;
      // use 100% height to ensure that the flexbox content
      // fills the entire parent div
      height: 100%;

      .edit-text {
        // use flex on the child content to allow for the text to be
        // centered in the container.
        display: flex;
        align-content: center;
        // the height is required to constrain the div around the font
        // to ensure that the div does not take up extra vertical space.
        height: 14px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  #content {
    .order-summary-band {
      border-bottom: 1px solid $catering-light-brown;

      .date-time {
        color: $catering-light-grey-brown;
        margin-top: 1.1px;
      }
    }
  }
}
</style>
