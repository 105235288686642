import { getDateWithDayOfWeek, getNextTime } from '@/utilityFunctions'

export default function toStringDayOfWeekDateTimeRange(value) {
  if (!value || !value.toLocaleDateString) {
    return value
  }
  const date = new Date(value)
  const result = `${getDateWithDayOfWeek(date)} - ${getNextTime(
    date,
    15,
    'en-US',
    {
      hour: 'numeric',
      minute: '2-digit',
    },
  )}`

  return result.toUpperCase()
}
