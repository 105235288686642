import { masker } from '@/masks'
import tokens from '@/masks/masker/tokens'

export default function toMasked(value, mask, tokensParam) {
  // if mask is an object, a custom mask object with both
  // the mask and tokens has been passed
  if (typeof mask === 'object' && mask.mask) {
    return masker(value, mask.mask, true, mask.tokens)
  }

  // otherwise, the mask is a string and we
  // should use either the tokens param or the
  // default tokens.
  return masker(value, mask, true, tokensParam || tokens)
}
