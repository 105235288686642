import {
  GET_RESTAURANT_EPOPS,
  GET_RESTAURANT_EPOPS_SUCCESS,
  SHOW_EPOPS_MODAL,
} from '@/store/mutation-types/epopsMutations'
import epopsService from '@/services/epopsService'

const state = {
  epops: null,
  epopsUnread: false,
  showEpopsModal: false,
}

const getters = {
  epops: (state) => state.epops || [],
  hasUnreadEpops: (state, getters) => getters.hasEpops && state.epopsUnread,
  hasEpops: (_state, getters) => !!getters.epops.length,
  showEpopsModal: (state) => state.showEpopsModal,
}

const actions = {
  [GET_RESTAURANT_EPOPS]({ commit }, restaurantId) {
    return epopsService.getRestaurantEpops(restaurantId).then((response) => {
      commit(GET_RESTAURANT_EPOPS_SUCCESS, response.data)
    })
  },
  [SHOW_EPOPS_MODAL]({ commit }, showModal) {
    commit(SHOW_EPOPS_MODAL, showModal)
  },
}

const mutations = {
  [GET_RESTAURANT_EPOPS_SUCCESS](state, data) {
    state.epops = data
    state.epopsUnread = true
  },
  [SHOW_EPOPS_MODAL](state, showModal) {
    state.showEpopsModal = showModal
    if (showModal) {
      state.epopsUnread = false
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
