<template>
  <b-modal
    v-bind="attributes"
    v-on="listeners"
    :class="[
      'custom-modal',
      {
        'always-full-screen': alwaysFullScreen,
        'mobile-full-screen': mobileFullScreen,
        'expose-site-header': !coverSiteHeader,
        'position-top-left': position === 'top-left',
        'position-top-right': position === 'top-right',
        'hide-header': !showHeaderText,
      },
    ]"
  >
    <template #modal-header v-if="showDefaultHeaderSlot">
      <!-- For ADA compliance, modal title should be h2 -->
      <h2 v-if="showHeaderText" class="h5 modal-title">
        {{ attributes.title }}
      </h2>
      <button
        v-if="!hideHeaderClose"
        type="button"
        aria-label="Close"
        :class="[
          'modal-close',
          {
            'modal-close-background': showModalCloseBackground,
            'modal-close-no-background': !showModalCloseBackground,
          },
        ]"
        @click="$emit('change', false)"
      >
        &times;
      </button>
    </template>
    <template v-for="(_, name) in $slots" #[name]>
      <slot :name="name" />
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'CustomModal',
  inheritAttrs: false,
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    alwaysFullScreen: {
      type: Boolean,
      default: false,
    },
    mobileFullScreen: {
      type: Boolean,
      default: true,
    },
    coverSiteHeader: {
      type: Boolean,
      default: true,
    },
    position: {
      validator: (value) =>
        ['top-left', 'top-right', 'center'].indexOf(value) > -1,
      default: 'center',
    },
    hideHeaderClose: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    showModalCloseBackground: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    attributes() {
      const defaultAttributes = {
        centered: true,
        noFade: false,
        scrollable: true,
      }
      return {
        ...defaultAttributes,
        ...this.$attrs,
        hideHeader: false, // always use our header
      }
    },
    listeners() {
      const { shown, hidden, ...otherListeners } = this.$listeners
      return {
        shown() {
          shown && shown()
        },
        hidden() {
          hidden && hidden()
        },
        ...otherListeners,
      }
    },
    showDefaultHeaderSlot() {
      return !Object.keys(this.$slots).some(
        (slotName) => slotName === 'modal-header',
      )
    },
    showHeaderText() {
      return !this.hideHeader && !!this.attributes.title
    },
  },
}
</script>

<style lang="scss">
// !important used as necessary since b-modal has certain inline styles
.custom-modal {
  .modal {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .modal-dialog {
    height: 100%;
    max-height: calc(100% - 3.5rem);
    min-height: calc(100% - 3.5rem);
    max-width: calc(100% - 3.5rem);
    margin: 1.75rem auto;
    flex-direction: column;
    justify-content: center;

    &::before {
      height: auto !important;
    }
  }

  .modal-content {
    overflow: hidden;
    width: auto;
  }

  .modal-body {
    overflow-y: auto;
    padding: 0;
  }

  .modal-footer,
  .modal-header {
    flex-shrink: 0;
  }

  .modal-title {
    margin-bottom: 0;
    line-height: 1.5;
  }

  .modal-close {
    padding: 0;
    margin: 0;
    border: 0;
    font-size: 2rem;
    font-weight: normal;
    line-height: 1;
    background: transparent;
    color: $catering-chocolate;

    &:not(:disabled):not(.disabled):hover {
      color: $catering-chocolate-hover;
    }
  }

  &.always-full-screen {
    .modal-dialog {
      margin: 0 !important;
      max-height: 100%;
      min-height: 100%;
      max-width: 100%;
      height: 100%;
    }

    .modal-content {
      border: 0 !important;
      border-radius: 0 !important;
      flex-grow: 1;
      width: 100%;
    }
  }
  &.mobile-full-screen {
    @include media-breakpoint-down(xs) {
      .modal-dialog {
        margin: 0 !important;
        max-height: 100%;
        min-height: 100%;
        max-width: 100%;
        height: 100%;
      }

      .modal-content {
        border: 0 !important;
        border-radius: 0 !important;
        flex-grow: 1;
        width: 100%;
      }
    }
  }

  &.expose-site-header {
    .modal,
    .modal-backdrop {
      @include media-breakpoint-up(sm) {
        top: calc(
          #{$header-height-default} + #{$usablenet-accessibility-header-height}
        );
        height: calc(
          100vh - #{$header-height-default} - #{$usablenet-accessibility-header-height}
        );
      }

      @include media-breakpoint-up(xl) {
        top: calc(
          #{$header-height-tall} + #{$usablenet-accessibility-header-height}
        );
        height: calc(
          100vh - #{$header-height-tall} - #{$usablenet-accessibility-header-height}
        );
      }
    }
  }

  .is-scrolled &.expose-site-header {
    .modal,
    .modal-backdrop {
      @include media-breakpoint-up(sm) {
        top: $header-height-default;
        height: calc(100vh - #{$header-height-default});
      }

      @include media-breakpoint-up(xl) {
        top: $header-height-tall;
        height: calc(100vh - #{$header-height-tall});
      }
    }
  }

  &.position-top-left,
  &.position-top-right {
    @include media-breakpoint-up(sm) {
      .modal-content {
        border: none;
        border-radius: 0 0 0.3rem 0.3rem;
      }

      .modal-dialog {
        max-height: 100%;
        max-width: 100%;
        margin: auto;
        display: flex;
        justify-content: flex-start;
      }
    }
  }

  &.position-top-right {
    .modal-dialog {
      @include media-breakpoint-up(sm) {
        align-items: flex-end;
      }
    }
  }

  &.position-top-left {
    .modal-dialog {
      @include media-breakpoint-up(sm) {
        align-items: flex-start;
      }
    }
  }

  &.hide-header {
    .modal-header {
      border: none;
      padding: 0;
      height: 0;
      justify-content: flex-end;
      z-index: 10;

      .modal-close-background {
        position: relative;
        top: 1.5rem;
        right: 1.5rem;
        height: 40px;
        width: 40px;
        background-color: $catering-white;
        border-radius: 20px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
      }

      .modal-close-no-background {
        margin: 1rem;
      }
    }
  }
}
</style>
