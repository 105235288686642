const rooUrl = process.env.VUE_APP_ROO_URL

export function goToRooPasswordReset() {
  goToRooWeb(
    `password-reset/enter-email?returnUrl=${encodeURIComponent(location.href)}`,
  )
}

export function goToRooWeb(path) {
  location.assign(`${rooUrl}/${path}`)
}
