<template>
  <div class="login-form">
    <login-control
      @login-success="loginSuccess"
      @login-verification-failed="loginVerificationFailed"
      @forgot-password-click="forgotPassword"
    />

    <or-separator />

    <b-button
      variant="tertiary"
      block
      size="md"
      class="create-account-btn"
      @click.stop="createAnAccount"
    >
      CREATE AN ACCOUNT
    </b-button>
  </div>
</template>

<script>
import loginControl from '@/components/account/LoginControl'
import orSeparator from '@/components/common/OrSeparator'

export default {
  name: 'login',
  components: {
    loginControl,
    orSeparator,
  },
  methods: {
    forgotPassword() {
      this.$emit('forgot-password-click')
    },
    loginSuccess() {
      this.$emit('login-success')
    },
    createAnAccount() {
      this.$emit('create-an-account-click')
    },
    loginVerificationFailed() {
      this.$emit('login-verification-failed')
    },
  },
}
</script>

<style lang="scss">
.login-form {
  .create-account-btn {
    height: 40px;
    font-size: 16px;
    letter-spacing: -0.3px;
  }
}
</style>
